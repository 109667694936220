import { FeatureTeaser, ViewedItem } from "shared/resources";
import { useUserStore } from "shared/stores/user";

export default {
  setFeatureTeasers(featureTeasers) {
    this.featureTeasers = featureTeasers;
  },
  fetchFeatureTeasers() {
    const viewedItemScope = ViewedItem.where({
      userId: useUserStore().currentUser.id,
      targetType: "FeatureTeaser",
    }).order({ viewedAt: "desc" });

    FeatureTeaser.includes(["viewedItems"])
      .where({
        featureName: [
          "share_online_broadcast",
          "has_instant_edit_media_item",
          "has_instant_edit_transcript",
          "has_outreach",
          "has_social_platform",
        ],
      })
      .merge({ viewedItems: viewedItemScope })
      .all()
      .then((response) => {
        this.setFeatureTeasers(response.data);
      })
      .catch(() => {
        // do nothing
      });
  },
};
