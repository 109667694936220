import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import User from "./User";

@Model()
class UpsellRegistration extends ApplicationRecord {
  static jsonapiType = "upsell_registrations";

  static notArchived() {
    return this.where({ archivedAt: { eq: "null" } });
  }

  @BelongsTo() organisation: Organisation;

  @BelongsTo() user: User;

  @Attr() organisationId: number;

  @Attr() userId: number;

  @Attr() service: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr() registeredUntil: string;
}

export default UpsellRegistration;
