/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config file > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/material-icons.js'



import {QField,QBanner,QLayout,QHeader,QDrawer,QPageContainer,QPagination,QPage,QToolbar,QToolbarTitle,QBtn,QBtnDropdown,QBtnToggle,QIcon,QList,QToggle,QItem,QTable,QTh,QTr,QTd,QInput,QSpinner,QCard,QCardSection,QCheckbox,QRadio,QOptionGroup,QMenu,QItemSection,QInfiniteScroll,QSeparator,QSelect,QSplitter,QTab,QRouteTab,QTabs,QTabPanels,QTabPanel,QItemLabel,QDialog,QCardActions,QForm,QTime,QImg,QDate,QPopupEdit,QPopupProxy,QExpansionItem,QUploader,QUploaderAddTrigger,QColor,QSpace,QSlider,QMarkupTable,QLinearProgress,QCircularProgress,QChip,QEditor,QSpinnerDots,QStepper,QStep,QStepperNavigation,QTooltip,Ripple,ClosePopup,Loading,Dialog,Notify} from 'quasar'



export default { config: {},iconSet,components: {QField,QBanner,QLayout,QHeader,QDrawer,QPageContainer,QPagination,QPage,QToolbar,QToolbarTitle,QBtn,QBtnDropdown,QBtnToggle,QIcon,QList,QToggle,QItem,QTable,QTh,QTr,QTd,QInput,QSpinner,QCard,QCardSection,QCheckbox,QRadio,QOptionGroup,QMenu,QItemSection,QInfiniteScroll,QSeparator,QSelect,QSplitter,QTab,QRouteTab,QTabs,QTabPanels,QTabPanel,QItemLabel,QDialog,QCardActions,QForm,QTime,QImg,QDate,QPopupEdit,QPopupProxy,QExpansionItem,QUploader,QUploaderAddTrigger,QColor,QSpace,QSlider,QMarkupTable,QLinearProgress,QCircularProgress,QChip,QEditor,QSpinnerDots,QStepper,QStep,QStepperNavigation,QTooltip},directives: {Ripple,ClosePopup},plugins: {Loading,Dialog,Notify} }

