import audienceFilesRoutes from "./audienceFiles";
import authRoutes from "./auth";
import briefGroupRoutes from "./briefGroups";
import briefRoutes from "./briefs";
import briefSetRoutes from "./briefSets";
import broadcastRecorderRoutes from "./broadcastRecorders";
import contractsRoutes from "./contracts";
import distributionListRoutes from "./distributionLists";
import excludedMentionRoutes from "./excludedMentions";
import exportsRoutes from "./exports";
import filterSetRoutes from "./filterSets";
import frontPages from "./frontPages";
import insightsRoutes from "./insights";
import insightsPlusRoutes from "./insights_plus";
import invoicesRoutes from "./invoices";
import journalistRoutes from "./journalists";
import magazineRoutes from "./magazine";
import manualBroadcastUploadRoutes from "./manualBroadcastUploads";
import matchDebugger from "./matchDebugger";
import mediaRequestRoutes from "./mediaRequests";
import mentionExpectations from "./mentionExpectations";
import mentionStreamRoutes from "./mentionStreams";
import onlineRoutes from "./online";
import organisationBrandRoutes from "./organisationBrands";
import organisationClientsRoutes from "./organisationClients";
import organisationContractRoutes from "./organisationContracts";
import organisationOutreachCampaignTemplateRoutes from "./organisationOutreachCampaignTemplates";
import organisationReportComments from "./organisationReportComments";
import organisationReportFilteredSpecRoutes from "./organisationReportFilteredSpecs";
import organisationReportHolidays from "./organisationReportHolidays";
import organisationReportRoster from "./organisationReportRoster";
import organisationReportScheduler from "./organisationReportScheduler";
import organisationReportSpecGroupRoutes from "./organisationReportSpecGroups";
import organisationReportSpecRoutes from "./organisationReportSpecs";
import organisationRoutes from "./organisations";
import organisationTeamRoutes from "./organisationTeams";
import organisationTranscriptTemplateRoutes from "./organisationTranscriptTemplates";
import podcastEpisodeRoutes from "./podcast_episodes";
import podcastRoutes from "./podcasts";
import printRoutes from "./print";
import qcRoutes from "./qc";
import querySnippetsGroupRoutes from "./querySnippetGroups";
import querySnippetsRoutes from "./querySnippets";
import radioRoutes from "./radio";
import reportRoutes from "./reports";
import searchRoutes from "./search";
import socialRoutes from "./social";
import socialStreamRoutes from "./socialStreams";
import sourceGroupRoutes from "./sourceGroups";
import sourceRestrictions from "./sourceRestrictions";
import spellingCorrectionRules from "./spellingCorrectionRules";
import streemCompany from "./streemCompany";
import tvRoutes from "./tv";
import userRequestRoutes from "./userRequests";
import userRoutes from "./users";

function defaultNormalAccessLevel(routes) {
  return routes.map((route) => {
    const updatedRoute = {
      ...route,
      meta: {
        accessLevel: "normal",
        ...route.meta,
      },
    };

    if (updatedRoute.children) {
      updatedRoute.children = defaultNormalAccessLevel(updatedRoute.children);
    }

    return updatedRoute;
  });
}

const routes = [
  ...authRoutes,
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    meta: {
      accessLevel: "limited", // this is the default & lowest access level
    },
    children: defaultNormalAccessLevel([
      // default route, first child is displayed in router view by default
      {
        name: "home",
        path: "/home",
        alias: "",
        component: () => import("pages/Home.vue"),
      },

      // child routes
      ...organisationRoutes,
      ...userRoutes,
      ...onlineRoutes,
      ...podcastRoutes,
      ...podcastEpisodeRoutes,
      ...printRoutes,
      ...magazineRoutes,
      ...tvRoutes,
      ...radioRoutes,
      ...socialRoutes,
      ...reportRoutes,
      ...briefRoutes,
      ...briefGroupRoutes,
      ...manualBroadcastUploadRoutes,
      ...broadcastRecorderRoutes,
      ...distributionListRoutes,
      ...excludedMentionRoutes,
      ...organisationBrandRoutes,
      ...organisationTeamRoutes,
      ...organisationTranscriptTemplateRoutes,
      ...organisationOutreachCampaignTemplateRoutes,
      ...sourceGroupRoutes,
      ...filterSetRoutes,
      ...frontPages,
      ...insightsRoutes,
      ...insightsPlusRoutes,
      ...invoicesRoutes,
      ...exportsRoutes,
      ...contractsRoutes,
      ...journalistRoutes,
      ...mediaRequestRoutes,
      ...mentionExpectations,
      ...mentionStreamRoutes,
      ...qcRoutes,
      ...querySnippetsGroupRoutes,
      ...querySnippetsRoutes,
      ...searchRoutes,
      ...matchDebugger,
      ...organisationReportComments,
      ...organisationReportFilteredSpecRoutes,
      ...organisationReportRoster,
      ...organisationReportHolidays,
      ...organisationReportScheduler,
      ...organisationReportSpecRoutes,
      ...organisationReportSpecGroupRoutes,
      ...sourceRestrictions,
      ...streemCompany,
      ...socialStreamRoutes,
      ...briefSetRoutes,
      ...organisationContractRoutes,
      ...userRequestRoutes,
      ...spellingCorrectionRules,
      ...audienceFilesRoutes,
      ...organisationClientsRoutes,
    ]),
  },
];

export default routes;
