export default () => ({
  globalDataLoaded: false,
  newVersion: false,
  mentionReviews: {
    tvCount: 0,
    radioCount: 0,
  },
  userRequestsCount: 0,
  locations: [],
  categories: [],
  organisationReportComments: 0,
  overnightComments: 0,
  transcriptRequestsCount: 0,
  excludedMentionsCount: 0,
  permissions: [],
  roles: [],
  accountManagers: [],
  mediaRequestsCount: 0,
  mentionExpectationsCount: { toDo: 0, expected: 0 },
  hideHeader: false,
  contractServices: null,
  currentOrganisation: {
    id: null,
    region: null,
    time_zone: null,
  },
});
