export default [
  {
    name: "briefs-add",
    path: "/organisations/:organisationId/briefs/new",
    props: true,
    component: () => import("pages/briefs/BriefPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Create Brief",
    },
  },
  {
    name: "briefs-edit",
    path: "/organisations/:organisationId/briefs/:briefId/edit",
    props: true,
    component: () => import("pages/briefs/BriefPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Brief",
    },
  },
];
