<template>
  <div class="col no-scroll">
    <div class="row row-border items-center">
      <div class="bold q-ml-md q-mr-auto">
        {{ $t("player_popout_list.lists_in_queue", { lists: list.length }) }}
      </div>
      <QBtn
        v-if="list.length"
        :label="$t('player_popout_list.clear_queue')"
        flat
        dense
        :aria-label="$t('player_popout_list.clear_list')"
        @click="$emit('clear-list')"
      />
      <QBtn
        flat
        icon="ion-md-close"
        size="1em"
        :aria-label="$t('player_popout_list.close_playlist')"
        @click="$emit('close-playlist')"
      >
        <StreemTooltip>{{ $t("global.close") }}</StreemTooltip>
      </QBtn>
    </div>
    <div class="scroll-y list-container">
      <div
        v-if="!list.length"
        class="row row-border list-item flex justify-between"
      >
        <div class="soft empty">
          {{ $t("player_popout_list.queue_is_empty") }}
        </div>
      </div>
      <div
        v-for="(item, index) in list"
        :key="item.id"
        :class="{ 'current-clip': isCurrentClip(item) }"
        class="row row-border list-item flex justify-between col-10"
      >
        <div class="row">
          <PlayerPopoutListItem
            :item="item"
            :index="index + 1"
            :is-playing="isPlaying"
            :is-current-clip="isCurrentClip(item)"
            :tooltip="$t('player_popout_list.play_item')"
            @play-item="$emit('play-item', item)"
          />
        </div>
        <QBtn
          flat
          dense
          icon="delete"
          class="col-auto btn-remove"
          :aria-label="$t('player_popout_list.remove_item')"
          @click="$emit('remove-item', item)"
        >
          <StreemTooltip>{{
            $t("player_popout_list.remove_from_list")
          }}</StreemTooltip>
        </QBtn>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerPopoutListItem from "./PlayerPopoutListItem.vue";

export default {
  name: "PlayerPopoutList",
  components: {
    PlayerPopoutListItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    currentClip: {
      type: Object,
      default: () => ({}),
    },
    isPlaying: {
      type: Boolean,
    },
  },
  emits: ["clear-list", "close-playlist", "play-item", "remove-item"],
  mounted() {
    document.querySelector(".current-clip").scrollIntoView();
  },
  methods: {
    isCurrentClip(clip) {
      return this.currentClip.id === clip.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  height: 3em;
  width: 100%;
  padding: 5px 14px;
  transition: background-color 0.3s;
  will-change: background-color;

  &:hover {
    background-color: rgb(0 0 0 / 20%);
    cursor: pointer;

    .btn-remove {
      display: inline-block;
    }
  }
}

.list-container {
  max-height: 15em;
  color: $silver-white;

  &.current-item-slot {
    max-height: 12em;
  }
}

.row-border {
  border-bottom: 1px solid #545454;
}

:deep(.q-btn--dense .q-btn__wrapper) {
  padding: 0;
}

.empty {
  padding: 8px 0 0 !important;
}

.btn-remove {
  display: none;
}

.player-popout-audio {
  .list-container {
    &:last-child {
      border-bottom: 1px solid $white !important;
    }
  }
}

.icon {
  width: 20px;
  margin-right: 8px;
  align-items: center;
}
</style>
