import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import User from "./User";

@Model()
class JournalistEnquiryEntry extends ApplicationRecord {
  static jsonapiType = "journalist_enquiry_entries";

  @BelongsTo() user: User;

  @Attr() text: string;

  @Attr() userId: number;

  @Attr() journalistEnquiryId: number;

  @Attr({ persist: false }) createdAt: string;
}

export default JournalistEnquiryEntry;
