import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "match-debugger",
    path: "/match-debugger",
    props: (route) => typecastRouteProps(route),
    component: () => import("pages/MatchDebugger.vue"),
    meta: {
      title: "Match Debugger",
    },
  },
];
