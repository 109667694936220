export default [
  {
    name: "query-snippet-add",
    path: "/organisations/:organisationId/query-snippets/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/QuerySnippetEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Create Query Snippet",
    },
  },
  {
    name: "query-snippet-edit",
    path: "/organisations/:organisationId/query-snippets/:querySnippetId/edit",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/QuerySnippetEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Query Snippet",
    },
  },
];
