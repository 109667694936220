import type { InputType } from "shared/components/base/InputText.vue";
import { type Props as ConfirmDialogModalProps } from "shared/modals/ConfirmDialogModal.vue";
import ModalService from "shared/modals/ModalService";
import { DialogType } from "shared/modals/types";
import { Nullable } from "shared/types";

export interface DialogServiceOptions extends ConfirmDialogModalProps {
  inputType?: InputType;
  inputValue?: string;
  dialogMaxWidth?: string;
}

export type DialogServiceFunction = (options: DialogServiceOptions) => {
  onDismiss(fn: Function): ThisType<DialogServiceFunction>;
  onCancel(fn: Function): ThisType<DialogServiceFunction>;
  onOk(fn: Function): ThisType<DialogServiceFunction>;
  hide(): void;
};

export function DialogService(options: DialogServiceOptions = {}) {
  let okFn: Nullable<Function> = null,
    cancelFn: Nullable<Function> = null,
    dismissFn: Nullable<Function> = null;

  const API = {
    onDismiss(fn: Function) {
      dismissFn = fn;

      return this;
    },
    onCancel(fn: Function) {
      cancelFn = fn;

      return this;
    },
    onOk(fn: Function) {
      okFn = fn;

      return this;
    },
    hide() {
      ModalService.close("ConfirmDialogModal");
    },
  };

  const transformForModal: (
    options: DialogServiceOptions
  ) => ConfirmDialogModalProps = (opt) => {
    const { inputType, inputValue, dialogMaxWidth, ...rest } = opt;

    return rest;
  };

  ModalService.open("ConfirmDialogModal", {
    props: {
      promptInput: {
        props: { type: options.inputType || "text" },
        value: options.inputValue || "",
      },
      dialogStyle: {
        maxWidth: options.dialogMaxWidth || null,
      },
      ...transformForModal(options),
    },
    events: {
      close: () => {
        if (dismissFn instanceof Function) {
          dismissFn();
        }
      },
      cancel: () => {
        if (cancelFn instanceof Function) {
          cancelFn();
        }
      },
      confirm: (value: string | boolean) => {
        if (okFn instanceof Function) {
          okFn(value);
        }
      },
    },
  });

  return API;
}

DialogService.alert = function alert(options: DialogServiceOptions = {}) {
  return DialogService({
    ...options,
    type: DialogType.ALERT,
  });
};

DialogService.confirm = function confirm(options: DialogServiceOptions = {}) {
  return DialogService({
    ...options,
    type: DialogType.CONFIRM,
  });
};

DialogService.prompt = function prompt(options: DialogServiceOptions = {}) {
  return DialogService({
    ...options,
    type: DialogType.PROMPT,
  });
};

export default DialogService;
