import { Attr, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class TranscriptTurnaroundTime extends ApplicationRecord {
  static jsonapiType = "transcript_turnaround_times";

  @HasOne() organisation: Organisation;

  @Attr() organisationId: number;

  @Attr() transcriptMinutes: number;

  @Attr() turnaroundMinutes: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default TranscriptTurnaroundTime;
