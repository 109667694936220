export default [
  {
    name: "organisation-contracts-new",
    path: "/organisations/:organisationId/organisation-contracts/new",
    component: () => import("pages/organisations/OrganisationContractEdit.vue"),
    props: true,
    meta: {
      accessLevel: "organisation_edit",
      title: "Organisation Contract New Form",
    },
  },
  {
    name: "organisation-contracts-edit",
    path: "/organisations/:organisationId/organisation-contracts/:organisationContractId/edit",
    component: () => import("pages/organisations/OrganisationContractEdit.vue"),
    props: true,
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Organisation Contract",
    },
  },
];
