import useTeamPicker from "shared/composables/useTeamPicker";
import { getCurrentTimestamp } from "shared/helpers/date";
import { TranscriptRequest } from "shared/resources";
import features from "shared/services/features";
import { useUserStore } from "shared/stores/user";

export default {
  fetchTranscriptRequestCount() {
    return TranscriptRequest.where({
      completedAt: { gt: useUserStore().currentUser.transcripts_read_at },
      ...(features.has("has_team_workspaces")
        ? {
            organisationTeamId: [
              useTeamPicker().selectedTeamPreference.value,
              "null",
            ],
          }
        : {}),
    })
      .per(0)
      .page(1)
      .stats({ total: "count" })
      .all()
      .then((response) => {
        this.transcriptRequestsCount = response.meta.stats.total.count;
      })
      .catch(() => {
        // do nothing
      });
  },
  clearTranscriptRequestAlertsCount() {
    this.transcriptRequestsCount = 0;

    return useUserStore().updateUser(
      { transcripts_read_at: getCurrentTimestamp() },
      { root: true }
    );
  },
};
