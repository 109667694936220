import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OrganisationFeatureAllowance from "./OrganisationFeatureAllowance";
import Version from "./Version";

@Model()
class OrganisationsFeature extends ApplicationRecord {
  static jsonapiType = "organisations_features";

  @BelongsTo() organisation: Organisation;

  @HasMany() versions: Version[];

  @HasMany()
  organisationFeatureAllowances: OrganisationFeatureAllowance[];

  @Attr() organisationId: number;

  @Attr() feature: string;

  @Attr() enabled: boolean;

  @Attr() cost: number;

  @Attr({ persist: false }) monthlyCredits: number;

  @Attr({ persist: false }) billable: boolean;

  @Attr({ persist: false }) flaggable: boolean;

  @Attr({ persist: false }) label: string;

  @Attr({ persist: false }) name: string;

  @Attr({ persist: false }) description: string;

  @Attr({ persist: false }) position: number;

  @Attr({ persist: false }) tag: string;

  @Attr({ persist: false }) locked: boolean;

  @Attr({ persist: false }) accessLevel: string;

  @Attr({ persist: false }) parentFeatureId: number;
}

export default OrganisationsFeature;
