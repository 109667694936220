import type { AxiosError, AxiosResponse } from "axios";
import { defineStore } from "pinia";

import { track } from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { dateToTimestamp, getCurrentTimestamp } from "shared/helpers/date";

import { useUserStore } from "../user";

interface RootState {
  alertCount: number;
}

export const useAlertsStore = defineStore("alerts", {
  state: (): RootState => ({
    alertCount: 0,
  }),
  actions: {
    setAlertCount(count: number): void {
      this.alertCount = count;
    },
    fetchAlertCount(): Promise<number | AxiosError> {
      return $streemApiV1
        .get("alerts", {
          params: {
            // TODO: remove cast to any once user store is properly typed
            after: dateToTimestamp(
              (useUserStore().currentUser as any).alerts_read_at
            ),
            limit: 0,
          },
        })
        .then((response: AxiosResponse) => {
          const count = Number(response.headers["x-total-count"]);
          this.setAlertCount(count);

          return count;
        })
        .catch((error: AxiosError) => error);
    },
    clearAlertCount(): Promise<void> {
      track("Read Alert");

      this.setAlertCount(0);

      return useUserStore().updateUser({
        alerts_read_at: getCurrentTimestamp(),
      });
    },
  },
});

export default useAlertsStore;
