import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "contracts",
    path: "/contracts",
    props: (route) => typecastRouteProps(route),
    component: () => import("pages/ContractList.vue"),
    meta: {
      title: "Contracts",
      accessLevel: "super",
    },
  },
];
