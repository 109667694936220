import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Outlet from "./Outlet";

@Model()
class OutletType extends ApplicationRecord {
  static jsonapiType = "outlet_types";

  @BelongsTo() outlet: Outlet;

  @Attr() name: string;
}

export default OutletType;
