export default [
  {
    name: "excluded-mentions",
    path: "/excluded-mentions",
    component: () => import("pages/excluded_mentions/ExcludedMentionsPage.vue"),
    props: (route) => ({
      targetId: route.query.target_id,
      reviewed: route.query.reviewed,
    }),
    meta: {
      accessLevel: "organisation_read",
      title: "Excluded Mentions",
    },
  },
];
