import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import EmailTracking from "./EmailTracking";

@Model()
class EmailEvent extends ApplicationRecord {
  static jsonapiType = "email_events";

  @BelongsTo() emailTracking: EmailTracking;

  @Attr() emailTrackingId: string;

  @Attr() event: string;

  @Attr() recipient: string;

  @Attr() timestamp: string;
}

export default EmailEvent;
