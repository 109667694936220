import { Attr, BelongsTo, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import InsightsQuery from "./InsightsQuery";
import QuerySnippet from "./QuerySnippet";
import QuerySnippetGroup from "./QuerySnippetGroup";
import Stream from "./Stream";

@Model()
class QuerySnippetLink extends ApplicationRecord {
  static jsonapiType = "query_snippet_links";

  @HasOne() querySnippet: QuerySnippet;

  @BelongsTo() group: QuerySnippetGroup;

  @BelongsTo() object: Stream | InsightsQuery;

  @Attr() querySnippetId: number;

  @Attr() objectId: number;

  @Attr() objectType: string;
}

export default QuerySnippetLink;
