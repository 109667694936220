import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class TranscriptRequestSourceFile extends ApplicationRecord {
  static jsonapiType = "transcript_request_source_files";

  @Attr() url: string;

  @Attr() filename: string;

  @Attr() startTime: number;

  @Attr() endTime: number;

  @Attr() position: number;

  @Attr() editingComplete: boolean;

  @Attr() contentType: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default TranscriptRequestSourceFile;
