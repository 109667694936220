import { Attr, HasMany, HasOne, Model } from "spraypaint";

import SourceRecord from "shared/resources/SourceRecord";

import Category from "./Category";
import CopyrightAgency from "./CopyrightAgency";
import Outlet from "./Outlet";
import SourceGroup from "./SourceGroup";
import SourceTopic from "./SourceTopic";
import WebsiteDomain from "./WebsiteDomain";

@Model()
class Website extends SourceRecord {
  static jsonapiType = "websites";

  @HasOne() copyrightAgency: CopyrightAgency;

  @HasOne() location: Location;

  @HasOne() sourceGroup: SourceGroup;

  @HasOne() category: Category;

  @HasMany() topics: SourceTopic[];

  @HasMany() websiteDomains: WebsiteDomain[];

  @HasOne() outlet: Outlet;

  @Attr() name: string;

  @Attr() label: string;

  @Attr({ persist: false }) medium: string;

  @Attr() locationId: number;

  @Attr() domain: string;

  @Attr() timeZone: string;

  @Attr() satirical: boolean;

  @Attr() logoUrl: string;

  @Attr() tier: number;

  @Attr() categoryId: number;

  @Attr() dailyAudience: number;

  @Attr() monthlyAudience: number;

  @Attr() scrapingCookie: string;

  @Attr() scraper: string;

  @Attr() syndication: string;

  @Attr() paywall: string;

  @Attr() sourceGroupId: number;

  @Attr() notes: string;

  @Attr() plainTextPaywallBypass: boolean;

  @Attr() visible: boolean;

  @Attr() caldContent: boolean;

  @Attr() translate: boolean;

  @Attr() origin: string;

  @Attr() lastArticleAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr() aliases: string[];

  @Attr() logoData: string;

  @Attr() useProxy: boolean;

  @Attr() copyrightAgencyId: number;

  internationalPlus() {
    return this.origin === "aylien";
  }
}

export default Website;
