import { Attr, HasMany, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import QuerySnippet from "./QuerySnippet";

@Model()
class QuerySnippetGroup extends ApplicationRecord {
  static jsonapiType = "query_snippet_groups";

  @HasOne() organisation: Organisation;

  @HasMany() querySnippets: QuerySnippet[];

  @Attr() organisationId: number;

  @Attr() position: number;

  @Attr() label: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default QuerySnippetGroup;
