<template>
  <div class="flex full-width player-popout-controls">
    <div class="flex full-width">
      <QSlider
        v-model="timeModel"
        color="body-color"
        :min="0"
        :max="clipDuration"
        :step="clipStep"
        @update:model-value="$emit('seek')"
      />
    </div>
    <div class="flex full-width justify-between player-popout-buttons-wrapper">
      <PlayerPopoutButtons
        :disabled="disabled"
        :first-play="firstPlay"
        :is-playing="isPlaying"
        :loading="loading"
        :no-next-clip="noNextClip"
        :no-previous-clip="noPreviousClip"
        @fastforward="$emit('fastforward')"
        @rewind="$emit('rewind')"
        @skip-next="$emit('skip-next')"
        @skip-previous="$emit('skip-previous')"
        @toggle-play="$emit('toggle-play')"
      />
      <div class="player-popout-time">
        <span class="player-popout-time-value smaller">{{
          formatTime(timeModel)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  dateInTimeZone,
  fromCurrentToGivenTimezone,
  getTimezone,
  shortTimezone,
} from "shared/helpers/date";

import PlayerPopoutButtons from "./PlayerPopoutButtons.vue";

export default {
  name: "PlayerPopoutCardControls",
  components: {
    PlayerPopoutButtons,
  },
  props: {
    player: {
      type: HTMLVideoElement,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    isPlaying: {
      type: Boolean,
    },
    firstPlay: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    noPreviousClip: {
      type: Boolean,
    },
    noNextClip: {
      type: Boolean,
    },
    playedClip: {
      type: Object,
      default: () => ({}),
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    timeZone: {
      type: String,
      default: getTimezone(),
    },
  },
  emits: [
    "seek",
    "fastforward",
    "rewind",
    "skip-next",
    "skip-previous",
    "toggle-play",
    "update:currentTime",
  ],
  computed: {
    timeModel: {
      get() {
        return !this.loading && this.playedClip.start_time
          ? this.currentTime
          : 0;
      },
      set(value) {
        this.player.currentTime = value;
        this.$emit("update:currentTime", value);
      },
    },
    clipDuration() {
      return this.playedClip.end_time
        ? this.playedClip.end_time - this.playedClip.start_time
        : 0.1;
    },
    clipStep() {
      return this.playedClip ? this.clipDuration / 100 : 0.1;
    },
  },
  methods: {
    formatTime(inputSeconds) {
      if (!this.playedClip.start_time || this.loading) return "--:--:--";

      const timestamp = Math.trunc(this.playedClip.start_time + inputSeconds);
      const isoString = new Date(timestamp * 1000).toISOString();

      const timeInTimezone = fromCurrentToGivenTimezone(
        isoString,
        this.timeZone
      );

      const timezone = shortTimezone(timeInTimezone, this.timeZone);

      return `${dateInTimeZone(
        timestamp,
        "h:mm:ssaaa",
        this.timeZone
      )} ${timezone}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.player-popout-buttons-wrapper {
  :deep(.q-btn__wrapper) {
    padding: 4px 14px;
  }
}

.player-popout-time {
  position: relative;
}

.player-popout-time-value {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: max-content;
}

.unavailable {
  opacity: 0.3;
  pointer-events: none;
}
</style>
