import { startOfDay, subMonths } from "date-fns";

export default {
  getLowestSearcheableDate: (state) => (currentDate) => {
    if (state.appConfig.contentDateCap.toString().length >= 10) {
      return state.appConfig.contentDateCap;
    }

    return subMonths(startOfDay(currentDate), state.appConfig.contentDateCap);
  },
  getContentDateCap: (state) => () => state.appConfig.contentDateCap,
};
