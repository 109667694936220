export default [
  {
    name: "organisation-client-add",
    path: "/organisations/:organisationId/organisation_clients/new",
    props: true,
    component: () => import("pages/OrganisationClientEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "New Organisation Client",
    },
  },
  {
    name: "organisation-client-edit",
    path: "/organisations/:organisationId/organisation_clients/:organisationClientId/edit",
    props: true,
    component: () => import("pages/OrganisationClientEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Organisation Client",
    },
  },
];
