export default [
  {
    name: "organisation-report-holidays",
    path: "/organisation-report-holidays",
    props: (route) => ({ view: "holidays", ...route.params, ...route.query }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "super",
      title: "Organisation Report - Holidays",
    },
  },
  {
    name: "organisation-report-holiday-new",
    path: "/organisation-report-holidays/new",
    component: () => import("pages/HolidayEdit.vue"),
    meta: {
      accessLevel: "super",
    },
  },
  {
    name: "organisation-report-holiday-edit",
    path: "/organisation-report-holidays/:id/edit",
    props: true,
    component: () => import("pages/HolidayEdit.vue"),
    meta: {
      accessLevel: "super",
    },
  },
];
