import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class Thumbnail extends ApplicationRecord {
  static jsonapiType = "thumbnails";

  @Attr() url: string;
}

export default Thumbnail;
