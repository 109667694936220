import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import AnalyticsIntegration from "./AnalyticsIntegration";

@Model()
class AnalyticsDataStream extends ApplicationRecord {
  static jsonapiType = "analytics_data_streams";

  @BelongsTo() analyticsIntegration: AnalyticsIntegration;

  @Attr() analyticsIntegrationId: number;

  @Attr() dataStreamId: string;

  @Attr() dataStreamIdentifier: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default AnalyticsDataStream;
