import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import Stream from "./Stream";
import User from "./User";

@Model()
class StreamReport extends ApplicationRecord {
  static jsonapiType = "stream_reports";

  @BelongsTo() user: User;

  @BelongsTo() stream: Stream;

  @BelongsTo() organisation: Organisation;

  @Attr() userId: number;

  @Attr() streamId: number;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() reportType: string;

  @Attr() insightsType: string;

  @Attr() format: string;

  @Attr() formats: string;

  @Attr() csv: boolean;

  @Attr() pdf: boolean;

  @Attr() includePrintClipsPdf: boolean;

  @Attr() fileUrl: string;

  @Attr() csvUrl: string;

  @Attr() pdfUrl: string;

  @Attr() xlsxUrl: string;

  @Attr() publicHtmlUrl: string;

  @Attr() label: string;

  @Attr() note: string;

  @Attr() before: number;

  @Attr() after: number;

  @Attr() timeZone: string;

  @Attr() types: string[];

  @Attr() options: Record<string, any>;

  @Attr() sortBy: string;

  @Attr() sortOrder: string;

  @Attr() status: string;

  @Attr() excludedItems: string[];

  @Attr() collapseSyndicated: boolean;

  @Attr() createdAt: string;

  @Attr() displayCharts: boolean;

  @Attr() displayMentions: boolean;
}

export default StreamReport;
