import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

enum InsightsEntityType {
  "Sentiment" = 0,
  "Highlight" = 1,
}

@Model()
class InsightsEntity extends ApplicationRecord {
  static jsonapiType = "insights_entities";

  @BelongsTo() organisation: Organisation;

  @Attr() keywords: string[];

  @Attr() label: string;

  @Attr() color: string;

  @Attr() entityType: InsightsEntityType;

  @Attr() organisationId: number;

  @Attr() position: number;

  @Attr() archivedAt: string;
}

export default InsightsEntity;
