export function dig(object, ...path) {
  return path.reduce(
    (nested, part) => (nested ? nested[part] || "" : ""),
    object
  );
}

export function has(object, method) {
  return object && Object.prototype.hasOwnProperty.call(object, method);
}

export const tableFilter = (row, filters) =>
  Object.entries(filters).every(([col, value]) => {
    const term = value ? value.toString().toLowerCase() : "";
    const path = col.split(".");
    const cell = dig(row, ...path);

    return cell.toString().toLowerCase().includes(term);
  });
