<template>
  <div class="wrapper">
    <Transition name="slide-up-fade">
      <div
        v-show="!isAudio && playerList.length"
        ref="videoContainer"
        class="video-container"
        :class="{ 'video-container-audio': isAudio }"
      >
        <div
          v-show="showRestrictionMessage"
          class="restriction-message"
        >
          <p class="absolute-center text-help-text full-width q-pa-md">
            {{ $t("player_popout_interface.duration_restriction") }}
          </p>
        </div>
      </div>
    </Transition>
    <div v-if="showPopoutInterface">
      <PlayerPopoutControls
        v-model:current-time="localCurrentTime"
        :class="{ 'player-popout-audio': isAudio }"
        :disabled="disabled"
        :first-play="firstPlay"
        :initial-clip="initialClip"
        :is-playing="isPlaying"
        :loading="loading"
        :no-next-clip="noNextClip"
        :no-previous-clip="noPreviousClip"
        :played-clip="playedClip"
        :player="player"
        :time-zone="timeZone"
        :clip-list-index="clipListIndex"
        :is-audio="isAudio"
        @close="$emit('close')"
        @fastforward="$emit('fastforward')"
        @rewind="$emit('rewind')"
        @skip-next="$emit('skip-next')"
        @skip-previous="$emit('skip-previous')"
        @toggle-play="$emit('toggle-play')"
        @toggle-playlist="$emit('toggle-playlist')"
        @clear-list="$emit('clear-list')"
        @play-item="playItem($event)"
        @remove-item="removeItem($event)"
      />
    </div>
    <div
      v-else
      :class="{ 'card-controls-video': !isAudio }"
    >
      <PlayerPopoutCardControls
        v-model:current-time="localCurrentTime"
        :player="player"
        :disabled="disabled"
        :first-play="firstPlay"
        :is-playing="isPlaying"
        :loading="loading"
        :no-next-clip="noNextClip"
        :no-previous-clip="noPreviousClip"
        :played-clip="playedClip"
        :time-zone="timeZone"
        @close="$emit('close')"
        @fastforward="$emit('fastforward')"
        @rewind="$emit('rewind')"
        @skip-next="$emit('skip-next')"
        @skip-previous="$emit('skip-previous')"
        @toggle-play="$emit('toggle-play')"
      />
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import PlayerPopoutCardControls from "shared/components/players/PlayerPopoutCardControls.vue";
import PlayerPopoutControls from "shared/components/players/PlayerPopoutControls.vue";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import { getTimezone } from "shared/helpers/date";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "PlayerPopoutInterface",
  components: {
    PlayerPopoutCardControls,
    PlayerPopoutControls,
  },
  props: {
    player: {
      type: HTMLVideoElement,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    isPlaying: {
      type: Boolean,
    },
    firstPlay: {
      type: Boolean,
    },
    noPreviousClip: {
      type: Boolean,
    },
    noNextClip: {
      type: Boolean,
    },
    playedClip: {
      type: Object,
      default: () => ({}),
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    timeZone: {
      type: String,
      default: getTimezone(),
    },
    initialClip: {
      type: Object,
      default: () => ({}),
    },
    clipListIndex: {
      type: Number,
      default: 0,
    },
    isAudio: {
      type: Boolean,
    },
    showRestrictionMessage: {
      type: Boolean,
    },
  },
  emits: [
    "close",
    "fastforward",
    "rewind",
    "skip-next",
    "skip-previous",
    "toggle-play",
    "toggle-playlist",
    "clear-list",
    "mount-video",
    "play-item",
    "remove-item",
    "update:currentTime",
  ],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();

    const {
      universalPlayerRef,
      popoutVideoRef,
      playerPopoutTarget,
      playerList,
    } = storeToRefs(universalPlayerStore);

    return {
      universalPlayerRef,
      popoutVideoRef,
      playerPopoutTarget,
      playerList,
    };
  },
  data() {
    return {
      showPopoutInterface: false,
    };
  },
  computed: {
    localCurrentTime: {
      get() {
        return this.currentTime;
      },
      set(value) {
        this.$emit("update:currentTime", value);
      },
    },
  },
  watch: {
    playerPopoutTarget: {
      immediate: true,
      handler() {
        this.showPopoutInterface =
          this.playerPopoutTarget === POPOUT_PLAYER_TARGET;
      },
    },
  },
  mounted() {
    this.$refs.videoContainer.appendChild(this.popoutVideoRef);
    this.popoutVideoRef.addEventListener("played", this.played);
    this.popoutVideoRef.addEventListener("ended", this.ended);
  },
  beforeUnmount() {
    this.popoutVideoRef.removeEventListener("played", this.played);
    this.popoutVideoRef.removeEventListener("ended", this.ended);
    this.$emit("mount-video");
  },
  methods: {
    playItem(clip) {
      this.$emit("play-item", clip);
    },
    removeItem(clip) {
      this.$emit("remove-item", clip);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "shared/css/utils/transitions/slideUpFade";

:deep(.video-container) {
  height: auto;
  margin-bottom: -3px;

  & .video-container-audio {
    height: 0;
  }
}

.card-controls-video {
  padding: 0 15px;
  border-left: 5px solid $tv;
}

.wrapper {
  position: relative;
}

.restriction-message {
  background-color: rgb(0 0 0 / 85%);
  border-radius: 5px 5px 0 0;
  color: white;
  height: 195px;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
