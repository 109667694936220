import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OutreachCampaignRecipient from "./OutreachCampaignRecipient";
import Tag from "./Tag";
import User from "./User";

function formatDate(date: string) {
  if (!date) return "";

  const parsedDate = parseISO(date);

  return format(parsedDate, "p, d MMMM");
}

@Model()
class OutreachCampaign extends ApplicationRecord {
  static jsonapiType = "outreach_campaigns";

  static Status = {
    Draft: 0,
    Ready: 1,
    Sending: 2,
    Sent: 3,
    Error: 4,
  };

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @HasMany({ name: "taggable" }) tags: Tag[];

  @HasMany() outreachCampaignRecipients: OutreachCampaignRecipient[];

  @HasMany()
  outreachCampaignRecipientsWithoutLists: OutreachCampaignRecipient[];

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() organisationBrandId: number;

  @Attr() recipients: any[];

  @Attr({ persist: false }) emailRecipients: any[];

  @Attr({ persist: false }) emailRecipientListCounts: any[];

  @Attr() replyTo: string;

  @Attr() fromName: string;

  @Attr() subject: string;

  @Attr() message: string;

  @Attr() signature: string;

  @Attr() status: number;

  @Attr() notifyOnOpen: boolean;

  @Attr() notifyOnClick: boolean;

  @Attr() private: boolean;

  @Attr() scheduledAt: string;

  @Attr() sentAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr() recipientCount: number;

  @Attr({ persist: false }) emailClickedCount: number;

  @Attr({ persist: false }) emailDeliveredCount: number;

  @Attr({ persist: false }) emailFailedCount: number;

  @Attr({ persist: false }) emailOpenedCount: number;

  statusLabel() {
    if (this.status === 3) {
      return `Sent at ${this.formattedSentAt()}`;
    }

    if (this.scheduledAt) {
      const prefix = this.status === 0 ? "Draft - " : "";

      return `${prefix}Scheduled for ${this.formattedScheduledAt()}`;
    }

    return {
      0: "Draft",
      1: "Ready",
      2: "Sending",
      4: "Error",
    }[this.status];
  }

  scheduledAtExpired() {
    return (
      ![2, 3, 4].includes(this.status) &&
      this.scheduledAt &&
      new Date(this.scheduledAt) < new Date()
    );
  }

  formattedScheduledAt() {
    return formatDate(this.scheduledAt);
  }

  formattedSentAt() {
    return formatDate(this.sentAt);
  }

  interval() {
    const hours = differenceInHours(new Date(), new Date(this.sentAt));
    const minutes = differenceInMinutes(new Date(), new Date(this.sentAt));

    if (minutes < 20) return "1m";
    if (hours < 2) return "5m";
    if (hours < 24) return "1h";

    return "1d";
  }
}

export default OutreachCampaign;
