import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Invoice from "./Invoice";
import type { Features, InvoiceLedger, InvoiceStatus } from "./types";

@Model()
class InvoiceLineItem extends ApplicationRecord {
  static jsonapiType = "invoice_line_items";

  @BelongsTo() invoice: Invoice;

  @Attr({ persist: false }) feature: Features;

  @Attr({ persist: false }) description: string;

  @Attr() writeOffReason: string;

  @Attr() status: InvoiceStatus;

  @Attr({ persist: false }) ledger: InvoiceLedger;

  @Attr({ persist: false }) units: number;

  @Attr({ persist: false }) unitCost: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default InvoiceLineItem;
