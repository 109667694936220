import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "copyright-reports",
    path: "/copyright_reports",
    props: true,
    component: () => import("pages/CopyrightReportList.vue"),
    meta: {
      accessLevel: "copyright_reporting",
      title: "Copyright Reports",
    },
  },
  {
    name: "copyright-report-new",
    path: "/copyright_reports/new",
    props: true,
    component: () => import("pages/CopyrightReportEdit.vue"),
    meta: {
      accessLevel: "copyright_reporting",
      title: "New Copyright Reports",
    },
  },
  {
    name: "front-page-reports",
    path: "/front_page_reports",
    props: (route) => ({
      tab: route.query.tab,
    }),
    component: () => import("pages/reports/FrontPages/FrontPageReports.vue"),
    meta: {
      title: "Front Page Reports",
    },
  },
  {
    name: "front-page-report-schedule-new",
    path: "/front_page_report_schedules/new",
    component: () =>
      import("pages/reports/FrontPages/EditFrontPageReportSchedule.vue"),
    meta: {
      title: "New Front Page Report Schedule",
    },
  },
  {
    name: "front-page-report-schedule-edit",
    path: "/front_page_report_schedules/:scheduleId",
    props: true,
    component: () =>
      import("pages/reports/FrontPages/EditFrontPageReportSchedule.vue"),
    meta: {
      title: "Edit Front Page Report Schedule",
    },
  },
  {
    name: "social-reports",
    path: "/social_reports",
    props: true,
    component: () => import("pages/reports/SocialReportsList.vue"),
    meta: {
      title: "Social Reports",
    },
  },
  {
    name: "social-report-new",
    path: "/social_reports/new",
    component: () => import("pages/reports/SocialReportEdit.vue"),
    meta: {
      title: "New Social Report",
    },
  },
  {
    name: "social-report-edit",
    path: "/social_reports/:reportId",
    props: true,
    component: () => import("pages/reports/SocialReportEdit.vue"),
    meta: {
      title: "Edit Social Report",
    },
  },
  {
    name: "news-summaries-list",
    path: "/news-summaries",
    component: () => import("pages/NewsSummaryList.vue"),
    meta: {
      title: "Daily Highlights",
    },
  },
  {
    name: "news-summaries-new",
    path: "/news-summaries/new",
    component: () => import("pages/NewsSummaryEdit.vue"),
    meta: {
      title: "New News Summary",
    },
  },
  {
    name: "news-summaries-edit",
    path: "/news-summaries/:id",
    component: () => import("pages/NewsSummaryEdit.vue"),
    props: true,
    meta: {
      title: "Edit News Summary",
    },
  },
  {
    name: "insights",
    path: "/insights",
    props: (route) => typecastRouteProps(route),
    component: () => import("pages/insights/InsightsOrganisationsPage.vue"),
    meta: {
      title: "Media Analysis",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus",
    path: "/insights-plus",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/InsightsOrganisationsPage.vue"),
    meta: {
      title: "Insights Plus",
      accessLevel: "media_analyst",
    },
  },
];
