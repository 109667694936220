import { $streemApiV1 } from "shared/boot/api";
import { useUserStore } from "shared/stores/user";

const defaultHandleErrors = true;

export default {
  getOrganisationFeatures: async ({ organisationId }) => {
    const handleErrors = defaultHandleErrors;

    const userStore = useUserStore();

    if (!userStore.organisation.features) {
      await userStore.getOrganisation(organisationId);
    }

    return $streemApiV1
      .get(`organisations/${organisationId}/features`, { handleErrors })
      .then((response) => {
        this.updateOrganisationFeatures(response.data);
      })
      .catch((error) => error);
  },
  refreshUsedCredits({ organisationId, feature, handleErrors }) {
    let shouldHandleErrors = handleErrors;

    if (shouldHandleErrors === undefined) {
      shouldHandleErrors = defaultHandleErrors;
    }

    return $streemApiV1
      .get(`organisations/${organisationId}/features/${feature}`, {
        handleErrors: shouldHandleErrors,
      })
      .then((response) => {
        this.updateOrganisationFeatures({
          [feature]: response.data,
        });
      });
  },
  updateOrganisationFeatures(data) {
    this.organisationFeatures = { ...this.organisationFeatures, ...data };
  },
  resetOrganisationFeatures(data) {
    this.organisationFeatures = { ...data };
  },
};
