import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import User from "./User";

@Model()
class MediaReleaseTemplate extends ApplicationRecord {
  static jsonapiType = "media_release_templates";

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() name: string;

  @Attr() message: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default MediaReleaseTemplate;
