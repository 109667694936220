import { Attr, BelongsTo, Model } from "spraypaint";

import ProgramAiring from "shared/resources/ProgramAiring";

import TvChannel from "./TvChannel";

@Model()
class TvProgramAiring extends ProgramAiring {
  static jsonapiType = "tv_program_airings";

  @BelongsTo() tvChannel: TvChannel;

  @Attr() currentAffairs: boolean;
}

export default TvProgramAiring;
