import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Outlet from "./Outlet";

@Model()
class OutletAddress extends ApplicationRecord {
  static jsonapiType = "outlet_addresses";

  @BelongsTo() outlet: Outlet;

  @Attr() address: string;

  @Attr() defaultFlag: boolean;
}

export default OutletAddress;
