import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Stream from "./Stream";
import User from "./User";

@Model()
class StreamsUser extends ApplicationRecord {
  static jsonapiType = "streams_users";

  @BelongsTo() user: User;

  @BelongsTo() stream: Stream;

  @Attr() accessLevel: string;

  @Attr() streamId: number;

  @Attr() userId: number;

  @Attr() primary: boolean;

  @Attr() autoRefresh: boolean;

  @Attr() refreshedAt: string;

  @Attr() archivedAt: string;

  @Attr() archived: boolean;

  @Attr() sharedGroupId: number;

  @Attr() canEdit: boolean;

  @Attr() position: number;

  @Attr() unreadMentionCount: number;
}

export default StreamsUser;
