export default [
  {
    name: "spelling-correction-rules",
    path: "/spelling-correction-rules",
    component: () =>
      import("pages/spelling_corrections/SpellingCorrectionRulesList.vue"),
    meta: {
      title: "Spelling Correction Rules",
    },
  },
  {
    name: "spelling-correction-rules-new",
    path: "/spelling-correction-rules/new",
    component: () =>
      import("pages/spelling_corrections/SpellingCorrectionRuleEdit.vue"),
    meta: {
      title: "New Spelling Correction Rule",
    },
  },
  {
    name: "spelling-correction-rules-edit",
    path: "/spelling-correction-rules/:id",
    component: () =>
      import("pages/spelling_corrections/SpellingCorrectionRuleEdit.vue"),
    props: true,
    meta: {
      title: "Edit Spelling Correction Rule",
    },
  },
];
