<template>
  <div
    class="row justify-center full-height volume drag-cancel"
    :class="{ 'items-end': playerIsExpanded }"
    @mousedown="resetHiding()"
    @mouseup="triggerHiding()"
  >
    <QBtn
      flat
      :icon="`ion-ios-volume-${volumeIcon}`"
      :aria-label="$t('player_popout_volume.toggle_volume')"
      @click="toggleVolumeSlider"
    >
      <StreemTooltip>{{
        $t("player_popout_volume.adjust_volume")
      }}</StreemTooltip>
    </QBtn>
    <div class="behind-playerBar">
      <Transition name="slide-right-fade">
        <div
          v-if="volumeSliderVisible"
          class="relative full-height"
        >
          <div
            class="slider column justify-between"
            :class="{ 'slider-expanded': playerIsExpanded }"
          >
            <VueSlider
              v-model="currentVolume"
              direction="btt"
              :width="3"
              :dot-size="15"
              :min="0"
              :max="10"
              :step="1"
              :height="
                playerIsExpanded ? sliderExpandedHeight : sliderDefaultHeight
              "
              :tooltip="'none'"
              real-time
              @update:model-value="updateVolume"
            />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import VueSlider from "vue-slider-component";

import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "PlayerPopoutVolume",
  components: {
    VueSlider,
  },
  props: {
    player: {
      type: HTMLVideoElement,
      required: true,
    },
  },
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();
    const { setPlayerPopoutActiveControl } = universalPlayerStore;

    const { activeControl, playerIsExpanded } =
      storeToRefs(universalPlayerStore);

    return {
      setPlayerPopoutActiveControl,
      activeControl,
      playerIsExpanded,
    };
  },
  data() {
    return {
      storedVolume: 1.0,
      currentVolume: 10,
      timeout: null,
      sliderDefaultHeight: 98,
      sliderExpandedHeight: 80,
    };
  },
  computed: {
    volumeIcon() {
      const volume = this.currentVolume / 10;

      if (volume === 0) {
        return "off";
      }

      if (volume >= 0.66) {
        return "high";
      }

      if (volume >= 0.33) {
        return "low";
      }

      return "mute";
    },
    volumeSliderVisible() {
      return this.activeControl === "volume";
    },
  },
  methods: {
    toggleVolumeSlider() {
      const activeControl = this.volumeSliderVisible ? null : "volume";
      this.setPlayerPopoutActiveControl(activeControl);
    },
    updateVolume() {
      this.player.volume = this.currentVolume / 10;
      this.storedVolume = this.player.volume;
    },
    resetHiding() {
      clearTimeout(this.timeout);
    },
    triggerHiding() {
      this.timeout = setTimeout(
        () => this.setPlayerPopoutActiveControl(null),
        10000
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "shared/css/utils/transitions/slideRightFade";

:deep(.vue-slider-rail) {
  background-color: rgb(0 0 0 / 10%);
  border-radius: 2px;
}

:deep(.vue-slider-process) {
  background-color: white;
  border-radius: 2px;
}

:deep(.vue-slider-dot) {
  background-color: white;
  border-radius: 50%;
}

.slider {
  height: 114px;
  padding: 1em 0.6em;
  position: absolute;
  right: -1.9em;
  bottom: 0;
  background-color: $primary;
  z-index: 10;
  border-radius: 0 3px 3px 0;
}

.slider-expanded {
  height: 92px;
  bottom: -0.6em;
}

.volume {
  margin: 0;
}

:deep(.q-btn__wrapper) {
  min-height: auto !important;
  padding: 0 16px 10px !important;
}
</style>
