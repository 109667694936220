/* eslint-disable no-underscore-dangle */
import localforage from "localforage";

import localForageCordovaSQLiteDriver from "shared/services/localForageSQLiteDriver";

const store = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  name: "streem",
});

export function logStorageDriver(app) {
  store.ready().then(() => {
    let platform = "desktop";

    if (app.config.globalProperties.$isMobile) {
      platform = /Android/.test(navigator.userAgent) ? "android" : "ios";
    }

    // eslint-disable-next-line no-console
    console.log("[Storage Initialized]", { driver: store.driver(), platform });
  });
}

export async function migrateStorage() {
  const newKeys = await store.keys();
  if (newKeys.includes("token")) return;

  const indexedDBStore = localforage.createInstance({
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    name: "streem",
  });

  const oldKeys = await indexedDBStore.keys();
  const keysToMigrate = oldKeys.filter((key) => !newKeys.includes(key));

  const promises = keysToMigrate.map(
    (key) =>
      new Promise((resolve) => {
        indexedDBStore
          .getItem(key)
          .then((value) => store.setItem(key, value))
          .then(resolve);
      })
  );

  await Promise.all(promises);
}

export async function initializeStorage() {
  await store.defineDriver(localForageCordovaSQLiteDriver);

  await store.setDriver([
    localForageCordovaSQLiteDriver._driver,
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
  ]);
}

export default store;
