<template>
  <div>
    <RouterView />

    <div
      v-show="playerOpen"
      class="player-popout-target-container"
    >
      <PortalTarget :name="POPOUT_PLAYER_TARGET" />
    </div>
    <PlayerPopout @close="playerHide" />
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";

import PlayerPopout from "shared/components/players/PlayerPopout.vue";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

import { useGlobalAdminStore } from "src/stores/global";
import { useUserStore } from "src/stores/user";

export default {
  name: "App",
  components: {
    PortalTarget,
    PlayerPopout,
  },
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();
    const { playerOpen } = storeToRefs(universalPlayerStore);
    const { playerHide } = universalPlayerStore;

    const userStore = useUserStore();
    const { isLoggedIn } = storeToRefs(userStore);

    const globalAdminStore = useGlobalAdminStore();
    const { getAppData } = globalAdminStore;

    return {
      isLoggedIn,
      playerHide,
      playerOpen,

      getAppData,
    };
  },
  data() {
    return {
      POPOUT_PLAYER_TARGET,
    };
  },
  watch: {
    $route(to) {
      document.title = to.meta.title ? String(to.meta.title) : "Admin";
    },
  },
  async mounted() {
    // Set initial data
    if (this.isLoggedIn) {
      await this.getAppData();
    }
  },
};
</script>

<style lang="scss" scoped>
.player-popout-target-container {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 347px;
}

.player-popout-dragabble {
  border: none;
}
</style>
