import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import AccountManager from "./AccountManager";
import ProgramAiring from "./ProgramAiring";
import SourceRecord from "./SourceRecord";
import User from "./User";
import ViewedItem from "./ViewedItem";

@Model()
class MediaRequest extends ApplicationRecord {
  static jsonapiType = "media_requests";

  @BelongsTo() source: SourceRecord;

  @BelongsTo() accountManager: AccountManager;

  @BelongsTo() programAiring: ProgramAiring;

  @BelongsTo() user: User;

  @HasMany() thumbnails: any;

  @HasMany({ name: "target" }) viewedItems: ViewedItem[];

  @Attr({ persist: false }) audience: number;

  @Attr() title: string;

  @Attr() url: string;

  @Attr({ persist: false }) mediaUrl: string;

  @Attr() startTime: number;

  @Attr() endTime: number;

  @Attr() status: string;

  @Attr() lockVersion: number;

  @Attr() notifyOnCompletion: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() completedAt: string;

  @Attr() sourceType: string;

  @Attr() type: string;

  @Attr() sourceId: number;

  @Attr({ persist: false }) mediaType: string;

  @Attr({ persist: false }) humanType: string;

  @Attr() token: string;

  @Attr() thumbnailUrl: string;

  @Attr() playerUrl: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr() recipients: string;

  @Attr() originId: number;

  @Attr() originType: string;

  @Attr() originKeyword: string;

  @Attr({ persist: false }) originLabel: string;

  @Attr() notes: string;

  @Attr() summary: string;

  @Attr() billable: boolean;

  @Attr() comment: string;

  @Attr() accountManagerId: number;

  @Attr() userId: number;

  @Attr() organisationTeamId: number;

  @Attr() instantDownload: boolean;

  duplicate() {
    // Deletes properties we can't write with .save()
    const {
      createdAt,
      id,
      mediaType,
      originLabel,
      playerUrl,
      token,
      type,
      humanType,
      ...attrs
    } = this;

    return attrs;
  }

  duplicateCancelled() {
    // Deletes properties we can't write from a cancelled Media Request.
    const {
      archivedAt,
      createdAt,
      comment,
      id,
      mediaType,
      originLabel,
      playerUrl,
      token,
      status,
      humanType,
      type,
      ...attrs
    } = this;

    return attrs;
  }

  async refreshLockVersion() {
    this.lockVersion = (
      await MediaRequest.where({ id: this.id })
        .select(["lockVersion"])
        .find(this.id as string)
    ).data.lockVersion;
  }
}

export default MediaRequest;
