import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "user-requests",
    path: "/user_requests",
    props: (route) => typecastRouteProps(route),
    component: () => import("pages/userRequests/UserRequestsPage.vue"),
    meta: {
      title: "User Requests",
      accessLevel: "organisation_read",
    },
  },
];
