import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class UcpMediaObject extends ApplicationRecord {
  static jsonapiType = "ucp_media_objects";

  @Attr() externalId: string;

  @Attr() mimeType: string;

  @Attr() description: string;

  @Attr() mediaId: number;
}

export default UcpMediaObject;
