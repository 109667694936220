import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import TvChannel from "./TvChannel";

@Model()
class TvCaption extends ApplicationRecord {
  static jsonapiType = "tv_captions";

  @BelongsTo() source: TvChannel;

  @HasMany() siblings: TvCaption[];

  @Attr() text: string;

  @Attr() startTime: number;

  @Attr() endTime: number;

  @Attr() programExclusion: boolean;

  @Attr() sourceId: number;

  @Attr() excludedFrom: string;
}

export default TvCaption;
