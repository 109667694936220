export default [
  {
    name: "brief-set-add",
    path: "/organisations/:organisationId/brief_sets/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/brief_sets/BriefSetEditPage.vue"),
    meta: {
      title: "Create Brief Set",
    },
  },
  {
    name: "brief-set-edit",
    path: "/organisations/:organisationId/brief_sets/:briefSetId/edit",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/brief_sets/BriefSetEditPage.vue"),
    meta: {
      title: "Edit Brief Set",
    },
  },
];
