import { getLocaleText } from "shared/boot/i18n";

export function attachmentUrl(blob) {
  return `${import.meta.env.VITE_API_BASE_URL}${blob.relativeUrl}`;
}

export async function getAttachmentMetadata(blob) {
  try {
    if (blob.video) {
      const video = await new Promise((resolve) => {
        const videoElement = document.createElement("video");
        videoElement.preload = "metadata";
        videoElement.onloadedmetadata = () => resolve(videoElement);
        videoElement.src = attachmentUrl(blob);
      });

      return {
        height: video.videoHeight,
        width: video.videoWidth,
        duration: video.duration,
      };
    }

    const image = new Image();
    image.src = attachmentUrl(blob);

    await image.decode();

    return {
      height: image.height,
      width: image.width,
    };
  } catch (error) {
    throw new Error(getLocaleText("helpers.attachment.could_not_load_image"));
  }
}
