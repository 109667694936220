import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Journalist from "./Journalist";

@Model()
class JournalistPhoneNumber extends ApplicationRecord {
  static jsonapiType = "journalist_phone_numbers";

  @BelongsTo() journalist: Journalist;

  @Attr() label: string;

  @Attr() phoneNumber: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default JournalistPhoneNumber;
