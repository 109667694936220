<template>
  <div class="player-popout">
    <div class="flex justify-between">
      <div class="player-popout-title row">
        <PlayerPopoutListItem
          :item="initialClip"
          :index="clipListIndex + 1"
          :tooltip="$t('player_popout_controls.playing')"
          @play-item="$emit('toggle-playlist')"
        />
      </div>
      <div class="flex">
        <ActionSheet
          :mention="mention"
          :origin="mention.origin"
          :on-nav-bar="$isMobile"
          :button-color="'white'"
          class="mention-dropdown"
        />
        <QBtn
          flat
          icon="ion-md-close"
          :aria-label="$t('player_popout_controls.close_player')"
          class="close"
          @click="$emit('close')"
        >
          <StreemTooltip>{{ $t("global.close") }}</StreemTooltip>
        </QBtn>
      </div>
    </div>
    <div
      v-if="playerIsExpanded"
      class="flex"
    >
      <div class="flex full-width justify-between items-center q-pb-sm">
        <div class="player-popout-slider">
          <QSlider
            v-model="timeModel"
            color="white"
            :min="0"
            :max="clipDuration"
            :step="clipStep"
            @update:model-value="$emit('seek')"
          />
        </div>
        <div class="player-popout-time">
          <span class="playerPopoutTime-value smaller">
            {{ formatTime(timeModel) }}
          </span>
        </div>
        <PlayerPopoutButtons
          :disabled="disabled"
          :first-play="firstPlay"
          :is-playing="isPlaying"
          :loading="loading"
          :no-next-clip="noNextClip"
          :no-previous-clip="noPreviousClip"
          show-next
          show-previous
          :is-audio="isAudio"
          @fastforward="$emit('fastforward')"
          @rewind="$emit('rewind')"
          @skip-next="$emit('skip-next')"
          @skip-previous="$emit('skip-previous')"
          @toggle-play="$emit('toggle-play')"
        />
        <div>
          <QBtn
            flat
            :aria-label="$t('player_popout_controls.toggle_playlist')"
            class="playlist"
            @click="$emit('toggle-playlist')"
          >
            <StreemTooltip>{{
              $t("player_popout_controls.queue")
            }}</StreemTooltip>
            <div class="playlist-count">
              <span v-if="showPlayList">
                {{
                  $t("player_popout_controls.playlist_queued", {
                    playerlistCount: playerList.length,
                  })
                }}
              </span>
              <span v-else>
                {{
                  $t("player_popout_controls.playlist_count", {
                    playerlistCount: playerList.length,
                  })
                }}
              </span>
            </div>
          </QBtn>
        </div>
        <PlayerPopoutVolume
          :player="player"
          :aria-label="$t('player_popout_controls.toggle_volume')"
        />
      </div>
    </div>
    <div
      v-else
      class="flex"
    >
      <div class="flex full-width justify-between">
        <PlayerPopoutButtons
          :disabled="disabled"
          :first-play="firstPlay"
          :is-playing="isPlaying"
          :loading="loading"
          :no-next-clip="noNextClip"
          :no-previous-clip="noPreviousClip"
          show-next
          show-previous
          :is-audio="isAudio"
          @fastforward="$emit('fastforward')"
          @rewind="$emit('rewind')"
          @skip-next="$emit('skip-next')"
          @skip-previous="$emit('skip-previous')"
          @toggle-play="$emit('toggle-play')"
        />
        <div>
          <QBtn
            flat
            :aria-label="$t('player_popout_controls.toggle_playlist')"
            class="playlist"
            @click="$emit('toggle-playlist')"
          >
            <StreemTooltip>{{
              $t("player_popout_controls.queue")
            }}</StreemTooltip>
            <div class="playlist-count">
              <span v-if="showPlayList">
                {{
                  $t("player_popout_controls.playlist_queued", {
                    playerlistCount: playerList.length,
                  })
                }}
              </span>
              <span v-else>
                {{
                  $t("player_popout_controls.playlist_count", {
                    playerlistCount: playerList.length,
                  })
                }}
              </span>
            </div>
          </QBtn>
        </div>
      </div>
      <div class="flex full-width justify-between">
        <div class="player-popout-slider">
          <QSlider
            v-model="timeModel"
            color="white"
            :min="0"
            :max="clipDuration"
            :step="clipStep"
            @update:model-value="$emit('seek')"
          />
        </div>
        <div class="player-popout-time">
          <span class="playerPopoutTime-value smaller">
            {{ formatTime(timeModel) }}
          </span>
        </div>
        <PlayerPopoutVolume
          :player="player"
          :aria-label="$t('player_popout_controls.toggle_volume')"
        />
      </div>
    </div>
    <div class="behind-player-popout">
      <Transition name="slide-up-fade">
        <div
          v-if="showPlayList"
          class="playlist-container full-height"
        >
          <PlayerPopoutList
            class="playlist"
            :class="{
              'playlist-video': !isAudio && playerList.length,
              'playlist-video-expanded':
                !isAudio && playerList.length && playerIsExpanded,
            }"
            :list="playerList"
            :current-clip="initialClip"
            :is-playing="playerIsPlaying"
            @close-playlist="$emit('toggle-playlist')"
            @play-item="playItem($event)"
            @clear-list="$emit('clear-list')"
            @remove-item="removeItem($event)"
          />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { dateInTimeZone, getTimezone } from "shared/helpers/date";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

import PlayerPopoutButtons from "./PlayerPopoutButtons.vue";
import PlayerPopoutList from "./PlayerPopoutList.vue";
import PlayerPopoutListItem from "./PlayerPopoutListItem.vue";
import PlayerPopoutVolume from "./PlayerPopoutVolume.vue";

export default {
  name: "PlayerPopoutControls",
  components: {
    PlayerPopoutButtons,
    PlayerPopoutList,
    PlayerPopoutListItem,
    PlayerPopoutVolume,
  },
  props: {
    player: {
      type: HTMLVideoElement,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    isPlaying: {
      type: Boolean,
    },
    firstPlay: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    noPreviousClip: {
      type: Boolean,
    },
    noNextClip: {
      type: Boolean,
    },
    playedClip: {
      type: Object,
      default: () => ({}),
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    timeZone: {
      type: String,
      default: getTimezone(),
    },
    initialClip: {
      type: Object,
      default: () => ({}),
    },
    clipListIndex: {
      type: Number,
      default: 0,
    },
    isAudio: {
      type: Boolean,
    },
  },
  emits: [
    "toggle-playlist",
    "close",
    "seek",
    "fastforward",
    "rewind",
    "skip-next",
    "skip-previous",
    "toggle-play",
    "clear-list",
    "play-item",
    "remove-item",
  ],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();

    const { playerList, activeControl, playerIsPlaying, playerIsExpanded } =
      storeToRefs(universalPlayerStore);

    return {
      playerList,
      activeControl,
      playerIsPlaying,
      playerIsExpanded,
    };
  },
  computed: {
    timeModel: {
      get() {
        return !this.loading && this.playedClip.start_time
          ? this.currentTime
          : 0;
      },
      set(value) {
        this.player.currentTime = value;
      },
    },
    clipDuration() {
      return this.playedClip.end_time
        ? this.playedClip.end_time - this.playedClip.start_time
        : 0.1;
    },
    clipStep() {
      return this.playedClip ? this.clipDuration / 100 : 0.1;
    },
    showPlayList() {
      return this.activeControl === "playList";
    },
    mention() {
      if (this.isAudio) return this.playedClip;

      return {
        ...this.initialClip,
        id: this.initialClip.mentionId,
      };
    },
  },
  methods: {
    formatTime(inputSeconds) {
      if (!this.playedClip.start_time || this.loading) return "--:--:--";

      const timestamp = Math.trunc(this.playedClip.start_time + inputSeconds);

      return dateInTimeZone(timestamp, "h:mm:ss aaa", this.timeZone);
    },
    playItem(clip) {
      this.$emit("play-item", clip);
    },
    removeItem(clip) {
      this.$emit("remove-item", clip);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "shared/css/utils/transitions/slideUpFade";

.player-popout {
  background-color: $primary;
  border-radius: 0 0 3px 3px;
  color: white;

  &.player-popout-audio {
    border-radius: 3px;
  }
}

.player-popout-title {
  padding: 8px 0 8px 14px;
  min-height: 48px;
  max-width: 260px;
}

.player-popout-slider {
  width: 210px;
  padding: 0 10px 0 14px;

  :deep(.q-slider--h) {
    height: 20px;
  }
}

.playlist-count {
  font-weight: normal;
  font-size: 13px;
}

:deep(.behind-player-popout) {
  position: relative;
  z-index: -1;
}

:deep(.playlist-container) {
  position: relative;

  & .playlist {
    position: absolute;
    width: 347px;
    max-height: 20em;
    overflow: scroll;
    bottom: 110px;
    right: 0;
    background-color: $primary;
    padding-bottom: 10px;
    color: $silver-white;
    border-radius: 3px;
  }

  & .playlist.playlist-video {
    bottom: 299px;
  }

  & .playlist.playlist-video-expanded {
    bottom: 479px;
    width: 700px;
  }

  & > .playlist-item {
    position: absolute;
    bottom: 28px;
    right: -100%;
    background-color: #333;
    padding: 0.25em;
  }
}
</style>
