export default [
  {
    name: "organisation-transcript-template-add",
    path: "/organisations/:organisationId/organisation_transcript_templates/new",
    props: true,
    component: () => import("pages/OrganisationTranscriptTemplateEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "New Organisation Transcript Template",
    },
  },
  {
    name: "organisation-transcript-template-edit",
    path: "/organisations/:organisationId/organisation_transcript_templates/:organisationTranscriptTemplateId/edit",
    props: true,
    component: () => import("pages/OrganisationTranscriptTemplateEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Organisation Transcript Template",
    },
  },
];
