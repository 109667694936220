import { HasMany, Model } from "spraypaint";

import MediaRequest from "shared/resources/MediaRequest";
import SourceRestriction from "shared/resources/SourceRestriction";
import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class SourceRecord extends ApplicationRecord {
  static jsonapiType = "source_records";

  @HasMany() sourceRestrictions: SourceRestriction[];

  @HasMany() mediaRequests: MediaRequest[];
}

export default SourceRecord;
