import LogRocket from "logrocket";
import { storeToRefs } from "pinia";

import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

function getLogrocketAppId(isAdminUser = false) {
  if (!import.meta.env.VITE_ENV.includes("production")) return "";

  const globalStore = useGlobalStore();
  const { appConfig } = storeToRefs(globalStore);

  const { adminUserLogrocketAppId, logrocketAppId } = appConfig.value;

  return isAdminUser ? adminUserLogrocketAppId : logrocketAppId;
}

function initialize({ isAdminUser = false } = {}) {
  const logrocketAppId = getLogrocketAppId(isAdminUser);
  if (!logrocketAppId) return false;

  const logrocketConfig = {
    dom: {
      release: "__commit_hash__",
      baseHref: "__gcp_bucket_base_href__",
    },
  };

  LogRocket.init(logrocketAppId, {
    ...logrocketConfig,
  });

  useUserStore().$onAction(({ name }) => {
    if (name === "loginSuccess") {
      LogRocket.log("action", { name });
    }
  });

  return LogRocket;
}

export default initialize;

export { initialize };
