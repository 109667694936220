import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import FeatureTeaser from "./FeatureTeaser";
import InstantInsightsReport from "./InstantInsightsReport";
import MediaRequest from "./MediaRequest";
import TranscriptRequest from "./TranscriptRequest";
import type { Features } from "./types";
import User from "./User";

@Model()
class ViewedItem extends ApplicationRecord {
  static jsonapiType = "viewed_items";

  @BelongsTo() target:
    | MediaRequest
    | TranscriptRequest
    | FeatureTeaser
    | InstantInsightsReport;

  @BelongsTo() user: User;

  @Attr() userId: number;

  @Attr() feature: Features;

  @Attr() viewedAt: string;

  @Attr() targetType: string;

  @Attr() targetId: number;
}

export default ViewedItem;
