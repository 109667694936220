import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OutreachLocation from "./OutreachLocation";

@Model()
class OrganisationOutreachLocationExclusion extends ApplicationRecord {
  static jsonapiType = "organisation_outreach_location_exclusions";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() outreachLocation: OutreachLocation;

  @Attr() organisationId: number;

  @Attr() outreachLocationId: number;
}

export default OrganisationOutreachLocationExclusion;
