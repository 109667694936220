import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

export const useGlobalStore = defineStore("global", {
  state,
  actions,
  getters,
});

export default useGlobalStore;
