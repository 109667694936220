import { Attr, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class XeroContact extends ApplicationRecord {
  static jsonapiType = "xero_contacts";

  @Attr() name: string;

  @Attr() guid: string;

  @HasMany() organisation: Organisation;
}

export default XeroContact;
