import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "podcast_episodes",
    path: "/podcast_episodes",
    component: () => import("pages/PodcastEpisodesPage.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Podcast Episodes",
    },
  },
  {
    name: "podcast_episodes-edit",
    path: "/podcast_episodes/:id/edit",
    component: () => import("pages/PodcastEpisodeEdit.vue"),
    props: true,
    meta: {
      title: "Edit Podcast Episode",
    },
  },
  {
    name: "podcast_episodes-new",
    path: "/podcast_episodes/new",
    component: () => import("pages/PodcastEpisodeEdit.vue"),
    meta: {
      title: "Create Podcast Episode",
    },
  },
];
