import { getLocaleText } from "shared/boot/i18n";

export * from "./social";
export * from "./regexp";
export * from "./reports";
export * from "./outreach";
export * from "./outreach-ai";
export * from "./industries";
export * from "./organisation";
export * from "./locations";
export * from "./currencies";

export const debug = Boolean(import.meta.env.VITE_DEBUG);
export const defaultTimezone = import.meta.env.VITE_TIME_ZONE;
export const isAdminMode = Boolean(import.meta.env.VITE_ADMIN_MODE);
export const v1BaseUrl = `${import.meta.env.VITE_API_BASE_URL}/v1`;
export const adminBaseUrl = `${import.meta.env.VITE_API_BASE_URL}/admin`;
export const publicBaseUrl = `${import.meta.env.VITE_API_BASE_URL}/public`;
export const directUploadUrl = `${
  import.meta.env.VITE_API_BASE_URL
}/rails/active_storage/direct_uploads`;
export const blobRedirectUrl = "/rails/active_storage/blobs/redirect";
export const stockAliases = { "^AORD": "XAO" };
export const streamTypes = {
  mentionStream: 0,
  bookmarkStream: 1,
  reportingStream: 2,
  socialStream: 3,
  organisationBrief: 4,
};

export function getAccessLevels() {
  return {
    read_write: {
      label: getLocaleText("global.edit"),
      permissions: ["read_write", "read_only"],
    },
    read_only: {
      label: getLocaleText("global.view"),
      permissions: ["read_only"],
    },
  };
}

export const mentionStreamTypes = [
  streamTypes.mentionStream,
  streamTypes.organisationBrief,
];
export const brandProfileTypes = {
  reporting: "OrganisationBrandReporting",
  outreach: "OrganisationBrandOutreach",
};

export const CDN_ICON_PATH = "https://assets.streem.com.au/icons/";

export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const shortWeekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const tagPalette = {
  "#70d687": { fontColor: "white" },
  "#5cdbd3": { fontColor: "white" },
  "#69c0ff": { fontColor: "white" },
  "#85a5ff": { fontColor: "white" },
  "#b37feb": { fontColor: "white" },
  "#ff85c0": { fontColor: "white" },
  "#ff7875": { fontColor: "white" },
  "#ff9143": { fontColor: "white" },
  "#c3f7cf": { fontColor: "#4b9f5d" },
  "#b5f5ec": { fontColor: "#08979c" },
  "#bae7ff": { fontColor: "#1890ff" },
  "#d6e4ff": { fontColor: "#597ef7" },
  "#efdbff": { fontColor: "#9254de" },
  "#ffd6e7": { fontColor: "#f759ab" },
  "#ffccc7": { fontColor: "#ff4d4f" },
  "#ffe0bd": { fontColor: "#fa6f19" },
};

export const defaultBookmarkPalette = {
  "var(--s-color-blue-2)": { fontColor: "var(--s-color-denim-10)" },
};

export const defaultTagColor = "#70d687";

export const DAY_IN_MILLISECONDS = 86400000;
export const DAY_IN_SECONDS = 86400;
export const DAY_IN_MINUTES = 1440;
export const DAYS_PER_WEEK = 7;
export const MINUTE_IN_MILLISECONDS = 60000;
export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_MILLISECONDS = 3600000;
export const HOUR_IN_SECONDS = 3600;
export const HOUR_IN_MINUTES = 60;
export const HALF_A_SECOND = 500;

export const NEXT_CLIP_OFFSET = 61;

export const MAX_ITEM_REQUEST_DURATION_HOURS = 1.5;

// Stream Expiry
export const STREAM_EXTENDED_EXPIRY_DAYS = 3;
export const streamExpiryOptions = [
  { value: 0, label: "Never" },
  { value: 1, label: "24 hours" },
  { value: 3, label: "3 days" },
  { value: 7, label: "7 days" },
  { value: 14, label: "2 weeks" },
  { value: 28, label: "1 month" },
];

export const POPOUT_PLAYER_TARGET = "player-popout-target";

export const derRoles = [
  { label: "Drafter", value: 1, class: "bg-radio" },
  { label: "Proofreader", value: 2, class: "bg-online" },
  { label: "Approver", value: 3, class: "bg-tv" },
];

export const reportTypesDisplayKeypath = {
  mentions: "constants.index.mentions",
  insights: "constants.index.insights",
  social_analyse: "constants.index.social_analyse",
  social_mentions: "constants.index.social_mentions",
};

export const streamHydrationStates = {
  completed: 0,
  requested: 1,
  running: 2,
  failed: 3,
  triggered: 4,
};

export const SOCIAL_ENGAGE_POST_STATES = {
  draft: 0,
  pending_approval: 1,
  scheduled: 2,
  live: 3,
  queued: 4,
  error: 5,
  rejected: 7,
  partially_sent: 8,
};
export const SOCIAL_ENGAGE_POST_STATE_LOOKUP = Object.entries(
  SOCIAL_ENGAGE_POST_STATES
).reduce((obj, [key, value]) => Object.assign(obj, { [value]: key }), {});

export const SOCIAL_ENGAGE_POST_EVENT_LOOKUP = {
  1: "created",
  2: "edited",
  3: "approved",
  4: "post published",
  5: "scheduled",
  6: "approval requested",
  7: "unpublish requested",
  8: "post removed",
  9: "delete requested",
  10: "deleted",
  11: "unpublish approved",
  12: "returned to draft",
  13: "rejected",
  14: "post failed",
  15: "retried",
};

export const SOCIAL_ENGAGE_CHARACTER_LIMITS = {
  facebook: 63206,
  twitter: 280,
  instagram: 2200,
  linkedin: 3000,
};

export const socialAutoRefreshEnabled = true;

export const defaultMentionsFields =
  "source,program_airing,excerpts,keywords,word_count,audience,thumbnails,syndications,related_article";

// The percentage of the chart that is cut out of the middle.
export const chartCutOuts = {
  slimDoughnut: 78,
  doughnut: 50,
  pie: 0,
};

export const organisationContractTypes = [
  {
    id: 1,
    label: "SLA",
  },
  {
    id: 2,
    label: "Additional Daily Email Report",
  },
  {
    id: 3,
    label: "Additional Transcripts or Instant Edit Transcripts",
  },
  {
    id: 4,
    label: "Additional Media Item Downloads or Instant Edit Downloads",
  },
  {
    id: 5,
    label: "Additional Print Allowance",
  },
  {
    id: 6,
    label: "Additional Users",
  },
  {
    id: 7,
    label: "Additional Media Analysis Reporting",
  },
  {
    id: 8,
    label: "Additional Scope (e.g. NZ Content, InternationalPlus, CALD)",
  },
  {
    id: 9,
    label: "Additional Social Monitoring (Influencer)",
  },
  {
    id: 10,
    label: "Additional Social Monitoring (Engage only)",
  },
  {
    id: 11,
    label: "Additional Social Monitoring (Monitoring, Analysis and Engage)",
  },
  {
    id: 12,
    label: "Additional Keywords or Clients (if PR Agency)",
  },
  {
    id: 13,
    label: "Additional Service (e.g Outreach, CRM)",
  },
];

export const contractTypes = {
  SLA: 1,
  additionalDailyEmailReport: 2,
  additionalTranscripts: 3,
  additionalMediaItemDownloads: 4,
  additionalPrintAllowance: 5,
  additionalUsers: 6,
  additionalMediaAnalysisReporting: 7,
  additionalScope: 8,
  additionalSocialMonitoringInfluencer: 9,
  additionalSocialMonitoringEngageOnly: 10,
  additionalSocialMonitoring: 11,
  additionalKeywordsOrClients: 12,
};

export const organisationContractTiers = [
  { label: "Tier 1 - more than $8,000 per month (excl GST)", value: 1 },
  { label: "Tier 2 - $3,750 to $8,000 per month (excl GST)", value: 2 },
  { label: "Tier 3 - $1,750 to $3,750 per month (excl GST)", value: 3 },
  { label: "Tier 4 - up to $1,750 per month (excl GST)", value: 4 },
  { label: "Project / Analysis", value: 5 },
];

export const UPSELL_LOCKED_FOR_DAYS = 4 * 7; // 4 weeks;

export const upsellRegistrationContractServices = {
  social_listen: "Streem Social - Listen/Analyse",
  social_engage: "Streem Social - Engage",
  influencer_social: "Influencer Social",
  outreach: "Outreach",
  cald: "CALD",
  podcasts: "Podcasts",
  new_zealand_online_content: "New Zealand Online Content",
  new_zealand_print_content: "New Zealand Print Content",
  magazines: "Magazines",
  international_plus: "InternationalPlus",
  instant_edit_downloads: "Instant Edit Downloads",
  instant_edit_transcripts: "Instant Edit Transcripts",
  media_item_downloads: "Media Item Downloads",
  transcripts: "Transcripts",
  insights_plus: "Insights Plus",
  insights: "Media Analysis & Insights",
  crm: "CRM",
  print: "Print Items",
  search_available_content: "Search Available Content",
  streaming_links: "Streaming Links",
  der: "DER",
};

export const FeatureToUpsellDashboardFeature = {
  has_social_platform: "Social Listen/Analyse",
  has_social_engage: "Social Engage",
  social_mentions: "Influencer Social",
  has_outreach_media_releases: "Outreach",
  has_outreach_activities_lists_search: "Outreach Lite",
  has_cald_sources: "CALD",
  podcast_mentions: "Podcasts",
  view_new_zealand_online_content: "NZ Online",
  view_new_zealand_print_and_magazine_content: "NZ Print and Magazine",
  view_magazine_articles: "Magazines",
  has_international_plus: "InternationalPlus",
  has_instant_edit_media_item: "Instant Edit Downloads",
  has_instant_edit_transcript: "Instant Edit Transcripts",
  media_request: "Media Items",
  audio_transcript: "Transcripts",
  has_journalist_enquiries: "CRM",
  view_print_articles: "Print",
  has_search_available_content: "Search Available Content",
  has_organisation_report_broadcast_preview_links: "Streaming Links",
};

export const FeaturesMappedToServices = {
  has_social_platform: "social_listen",
  has_social_engage: "social_engage",
  social_mentions: "influencer_social",
  has_outreach: "outreach",
  has_cald_sources: "cald",
  podcast_mentions: "podcasts",
  view_new_zealand_online_content: "new_zealand_online_content",
  view_new_zealand_print_and_magazine_content:
    "new_zealand_print_and_magazine_content",
  view_magazine_articles: "magazines",
  has_international_plus: "international_plus",
  has_instant_edit_media_item: "instant_edit_downloads",
  has_instant_edit_transcript: "instant_edit_transcripts",
  media_request: "media_item_downloads",
  audio_transcript: "transcripts",
  has_journalist_enquiries: "crm",
  view_print_articles: "print",
  has_search_available_content: "search_available_content",
  has_organisation_report_broadcast_preview_links: "streaming_links",
};

export const reasonsToArchiveAcompany = [
  "Poorly Qualified",
  "Ceased Monitoring",
  "Budget Cut",
  "Product",
  "BD/Account Service",
  "Short Term Deal",
  "Invoicing Dispute",
  "Unknown",
  "COVID-19",
  "Machinery of Government Change",
  "Lack of Social",
  "Key Contact MMO Preference",
  "Full Service Upgrade",
  "Licencing Change",
  "Merger/Acquisition",
];

export const competitors = [
  "Meltwater",
  "Isentia",
  "Streem",
  "Unknown",
  "Ceased Monitoring",
  "N/A",
];

export const ONE_MB_IN_BYTES = 1048576;

export const audienceDataSuppliers = [
  "oztam",
  "nielsen",
  "regional_tam",
  "regional_tam_aggregation",
];

export const websiteScrapers = ["SimpleScraper", "DiffbotScraper"];

export const audienceDataTypesRequireSchedule = ["regional_tam"];

const transcriptRequestStaleErrorMessage =
  "This Transcript Request has already been updated by another user so your changes cannot be saved. " +
  "Please refresh this page or open this Transcript Request in another browser window and copy your changes over.";

const tvMediaRequestStaleErrorMessage =
  "This TV Media Request has already been updated by another user so your changes cannot be saved. " +
  "Please refresh this page or open this TV Media Request in another browser window and copy your changes over.";

const radioMediaRequestStaleErrorMessage =
  "This Radio Media Request has already been updated by another user so your changes cannot be saved. " +
  "Please refresh this page or open this Radio Media Request in another browser window and copy your changes over.";

export const STALE_ERROR_MESSAGE = {
  transcriptRequest: transcriptRequestStaleErrorMessage,
  tvMediaRequest: tvMediaRequestStaleErrorMessage,
  radioMediaRequest: radioMediaRequestStaleErrorMessage,
};

// query snippets regex:
// valid snippets should be {qs-:id-:suffix}
// where :suffix is only present to help users identify the snippet
export const querySnippetRegex = /{qs-\d+(-\S*)?}/g;

export const invoiceStatuses = {
  draft: "Draft",
  approved: "Approved",
  pending: "Pending creation in Xero",
  xero: "Invoice created in Xero",
  error: "Failed to create in Xero",
  defer: "Deferred",
  write_off: "Invoice written off",
  manual: "Manually raised",
  hold: "On Hold",
};

export const invoiceTypes = {
  usage: "Usage",
  contract: "Contract",
};

export const maxCardTitleLength = 65;

export const notificationDeliveryMethods = {
  email: "Email",
  mobile: "Mobile",
  slack: "Slack",
  microsoftTeams: "Teams",
};

export const notificationTypes = {
  standard: "StreamNotificationStandard",
  spike: "StreamNotificationSpike",
};

export const maxBriefsPerClient = 3;

export const insightsReportTypes = [
  {
    label: "Quantitative",
    value: "quantitative",
  },
  {
    label: "Qualitative",
    value: "qualitative",
  },
  {
    label: "Insights Plus",
    value: "insights_plus",
  },
];

export const insightsEntityTypes = [
  {
    label: "Sentiment",
    value: "Sentiment",
  },
  {
    label: "Highlight",
    value: "Highlight",
  },
];

export const insightsTabsByReportType = {
  quantitative: [
    { label: "Reports", name: "reports" },
    { label: "Queries", name: "queries" },
    { label: "Topics", name: "topics" },
    { label: "Report Groups", name: "groups" },
  ],
  qualitative: [
    { label: "Reports", name: "reports" },
    { label: "Queries", name: "queries" },
    { label: "Topics", name: "topics" },
    { label: "Entities", name: "entities" },
    { label: "Report Groups", name: "groups" },
  ],
  insights_plus: [
    { label: "Reports", name: "reports" },
    { label: "Queries", name: "queries" },
    { label: "Report Groups", name: "groups" },
  ],
};

export const insightsSentimentOptions = [
  { label: "40", value: 40 },
  { label: "45", value: 45 },
  { label: "50", value: 50 },
  { label: "55", value: 55 },
  { label: "60", value: 60 },
];

export const insightsInfluenceOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
];

export const instantInsightsPlusPendingStatuses = [
  "indexing_pending",
  "indexing_in_progress",
  "indexing_completed",
  "building_pending",
  "building_in_progress",
  "html_in_progress",
  "pdf_in_progress",
];

export const insightsQueryProcessingStatuses = [
  "export_pending",
  "export_in_progress",
  "sentiment_rating_pending",
  "sentiment_rating_in_progress",
];

export const instantInsightsDoubleWidthChartSortTooltip =
  "Double width charts are pinned to the top of your dashboard.";

export const scrapeFailureTypes = {
  "DiffbotScraperService::DiffbotApiError": "Diffbot failed to scrape",
  "ScrapeOnlineArticle:SimpleScraperError": "NewsScraper failed to scrape",
  "ScrapeOnlineArticle:TitleOrBodyBlank":
    "NewsScraper returned no title and/or body",
};

export const tvProgramCategoryRelevanceSettings = [
  {
    value: true,
    label: "Relevant",
  },
  {
    value: false,
    label: "Not Relevant",
  },
];

export const billingCycles = [
  {
    id: 0,
    label: "Monthly in advance",
    value: "MTH-ADV",
  },
  {
    id: 1,
    label: "Monthly in arrears",
    value: "MTH-ARR",
  },
  {
    id: 2,
    label: "Quarterly in advance",
    value: "QRT-ADV",
  },
  {
    id: 3,
    label: "Quarterly in arrears",
    value: "QRT-ARR",
  },
  {
    id: 4,
    label: "Halfyearly in advance",
    value: "HLF-ADV",
  },
  {
    id: 5,
    label: "Halfyearly in arrears",
    value: "HLF-ARR",
  },
  {
    id: 6,
    label: "Annual in advance",
    value: "ANN-ADV",
  },
  {
    id: 7,
    label: "Short term - unspecified",
    value: "PROJECT",
  },
];

export const mrrServiceGroups = {
  traditional: "mediaMonitoring",
  social_listen: "streemSocial",
  social_engage: "streemSocial",
  crm: "mediaMonitoring",
  ccp: "mediaMonitoring",
  outreach: "mediaMonitoring",
  der: "mediaMonitoring",
  insights: "mediaAnalysisAndInsights",
  insights_plus: "mediaAnalysisAndInsights",
  international_plus: "mediaMonitoring",
  international_lite: "mediaMonitoring",
  international_max: "mediaMonitoring",
  cald: "mediaMonitoring",
  additional_users: "mediaMonitoring",
  media_item_downloads: "mediaMonitoring",
  instant_edit_downloads: "mediaMonitoring",
  transcripts: "mediaMonitoring",
  instant_edit_transcripts: "mediaMonitoring",
  influencer_social: "mediaMonitoring",
  print: "mediaMonitoring",
  new_zealand_content: "mediaMonitoring",
  additional_clients_keywords: "mediaMonitoring",
  magazines: "mediaMonitoring",
  podcasts: "mediaMonitoring",
  radio_tv_broadcasts: "mediaMonitoring",
  sky_news: "mediaMonitoring",
  ave: "mediaMonitoring",
  new_zealand_online_content: "mediaMonitoring",
  new_zealand_print_content: "mediaMonitoring",
  search_available_content: "mediaMonitoring",
  tveyes: "mediaMonitoring",
  lexisnexis: "mediaMonitoring",
  nla: "mediaMonitoring",
  cla: "mediaMonitoring",
  financial_times: "mediaMonitoring",
  risk_score: "mediaMonitoring",
};

export const broadcastMedia = [
  {
    id: "tv",
    label: "TV",
  },
  {
    id: "radio",
    label: "Radio",
  },
];

export const ORGANISATION_REPORT_SPEC_PRODUCTS = {
  report_pdf: "Report PDF",
  front_pages_pdf: "Front Pages PDF",
  front_and_back_page_summaries: "Front/Back Page Summaries",
  australia_front_page_summaries: "Australia Front Page Summaries",
  australia_back_page_summaries: "Australia Back Page Summaries",
  new_zealand_front_page_summaries: "New Zealand Front Page Summaries",
  new_zealand_back_page_summaries: "New Zealand Back Page Summaries",
  coverage_summaries: "Coverage Summaries",
  automatic_summary: "Automatic Summary",
  afr_pdf: "AFR PDF",
  twitter_table: "Twitter Table",
  leading_news_table: "Leading News Table",
  stock_prices: "Stock Prices",
  in_focus: "In Focus",
};

export const salutationOptions = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Miss", value: "Miss" },
  { label: "Dr.", value: "Dr." },
  { label: "Ms.", value: "Ms." },
  { label: "Prof.", value: "Prof." },
  { label: "Rev.", value: "Rev." },
];

export const contentTeaserTiles = [
  {
    id: "influencer_social",
    features: ["social_mentions"],
  },
  {
    id: "podcast",
    features: ["podcast_mentions"],
  },
  {
    id: "magazine",
    features: ["view_magazine_articles"],
  },
  {
    id: "international",
    features: ["has_international_max", "has_international_plus"],
  },
  {
    id: "cald",
    features: ["has_cald_sources"],
  },
  {
    id: "nz_content",
    features: [
      "view_new_zealand_online_content",
      "view_new_zealand_print_and_magazine_content",
    ],
  },
];

export const organisationServiceLevels = [
  {
    label: "Tier 1+",
    id: "tier_one_plus",
    region: ["au", "nz"],
  },
  {
    label: "Standard Account Management - Tier 1",
    id: "standard_account_management_tier_one",
    region: ["au", "nz"],
  },
  {
    label: "Standard Account Management - Tier 2",
    id: "standard_account_management_tier_two",
    region: ["au", "nz"],
  },
  {
    label: "Standard Account Management - Tier 3",
    id: "standard_account_management_tier_three",
    region: ["au", "nz"],
  },
  {
    label: "Customer Success - Tier 3",
    id: "customer_success_tier_three",
    region: ["au", "nz"],
  },
  {
    label: "Customer Success - Tier 4",
    id: "customer_success_tier_four",
    region: ["au", "nz"],
  },
  {
    label: "Customer Success - Tier 5",
    id: "customer_success_tier_five",
    region: ["au", "nz"],
  },
  {
    label: "Tier 1",
    id: "tier_one",
    region: ["uk", "us"],
  },
  {
    label: "Tier 2",
    id: "tier_two",
    region: ["uk", "us"],
  },
];

export const ORGANISATION_SOCIAL_HELP_CENTER_CONTACT_OPTIONS = [
  {
    label: "Social - Customer Success Manager",
    id: "customer_success",
    locale: "en-AU",
  },
  {
    label: "Standard - Without Contact Information",
    id: "without_contact",
    locale: "en-GB",
  },
];

export const ORGANISATION_SOCIAL_SUPPORT_CONTACT_OPTIONS = [
  {
    label: "Social - Customer Success Manager",
    id: "customer_success",
    locale: "en-AU",
  },
  {
    label: "Standard",
    id: "standard",
    locale: "en-GB",
  },
];

export const organisationFeatureBatches = [
  { value: 1, label: "1 - Outreach" },
  { value: 2, label: "2 - Monitoring Essentials" },
  { value: 3, label: "3 - Monitoring Plus (Essentials + Social + Print)" },
  { value: 4, label: "4 - Outreach + Monitoring Essentials" },
  { value: 5, label: "5 - CisionOne Full Comms Suite" },
];

export const organisationContentPackages = [
  {
    value: "UK-C1-MON-STAN",
    label: "Global Online News Monitoring - English only",
  },
  {
    value: "UK-C1-MON-UKPRINT-50CLIPS",
    label: "Print Monitoring UK Print Clips 50 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-100CLIPS",
    label: "Print Monitoring UK Print Clips 100 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-250CLIPS",
    label: "Print Monitoring UK Print Clips 250 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-500CLIPS",
    label: "Print Monitoring UK Print Clips 500 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-750CLIPS",
    label: "Print Monitoring UK Print Clips 750 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-1000CLIPS",
    label: "Print Monitoring UK Print Clips 1000 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-1500CLIPS",
    label: "Print Monitoring UK Print Clips 1500 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-2000CLIPS",
    label: "Print Monitoring UK Print Clips 2000 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-3000CLIPS",
    label: "Print Monitoring UK Print Clips 3000 per month",
  },
  {
    value: "UK-C1-MON-UKPRINT-4000CLIPS",
    label: "Print Monitoring UK Print Clips 4000 per month",
  },
  {
    value: "UK-C1-MON-PREMIUM",
    label: "Global Premium Monitoring",
  },
  {
    value: "UK-C1-MON-GLBPODCAST",
    label: "Podcast Monitoring",
  },
  {
    value: "UK-C1-MON-USCAN-BROADCAST",
    label: "Global Broadcast - US + Can",
  },
  {
    value: "UK-C1-MON-AUSNZ-BROADCAST",
    label: "Global Broadcast - Aus + Nz",
  },
  {
    value: "UK-C1-MON-UKBROADCAST",
    label: "UK Broadcast Monitoring",
  },
  {
    value: "UK-C1-MON-EMEABROADCAST",
    label: "EMEA Broadcast Monitoring",
  },
  {
    value: "UK-C1-MON-GLOB-BROADCAST",
    label: "Global Broadcast (ROW)",
  },
];

export const regions = [
  { label: "AU", value: "au" },
  { label: "NZ", value: "nz" },
  { label: "UK", value: "uk" },
  { label: "US", value: "us" },
  { label: "CA", value: "ca" },
  { label: "DACH", value: "dach" },
  { label: "NORDIC", value: "nordic" },
  { label: "APAC", value: "apac" },
  { label: "FR", value: "fr" },
];

export const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "en-AU",
    label: "English (Australia)",
  },
  {
    value: "en-CA",
    label: "English (Canada)",
  },
  {
    value: "en-GB",
    label: "English (United Kingdom)",
  },
  {
    value: "en-US",
    label: "English (United States)",
  },
  {
    value: "fr",
    label: "Français",
  },
  {
    value: "fr-CA",
    label: "Français (Canada)",
  },
  {
    value: "de",
    label: "Deutsch",
  },
];

export const CISION_SOURCE_FEATURES = [
  "has_international_max",
  "has_lexisnexis_print",
  "has_lexisnexis_online",
  "has_lexisnexis_academic",
  "has_wise_nla",
  "has_wise_public",
  "has_french_wise_public",
  "has_wise_cla",
  "has_wise_bonnier",
  "has_nla_eclips_web",
  "has_financial_times_online",
  "has_mediatrack_nla_regional",
  "has_uk_clipped_content_nla",
  "has_uk_clipped_content_cla",
  "has_uk_clipped_content_other",
  "has_english_language_international_nla",
  "has_english_language_international_cla",
  "has_english_language_international_other",
  "has_nla_eclips_print",
  "has_nla_eclips_print_financial_times",
  "has_tveyes_broadcast_anz",
  "has_tveyes_broadcast_us_canada",
  "has_tveyes_broadcast_uk",
  "has_tveyes_broadcast_europe",
  "has_tveyes_broadcast_rest_of_world",
  "has_new_york_times_content",
  "has_dow_jones_content",
  "has_globe_and_mail_content",
  "has_factiva_content",
  "has_mediatrack_apac",
  "has_mediatrack_international",
];

export const HIGH_REACT_SCORE_THRESHOLD = 75;

export const NEWS_SUMMARY_TYPES = [
  {
    value: "australia_front_pages",
    label: "Australia - Front Pages",
    region: "au",
  },
  {
    value: "australia_back_pages",
    label: "Australia - Back Pages",
    region: "au",
  },
  {
    value: "new_zealand_front_pages",
    label: "New Zealand - Front Pages",
    region: "nz",
  },
  {
    value: "new_zealand_back_pages",
    label: "New Zealand - Back Pages",
    region: "nz",
  },
];
