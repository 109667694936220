import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ScheduledReportSpecification from "./ScheduledReportSpecification";

@Model()
class ScheduledReport extends ApplicationRecord {
  static jsonapiType = "scheduled_reports";

  @BelongsTo()
  scheduledReportSpecification: ScheduledReportSpecification;

  @Attr() htmlUrl: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default ScheduledReport;
