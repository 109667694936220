import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import User from "./User";

@Model()
class UserRequest extends ApplicationRecord {
  static jsonapiType = "user_requests";

  @BelongsTo() requester: User;

  @BelongsTo() reviewer: User;

  @BelongsTo() user: User;

  @Attr() action: string;

  @Attr({ persist: false }) name: string;

  @Attr() firstName: string;

  @Attr() lastName: string;

  @Attr() email: string;

  @Attr() phoneNumber: string;

  @Attr() notes: string;

  @Attr() team: string;

  @Attr() approved: boolean;

  @Attr() errorMessages: string;

  @Attr({ persist: false }) matchingUsers: string[];

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) reviewedAt: string;

  @Attr() organisationTeamIds: number[];
}

export default UserRequest;
