import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Influencer from "./Influencer";
import Outlet from "./Outlet";

@Model()
class InfluencerOutlet extends ApplicationRecord {
  static jsonapiType = "influencers_outlets";

  @BelongsTo() influencer: Influencer;

  @BelongsTo() outlet: Outlet;

  @Attr() influencerId: string;

  @Attr() outletId: string;

  @Attr() archivedAt: string;
}

export default InfluencerOutlet;
