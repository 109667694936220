import type {
  InstantInsightsChart,
  SocialAnalyseChart,
} from "shared/resources/types";

export enum Region {
  AU = "au",
  CA = "ca",
  FR = "fr",
  US = "us",
}

export interface CisionSpecificConfig {
  churnzero: {
    appKey: string;
    scriptUrl: string;
  };
}

export interface AppConfig {
  adminUserLogrocketAppId: string;
  baseUrl: string;
  churnzero: {
    appKey: string;
    scriptUrl: string;
  };
  contentDateCap: number;
  emails: {
    accountManagers: string;
    bd: string;
    customerSuccess: string;
    dedicatedSupport: string;
    help: string;
    streemSocial: string;
  };
  firebaseAppConfig: {
    apiKey: string;
    appId: string;
    authDomain: string;
    projectId: string;
  };
  geoChartANZOnly: boolean;
  googleAnalyticsServiceAccount: string;
  includePrintPdfClippingScheduledReports: boolean;
  includePrintPdfClippingStreamReports: boolean;
  insightsPlusTeaser?: {
    imageUrl: string;
    reportImageUrl: string;
    reportUrl: string;
    factSheetImageUrl: string;
    factSheetUrl: string;
  };
  instantInsightsCharts: Array<InstantInsightsChart>;
  intercomAppId: string;
  legalName: string;
  login: {
    findOutMoreLink: string;
    logo: string;
    video: string;
  };
  logo: {
    login: string;
    menu: {
      media: {
        path: string;
        secondHalf?: {
          path: string;
          style: string;
        };
      };
      outreach?: {
        path: string;
        secondHalf: {
          path: string;
          style: string;
        };
      };
      social: {
        firstHalf: {
          path: string;
        };
        secondHalf: {
          path: string;
          style: string;
        };
      };
      style: string;
    };
  };
  logrocketAppId: string;
  mediaReleaseSendingDomain: string;
  microsoftClientId: string;
  mobileTrapImage: string;
  mobileUrlScheme: string;
  organisationMaxDateRange: number;
  phone: {
    formatted: string;
    raw: string;
  };
  platformIcons: {
    narratives: string;
  };
  platformName: string;
  shareableLinksDomain: string;
  slackClientId: string;
  socialAnalyseCharts: Array<SocialAnalyseChart>;
  streamNotificationSpikeImages: {
    large: string;
    medium: string;
    small: string;
  };
  urls: {
    contentWarning: string;
    googleAnalyticsHelp: string;
    helpArticles?: string;
    learnMore: string;
    legalTerms: string;
    mentionRestriction: string;
    microsoftTeamsHelp: string;
    mobileApp: {
      android: string;
      ios: string;
    };
    onboardingCustomerSuccess: string;
    outreachHelpArticles: string;
    potentialAudience: string;
    sentimentRating: string;
    slackHelp: string;
    storageAttachmentsBase: string;
    streamNotifications: string;
    taggedEmailReport: string;
    viewPublicHolidays: string;
  };
}
