import { Attr, BelongsTo, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import SocialEngagePostIntegrationEngagement from "./SocialEngagePostIntegrationEngagement";

@Model()
class SocialEngagePostsIntegration extends ApplicationRecord {
  static jsonapiType = "social_engage_posts_integrations";

  @BelongsTo() organisation: Organisation;

  @HasOne() latestEngagement: SocialEngagePostIntegrationEngagement;

  @Attr() socialPlatform: string;

  @Attr() label: string;

  @Attr({ persist: false }) metadata: Record<string, any>;

  @Attr() token: string;

  @Attr() remoteId: string;

  @Attr() live: boolean;

  @Attr() postedAt: string;

  @Attr() deletedAt: string;

  @Attr() scheduledAt: string;

  @Attr() organisationId: number;

  @Attr() permalink: string;

  @Attr() organisationSocialIntegrationId: number;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) archived: boolean;

  @Attr({ persist: false }) updatedAt: string;

  platformIcon(whiteIcon = false) {
    if (this.socialPlatform === "TwitterSocialIntegration") {
      return `https://assets.streem.com.au/icons/twitter.svg`;
    }

    const format = whiteIcon ? "15x15" : "";

    switch (this.socialPlatform) {
      case "FacebookSocialIntegration":
        return `https://assets.streem.com.au/icons/facebook${format}.svg`;
      case "InstagramSocialIntegration":
        return `https://assets.streem.com.au/icons/instagram${format}.svg`;
      case "LinkedinSocialIntegration":
        return `https://assets.streem.com.au/icons/linkedin${format}.svg`;
      default:
        return "";
    }
  }
}

export default SocialEngagePostsIntegration;
