// eslint-disable-next-line import/prefer-default-export
export const CONTACT_INNER_HITS_LIMIT = 20;
export const CONTACT_LIST_LIMIT = 5000;
export const CONTACT_EXPORT_LIMIT = 5000;
export const MAX_PAGINATION_LIMIT = 10000;

export const MEDIA_RELEASE_TYPE = "media_release";
export const PITCH_TYPE = "pitch";
export const MEDIA_RELEASE_TYPES = [MEDIA_RELEASE_TYPE, PITCH_TYPE];

export const MINIMUM_PITCH_PROMPT_WORDS = 14;
