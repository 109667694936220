import { Attr, HasMany, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import InsightsReport from "./InsightsReport";
import Organisation from "./Organisation";

@Model()
class InsightsReportGroup extends ApplicationRecord {
  static jsonapiType = "insights_report_groups";

  @HasMany() insightsReports: InsightsReport[];

  @HasOne() organisation: Organisation;

  @Attr() label: string;

  @Attr() organisationId: number;

  @Attr() reportType: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr() archivedAt: string;
}

export default InsightsReportGroup;
