import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Category from "./Category";
import Tweet from "./Tweet";

@Model()
class TwitterUser extends ApplicationRecord {
  static jsonapiType = "twitter_users";

  @BelongsTo() category: Category;

  @HasMany() tweets: Tweet[];

  @Attr() avatarImage: string;

  @Attr() categoryId: number;

  @Attr() city: string;

  @Attr() country: string;

  @Attr() followerCount: number;

  @Attr() gender: string;

  @Attr() influence: number;

  @Attr() label: string;

  @Attr() latitude: number;

  @Attr() longitude: number;

  @Attr() name: string;

  @Attr() outreach: number;

  @Attr() profileDescription: string;

  @Attr() state: string;

  @Attr() username: string;

  @Attr() usernameHash: string;

  @Attr() verified: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default TwitterUser;
