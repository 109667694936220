export enum DialogType {
  CONFIRM = "confirm",
  ALERT = "alert",
  PROMPT = "prompt",
}

export enum DialogTheme {
  DEFAULT = "default",
  DANGER = "danger",
  SOCIAL = "social",
}
