import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class StreamFilter extends ApplicationRecord {
  static jsonapiType = "stream_filters";

  @Attr() streamId: number;

  @Attr() targetId: number;

  @Attr() targetType: string;

  @Attr({ persist: false }) targetName: string;

  @Attr() scope: string;

  @Attr() action: string;
}

export default StreamFilter;
