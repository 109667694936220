export default [
  {
    name: "brief-group-add",
    path: "/organisations/:organisationId/brief_groups/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/brief_groups/BriefGroupEditPage.vue"),
    meta: {
      title: "Create Brief Group",
    },
  },
  {
    name: "brief-group-edit",
    path: "/organisations/:organisationId/brief_groups/:briefGroupId/edit",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/brief_groups/BriefGroupEditPage.vue"),
    meta: {
      title: "Edit Brief Group",
    },
  },
];
