import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Contact from "./Contact";
import OutreachCommunication from "./OutreachCommunication";

@Model()
class OutreachCommunicationContact extends ApplicationRecord {
  static jsonapiType = "outreach_communication_contacts";

  @BelongsTo() contact: Contact;

  @BelongsTo() outreachCommunication: OutreachCommunication;

  @Attr() contactId: number;

  @Attr() contactType: string;

  @Attr() outreachCommunicationId: number;
}

export default OutreachCommunicationContact;
