import type { App } from "vue";

/* eslint-disable import/no-mutable-exports */

let appInstance: App;

// so we can get the Vue app instance as quasar does not expose this except for boot files :(
export default ({ app }: { app: App }) => {
  appInstance = app;
};

export { appInstance };
