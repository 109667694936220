import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import SocialEngagePost from "./SocialEngagePost";

@Model()
class SocialEngagePostPerformance extends ApplicationRecord {
  static jsonapiType = "social_engage_post_performances";

  @BelongsTo() socialEngagePost: SocialEngagePost;

  @Attr() socialPlatform: string;

  @Attr() label: string;

  @Attr() token: string;

  @Attr() remoteId: string;

  @Attr() live: boolean;

  @Attr() deletedAt: string;

  @Attr() permalink: string;

  @Attr() likes: number;

  @Attr() comments: number;

  @Attr() clicks: number;

  @Attr() shares: number;

  @Attr() reach: number;

  @Attr() impressions: number;

  @Attr() performance: number;

  @Attr() organisationSocialIntegrationId: number;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) archived: boolean;

  @Attr({ persist: false }) updatedAt: string;

  platformIcon(whiteIcon = false) {
    if (this.platform() === "Twitter")
      return `https://assets.streem.com.au/icons/twitter.svg`;

    const format = whiteIcon ? "15x15" : "";
    const iconName = this.platform().toLowerCase();

    return `https://assets.streem.com.au/icons/${iconName}${format}.svg`;
  }

  platform() {
    switch (this.socialPlatform) {
      case "FacebookSocialIntegration":
        return "Facebook";
      case "TwitterSocialIntegration":
        return "Twitter";
      case "InstagramSocialIntegration":
        return "Instagram";
      case "LinkedinSocialIntegration":
        return "LinkedIn";
      default:
        return "";
    }
  }
}

export default SocialEngagePostPerformance;
