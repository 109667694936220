import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import PrivateInfluencer from "./PrivateInfluencer";

interface Address {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  state: string;
  county: string;
  city: string;
  postalCode: string;
  country: string;
}

@Model()
class PrivateInfluencerAddress extends ApplicationRecord {
  static jsonapiType = "private_influencer_addresses";

  @BelongsTo() privateInfluencer: PrivateInfluencer;

  @Attr() address: Address;

  @Attr() defaultFlag: boolean;
}

export default PrivateInfluencerAddress;
