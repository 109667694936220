export default [
  {
    name: "add-user",
    path: "/organisations/:organisationId/users/new",
    props: true,
    component: () => import("pages/organisations/users/AddUserPage.vue"),
    meta: {
      title: "Add User",
    },
  },
  {
    name: "xero-authorisation",
    path: "/xero/authorisation",
    component: () => import("pages/XeroAuthorisation.vue"),
    meta: {
      title: "Connect to Xero",
    },
  },
];
