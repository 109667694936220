import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContactList from "./ContactList";
import ContactListsContact from "./ContactListsContact";
import Organisation from "./Organisation";
import PrivateInfluencerAddress from "./PrivateInfluencerAddress";
import type { Email, Phone, Social } from "./types";

@Model()
class PrivateInfluencer extends ApplicationRecord {
  static jsonapiType = "private_influencers";

  @BelongsTo() organisation: Organisation;

  @HasMany() contactListsContacts: ContactListsContact[];

  @HasMany() contactLists: ContactList[];

  @HasMany({ name: "privateInfluencerAddresses" })
  privateInfluencerAddresses: PrivateInfluencerAddress[];

  @Attr() salutation: string;

  @Attr() firstName: string;

  @Attr() lastName: string;

  @Attr() name: string;

  @Attr() email: string;

  @Attr({ persist: false }) emails: Email[];

  @Attr() phone: string;

  @Attr({ persist: false }) phones: Phone[];

  @Attr({ persist: false }) socials: Social[];

  @Attr() jobTitle: string;

  @Attr() linkedin: string;

  @Attr() twitter: string;

  @Attr() language: string;

  @Attr() organisationId: number;

  @Attr({ persist: false }) documentId: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() note: string;
}

export default PrivateInfluencer;
