import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import BriefSet from "./BriefSet";
import Organisation from "./Organisation";
import OrganisationBrand from "./OrganisationBrand";
import User from "./User";

@Model()
class OrganisationTeam extends ApplicationRecord {
  static jsonapiType = "organisation_teams";

  @BelongsTo() brandProfile: OrganisationBrand;

  @BelongsTo() organisation: Organisation;

  @HasMany() users: User[];

  @HasMany() secondaryUsers: User[];

  @HasMany() primaryUsers: User[];

  @HasMany() operationalTeamUsers: User[];

  @HasMany() briefSets: BriefSet[];

  @Attr() name: string;

  @Attr() secondaryUserIds: number[];

  @Attr() roleId: number;

  @Attr() userIds: number[];

  @Attr() brandProfileId: number;

  @Attr() briefSetIds: number[];

  @Attr() operationalTeam: boolean;

  @Attr({ persist: false }) logoUrl: string;

  @Attr() logoData: string;

  @Attr() primaryStreamId: number;

  @Attr() organisationId: number;

  @Attr() usersCount: number;

  @Attr() secondaryUsersCount: number;

  @Attr() color: string;

  @Attr() operationalTeamUserIds: number[];
}

export default OrganisationTeam;
