import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

export const useBookmarkStreamsStore = defineStore("bookmarkStreams", {
  actions,
  getters,
  state,
});

export default useBookmarkStreamsStore;
