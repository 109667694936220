import { Attr, HasMany, HasOne, Model } from "spraypaint";

import SourceRecord from "shared/resources/SourceRecord";

import Category from "./Category";
import Outlet from "./Outlet";
import SourceGroup from "./SourceGroup";
import TvChannelsBroadcastRecorder from "./TvChannelsBroadcastRecorder";

@Model()
class TvChannel extends SourceRecord {
  static jsonapiType = "tv_channels";

  @HasOne() location: Location;

  @HasOne() sourceGroup: SourceGroup;

  @HasOne() category: Category;

  @HasOne() outlet: Outlet;

  @HasMany()
  tvChannelsBroadcastRecorders: TvChannelsBroadcastRecorder[];

  @Attr() name: string;

  @Attr({ persist: false }) medium: string;

  @Attr() locationId: number;

  @Attr() timeZone: string;

  @Attr() logoUrl: string;

  @Attr() logoData: string;

  @Attr() categoryId: number;

  @Attr() tier: number;

  @Attr() audienceVendorKey: string;

  @Attr() audienceVendorChannel: string;

  @Attr() fromVastSatellite: boolean;

  @Attr() syndication: string;

  @Attr() epgRegionId: number;

  @Attr() epgChannelId: number;

  @Attr() languageCode: string;

  @Attr() url: string;

  @Attr() sourceGroupId: number;

  @Attr() transcriptionEngine: string;

  @Attr() transcribePrograms: boolean;

  @Attr() visible: boolean;

  @Attr() watermarkUrl: string;

  @Attr() watermarkPreset: string;

  @Attr() fullName: string;

  @Attr() label: string;

  @Attr() notes: string;

  @Attr() audienceDataSupplier: string;

  @Attr() oztamCity: string;

  @Attr() oztamChannel: string;

  @Attr() aliases: string[];

  @Attr() notifyOutageViaPagerduty: boolean;

  @Attr() notifyOutageAfterMinutes: number;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default TvChannel;
