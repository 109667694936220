import { BelongsTo, Model } from "spraypaint";

import ProgramAiring from "shared/resources/ProgramAiring";

import RadioStation from "./RadioStation";

@Model()
class RadioProgramAiring extends ProgramAiring {
  static jsonapiType = "radio_program_airings";

  @BelongsTo() radioStation: RadioStation;
}

export default RadioProgramAiring;
