export default [
  {
    name: "advanced-search",
    path: "/advanced_search",
    props: (route) => ({
      query: route.query.query,
    }),
    component: () => import("pages/advanced_search/AdvancedSearchView.vue"),
    meta: {
      title: "Advanced Search",
    },
  },
  {
    name: "search-article",
    path: "/search",
    props: (route) => ({
      query: route.query.q,
    }),
    component: () => import("pages/SearchArticle.vue"),
    meta: {
      title: "Search Articles",
    },
  },
];
