import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class AccountManager extends ApplicationRecord {
  static jsonapiType = "account_managers";

  @Attr() name: string;
}

export default AccountManager;
