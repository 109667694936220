import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    path: "/journalists",
    component: () => import("pages/journalists/JournalistsPage.vue"),
    children: [
      {
        name: "journalists-new",
        path: "new",
        component: () => import("pages/journalists/JournalistEdit.vue"),
        meta: {
          title: "New Journalist",
        },
      },
      {
        name: "journalists-edit",
        path: ":id",
        props: true,
        component: () => import("pages/journalists/JournalistEdit.vue"),
        meta: {
          title: "Edit Journalist",
        },
      },
      {
        name: "journalist-mailing-lists-index",
        path: "/journalist-mailing-lists",
        component: () =>
          import("pages/journalists/JournalistMailingListList.vue"),
        props: (route) => typecastRouteProps(route),
        meta: {
          title: "Journalist Mailing Lists",
        },
      },
      {
        name: "journalist-mailing-list-new",
        path: "/journalist-mailing-lists/new",
        component: () =>
          import("pages/journalists/JournalistMailingListEdit.vue"),
        meta: {
          title: "New Journalist Mailing List",
        },
      },
      {
        name: "journalist-mailing-list-edit",
        path: "/journalist-mailing-lists/:id",
        props: true,
        component: () =>
          import("pages/journalists/JournalistMailingListEdit.vue"),
        meta: {
          title: "Edit Journalist Mailing List",
        },
      },
      {
        name: "contact-mailing-lists-index",
        path: "/contact-mailing-lists",
        component: () => import("pages/journalists/ContactMailingLists.vue"),
        props: (route) => typecastRouteProps(route),
        meta: {
          title: "Contact Mailing Lists",
        },
      },
      {
        name: "contact-mailing-list-new",
        path: "/contact-mailing-lists/new",
        component: () => import("pages/journalists/ContactMailingListEdit.vue"),
        meta: {
          title: "New Contact Mailing List",
        },
      },
      {
        name: "contact-mailing-list-edit",
        path: "/contact-mailing-lists/:id",
        props: true,
        component: () => import("pages/journalists/ContactMailingListEdit.vue"),
        meta: {
          title: "Edit Contact Mailing List",
        },
      },
      {
        name: "journalists-index",
        path: "",
        props: (route) => typecastRouteProps(route),
        component: () => import("pages/journalists/JournalistList.vue"),
        meta: {
          title: "Journalists",
        },
      },
    ],
  },
];
