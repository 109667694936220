import { defineStore } from "pinia";

import actions from "./actions";
import state from "./state";

export const useMediaRequestsStore = defineStore("mediaRequests", {
  state,
  actions,
});

export default useMediaRequestsStore;
