import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Journalist from "./Journalist";

@Model()
class JournalistEnquiryRecipient extends ApplicationRecord {
  static jsonapiType = "journalist_enquiry_recipients";

  @BelongsTo() journalist: Journalist;

  @Attr() journalistId: number;

  @Attr() journalistName: string;

  @Attr() journalistEnquiryId: number;
}

export default JournalistEnquiryRecipient;
