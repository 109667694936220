import { ComponentPublicInstance, createApp, markRaw } from "vue";

import { appInstance } from "shared/boot/app";
import { getI18n } from "shared/boot/i18n";
import ModalsContainer from "shared/components/core/modals/ModalsContainer.vue";
import type { Nullable } from "shared/types";

export interface ModalServiceOptions {
  props?: Record<string, any>;
  events?: Record<string, Function>;
}

export interface ModalContainerOptions<T> extends ModalServiceOptions {
  name: string;
  component: T;
}

type ModalsContainerInstance = InstanceType<typeof ModalsContainer>;

interface RegisteredModals<T> {
  [key: string]: T;
}

const registeredModals: RegisteredModals<ComponentPublicInstance> = {};
let modalsContainer: Nullable<ModalsContainerInstance> = null;

function setupModalsContainer() {
  if (modalsContainer) return;

  const modalsApp = createApp(ModalsContainer);
  modalsApp.use(getI18n());

  // Need to copy the context from the main app to keep installed plugins, components, config etc.
  // Isn't much of an option here because quasar hides our app instance creation from us
  // so can't use a factory pattern to reuse options when creating new app instances

  /* eslint-disable no-underscore-dangle */
  Object.assign(modalsApp._context, appInstance._context, {
    app: modalsApp._context.app,
  });
  /* eslint-enable no-underscore-dangle */

  const div = document.createElement("div");
  document.body.appendChild(div);
  modalsContainer = modalsApp.mount(div) as ModalsContainerInstance;
}

export const ModalService = {
  registerModals<T>(modals: RegisteredModals<T>) {
    Object.assign(registeredModals, modals);
  },
  hasModalOpen(): boolean {
    if (modalsContainer) {
      return modalsContainer.modals.length > 0;
    }

    return false;
  },
  open(component: string, options: ModalServiceOptions = {}) {
    setupModalsContainer();

    const modal = registeredModals[component];

    const modalOptions: ModalContainerOptions<typeof modal> = {
      name: component,
      component: markRaw(modal),
      props: options.props,
      events: {
        ...options.events,
        close: () => {
          if (options.events?.close instanceof Function) options.events.close();
          modalsContainer!.closeModal(component);
        },
      },
    };

    modalsContainer!.openModal(modalOptions);
  },
  close(modalName: string) {
    modalsContainer?.closeModal(modalName);
  },
  closeAll() {
    modalsContainer?.closeAllModals();
  },
};

export default ModalService;
