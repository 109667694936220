import { Model } from "spraypaint";

import Stream from "shared/resources/Stream";

@Model({ endpoint: "/coverage" })
class MentionStreamSearch extends Stream {
  static jsonapiType = "streams";
}

export default MentionStreamSearch;
