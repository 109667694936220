export default [
  {
    name: "organisation-report-customer-feedback",
    path: "/organisation-report-customer-feedback",
    props: (route) => ({
      view: "customer-feedback",
      ...route.params,
      ...route.query,
    }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "der",
      title: "Organisation Report - Customer Feedback",
    },
  },
  {
    name: "organisation-report-curator-exclusions",
    path: "/organisation-report-curator-exclusions",
    props: (route) => ({
      view: "curator-exclusions",
      ...route.params,
      ...route.query,
    }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "der",
      title: "Organisation Report - Curator Exclusions",
    },
  },
];
