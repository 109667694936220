import { POPOUT_PLAYER_TARGET } from "shared/constants";

export default () => ({
  playerOpen: false,
  addedClip: null,
  universalPlayerRef: null,
  playerClip: {},
  playerInitialClip: null,
  playerList: [],
  playerIsPlaying: false,
  playerPopoutRef: null,
  playerPopoutTarget: POPOUT_PLAYER_TARGET,
  popoutVideoRef: null,
  activeControl: null,
  playerIsDismissed: false,
  playerIsExpanded: false,
});
