export default [
  {
    name: "organisation-report-roster",
    path: "/organisation-report-roster",
    props: (route) => ({ view: "roster", ...route.params, ...route.query }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "der",
      title: "Organisation Report - Roster",
    },
  },
];
