import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class OrganisationSocialIntegration extends ApplicationRecord {
  static jsonapiType = "organisation_social_integrations";

  static notArchived() {
    return this.where({ archivedAt: { eq: "null" } });
  }

  @BelongsTo() organisation: Organisation;

  @Attr() socialPlatform: string;

  @Attr() label: string;

  @Attr() metadata: Record<string, any>;

  @Attr() token: string;

  @Attr() remoteId: string;

  @Attr() organisationId: number;

  @Attr() organisationTeamIds: number[];

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) archived: boolean;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() expiresAt: string;

  @Attr({ persist: false }) hasScheduledPosts: boolean;

  @Attr({ persist: false }) hasDraftPosts: boolean;

  @Attr() isOutreachAccount: boolean;

  platformIcon(whiteIcon = false) {
    const assetsUrl = String(import.meta.env.VITE_ASSETS_URL);

    if (this.socialPlatform === "TwitterSocialIntegration")
      return `${assetsUrl}/icons/twitter.svg`;

    const format = whiteIcon ? "15x15" : "";

    switch (this.socialPlatform) {
      case "FacebookSocialIntegration":
        return `${assetsUrl}/icons/facebook${format}.svg`;
      case "InstagramSocialIntegration":
        return `${assetsUrl}/icons/instagram${format}.svg`;
      case "LinkedinSocialIntegration":
        return `${assetsUrl}/icons/linkedin${format}.svg`;
      default:
        return "";
    }
  }
}

export default OrganisationSocialIntegration;
