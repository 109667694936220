import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import CustomerProfile from "./CustomerProfile";

@Model()
class CustomerProfileComponent extends ApplicationRecord {
  static jsonapiType = "customer_profile_components";

  @BelongsTo() CustomerProfile: CustomerProfile;

  @Attr() type: string;

  @Attr() customerProfileId: number;

  @Attr() label: string;

  @Attr() context: string;

  @Attr() subContext: string;

  @Attr() booleanQuery: string;

  @Attr() socialHandles: Record<string, any>;
}

export default CustomerProfileComponent;
