import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Stream from "./Stream";
import User from "./User";

@Model()
class StreamNotification extends ApplicationRecord {
  static jsonapiType = "stream_notifications";

  @BelongsTo() stream: Stream;

  @BelongsTo() user: User;

  @Attr() userId: number;

  @Attr() streamId: number;

  @Attr() type: string;

  @Attr() enabled: boolean;

  @Attr() threshold: number;

  @Attr() email: boolean;

  @Attr() mobile: boolean;

  @Attr() slack: boolean;

  @Attr() microsoftTeams: boolean;

  @Attr() riskScore: number;

  @Attr() printContent: boolean;

  @Attr() magazineContent: boolean;

  @Attr() onlineContent: boolean;

  @Attr() tvContent: boolean;

  @Attr() radioContent: boolean;

  @Attr() socialContent: boolean;

  @Attr() podcastContent: boolean;

  @Attr() selectedSocialContent: string[];

  @Attr() slackRecipients: string;

  @Attr() slackChannelName: string;

  @Attr() microsoftTeamsRecipients: string;

  @Attr() microsoftTeamsChannelName: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default StreamNotification;
