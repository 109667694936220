export default [
  {
    name: "twitter-capture-rules",
    path: "/social/twitter-capture-rules",
    component: () => import("pages/social/GnipRuleList.vue"),
    meta: {
      title: "Twitter Capture Rules",
    },
  },
  {
    name: "twitter-capture-rule-new",
    path: "/social/twitter-capture-rules/new",
    component: () => import("pages/social/GnipRuleEdit.vue"),
    meta: {
      title: "New Twitter Capture Rule",
    },
  },
  {
    name: "twitter-capture-rule-edit",
    path: "/social/twitter-capture-rules/:gnipRuleId/edit",
    props: true,
    component: () => import("pages/social/GnipRuleEdit.vue"),
    meta: {
      title: "Edit Twitter Capture Rule",
    },
  },
  {
    name: "socialgist-capture-rules",
    path: "/social/socialgist-capture-rules",
    component: () => import("pages/social/SocialgistRuleList.vue"),
    meta: {
      title: "Socialgist Capture Rules",
    },
  },
  {
    name: "socialgist-capture-rule-new",
    path: "/social/socialgist-capture-rules/new",
    component: () => import("pages/social/SocialgistRuleEdit.vue"),
    meta: {
      title: "New Socialgist Capture Rule",
    },
  },
  {
    name: "socialgist-capture-rule-edit",
    path: "/social/socialgist-capture-rules/:socialgistRuleId/edit",
    props: true,
    component: () => import("pages/social/SocialgistRuleEdit.vue"),
    meta: {
      title: "Edit Socialgist Capture Rule",
    },
  },
  {
    name: "search-tweets",
    path: "/social/search-tweets",
    component: () => import("pages/social/SearchTweetsPage.vue"),
    meta: {
      title: "Search Tweets by URL",
    },
  },
  {
    name: "tweets",
    path: "/social/tweets",
    component: () => import("pages/social/TweetsPage.vue"),
    meta: {
      title: "Tweets",
    },
  },
  {
    name: "facebook-pages",
    path: "/social/facebook-pages",
    component: () => import("pages/social/FacebookPagesPage.vue"),
    meta: {
      title: "Facebook Pages",
    },
  },
  {
    name: "facebook-pages-new",
    path: "/social/facebook-pages/new",
    component: () => import("pages/social/AddEditFacebookPagesPage.vue"),
    meta: {
      title: "New Facebook Page",
    },
  },
  {
    name: "facebook-pages-edit",
    path: "/social/facebook-pages/:id",
    component: () => import("pages/social/AddEditFacebookPagesPage.vue"),
    props: true,
    meta: {
      title: "Edit Facebook Page",
    },
  },
  {
    name: "facebook-posts",
    path: "/social/facebook-posts",
    component: () => import("pages/FacebookPostList.vue"),
    meta: {
      title: "Facebook Posts",
    },
  },
  {
    name: "facebook-posts-new",
    path: "/social/facebook-posts/new",
    component: () => import("pages/FacebookPostEdit.vue"),
    props: true,
    meta: {
      title: "New Facebook Post",
    },
  },
  {
    name: "facebook-posts-edit",
    path: "/social/facebook-posts/:id",
    component: () => import("pages/FacebookPostEdit.vue"),
    props: true,
    meta: {
      title: "Edit Facebook Post",
    },
  },
  {
    name: "instagram-handles",
    path: "/social/instagram-handles",
    component: () => import("pages/social/InstagramHandlesPage.vue"),
    meta: {
      title: "Instagram Handles",
    },
  },
  {
    name: "instagram-handles-new",
    path: "/social/instagram-handles/new",
    component: () => import("pages/social/InstagramHandleEdit.vue"),
    meta: {
      title: "New Instagram Handle",
    },
  },
  {
    name: "instagram-handles-edit",
    path: "/social/instagram-handles/:id",
    component: () => import("pages/social/InstagramHandleEdit.vue"),
    props: true,
    meta: {
      title: "Edit Instagram Handle",
    },
  },
  {
    name: "instagram-posts",
    path: "/social/instagram-posts",
    component: () => import("pages/social/InstagramPostsPage.vue"),
    meta: {
      title: "Instagram Posts",
    },
  },
  {
    name: "instagram-posts-new",
    path: "/social/instagram-posts/new",
    component: () => import("pages/social/InstagramPostEdit.vue"),
    meta: {
      title: "New Instagram Post",
    },
  },
  {
    name: "instagram-posts-edit",
    path: "/social/instagram-posts/:id",
    component: () => import("pages/social/InstagramPostEdit.vue"),
    props: true,
    meta: {
      title: "Edit Instagram Post",
    },
  },
  {
    name: "youtube-channels",
    path: "/social/youtube-channels",
    component: () => import("pages/social/YoutubeChannelList.vue"),
    meta: {
      title: "Youtube Channels",
    },
  },
  {
    name: "youtube-channels-new",
    path: "/social/youtube-channels/new",
    component: () => import("pages/social/YoutubeChannelEdit.vue"),
    meta: {
      title: "New Youtube Channel",
    },
  },
  {
    name: "youtube-channels-edit",
    path: "/social/youtube-channels/:id",
    component: () => import("pages/social/YoutubeChannelEdit.vue"),
    props: true,
    meta: {
      title: "Edit Youtube Channel",
    },
  },
  {
    name: "reddit-subreddits",
    path: "/social/reddit-subreddits",
    component: () => import("pages/social/RedditSubredditsPage.vue"),
    meta: {
      title: "Reddit Subreddits",
    },
  },
  {
    name: "reddit-subreddits-new",
    path: "/social/reddit-subreddits/new",
    component: () => import("pages/social/RedditSubredditEdit.vue"),
    meta: {
      title: "New Reddit Subreddit",
    },
  },
  {
    name: "reddit-subreddits-edit",
    path: "/social/reddit-subreddits/:id",
    component: () => import("pages/social/RedditSubredditEdit.vue"),
    props: true,
    meta: {
      title: "Edit Reddit Subreddit",
    },
  },
  {
    name: "blogs",
    path: "/social/blogs",
    component: () => import("pages/social/BlogsPage.vue"),
    meta: {
      title: "Blogs",
    },
  },
  {
    name: "blogs-new",
    path: "/social/blogs/new",
    component: () => import("pages/social/BlogEdit.vue"),
    meta: {
      title: "New Blog",
    },
  },
  {
    name: "blogs-edit",
    path: "/social/blogs/:id",
    component: () => import("pages/social/BlogEdit.vue"),
    props: true,
    meta: {
      title: "Edit Blog",
    },
  },
  {
    name: "blog-posts",
    path: "/social/blog-posts",
    component: () => import("pages/social/BlogPostsPage.vue"),
    meta: {
      title: "Blog Posts",
    },
  },
  {
    name: "blog-posts-new",
    path: "/social/blog-posts/new",
    component: () => import("pages/social/BlogPostEdit.vue"),
    meta: {
      title: "New Blog Post",
    },
  },
  {
    name: "blog-posts-edit",
    path: "/social/blog-post/:id",
    component: () => import("pages/social/BlogPostEdit.vue"),
    props: true,
    meta: {
      title: "Edit Blog Post",
    },
  },
  {
    name: "forums",
    path: "/social/forums",
    component: () => import("pages/social/ForumsPage.vue"),
    meta: {
      title: "Forums",
    },
  },
  {
    name: "forums-new",
    path: "/social/forums/new",
    component: () => import("pages/social/ForumEdit.vue"),
    meta: {
      title: "New Forum",
    },
  },
  {
    name: "forums-edit",
    path: "/social/forums/:id",
    component: () => import("pages/social/ForumEdit.vue"),
    props: true,
    meta: {
      title: "Edit Forum",
    },
  },
];
