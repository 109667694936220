import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "front-pages",
    path: "/front-pages",
    props: (route) => typecastRouteProps(route),
    component: () => import("pages/FrontPageList.vue"),
    meta: {
      title: "Front Pages",
    },
  },
  {
    name: "front-pages-add",
    path: "/front-pages/new",
    component: () => import("pages/FrontPageEdit.vue"),
    meta: {
      title: "New Front Page",
    },
  },
  {
    name: "front-pages-edit",
    path: "/front-pages/:id/edit",
    component: () => import("pages/FrontPageEdit.vue"),
    props: true,
    meta: {
      title: "Edit Front Page",
    },
  },
];
