export default [
  {
    name: "organisation-report-scheduler",
    path: "/organisation-report-scheduler",
    props: (route) => ({ view: "scheduler", ...route.params, ...route.query }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "der",
      title: "Organisation Reports",
    },
  },
];
