export default [
  {
    name: "streem-company",
    path: "/streem-company",
    component: () => import("pages/StreemCompany.vue"),
    meta: {
      title: "Streem Company",
    },
  },
];
