import { Attr, Model } from "spraypaint";

import {
  ApplicationRecord,
  objectDirtyChecker,
} from "shared/services/spraypaint";

@Model()
class LaunchpadSettings extends ApplicationRecord {
  static jsonapiType = "launchpad_settings";

  @Attr() userId: number;

  @Attr() organisationTeamId: number;

  @Attr({ dirtyChecker: objectDirtyChecker }) chartSettings: Record<
    string,
    any
  >;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default LaunchpadSettings;
