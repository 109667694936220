import { Attr, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContentVersion from "./ContentVersion";
import Journalist from "./Journalist";
import JournalistMailingListsJournalist from "./JournalistMailingListsJournalist";
import OutreachCampaignRecipient from "./OutreachCampaignRecipient";

@Model()
class JournalistMailingList extends ApplicationRecord {
  static jsonapiType = "journalist_mailing_lists";

  @HasMany() journalists: Journalist[];

  @HasMany()
  journalistMailingListsJournalists: JournalistMailingListsJournalist[];

  @HasMany() versions: ContentVersion[];

  @HasMany() outreachCampaignRecipients: OutreachCampaignRecipient[];

  @Attr() name: string;

  @Attr() publishedAt: string;

  @Attr() recipientsCount: number;
}

export default JournalistMailingList;
