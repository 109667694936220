import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import Organisation from "./Organisation";
import OrganisationBrand from "./OrganisationBrand";
import OrganisationReportSpecDateRange from "./OrganisationReportSpecDateRange";
import OrganisationReportSpecGroup from "./OrganisationReportSpecGroup";
import OrganisationReportSpecProduct from "./OrganisationReportSpecProduct";
import User from "./User";

@Model()
class OrganisationReportSpec extends ApplicationRecord {
  static jsonapiType = "organisation_report_specs";

  @HasMany() attachments: ActiveStorageAttachment[];

  @HasMany() dateRanges: OrganisationReportSpecDateRange[];

  @HasMany()
  organisationReportSpecProducts: OrganisationReportSpecProduct[];

  @BelongsTo() organisation: Organisation;

  @BelongsTo() userAccount: User;

  @BelongsTo() group: OrganisationReportSpecGroup;

  @BelongsTo() organisationBrand: OrganisationBrand;

  @Attr() autoDraftEnabled: boolean;

  @Attr() autoDraftRules: any[];

  @Attr() contactEmails: any[];

  @Attr({ persist: false }) contentRange: string;

  @Attr() customSender: string;

  @Attr() deliverAt: number;

  @Attr() deliveryTime: string;

  @Attr() disclaimer: string;

  @Attr() distributionListId: number;

  @Attr() emptyFolderMessage: string;

  @Attr() exclusionsNotes: string;

  @Attr() folderIds: number[];

  @Attr() fontSize: string;

  @Attr() footer: string;

  @Attr() formatNotes: string;

  @Attr() fridayRange: number;

  @Attr() groupId: number;

  @Attr() hideReport: boolean;

  @Attr() iconUrl: string;

  @Attr() includeCompletePdf: boolean;

  @Attr() includeCsv: boolean;

  @Attr() includeDisclaimer: boolean;

  @Attr() includeFolderPrintClipsPdf: boolean;

  @Attr() includePdf: boolean;

  @Attr() includePrintClipsPdf: boolean;

  @Attr() includePrintClipsPdfWithAfrClippings: boolean;

  @Attr() inclusionsNotes: string;

  @Attr() mentionsDisplay: string;

  @Attr() message: string;

  @Attr() mondayRange: number;

  @Attr() onFriday: boolean;

  @Attr() onMonday: boolean;

  @Attr() onSaturday: boolean;

  @Attr() onSunday: boolean;

  @Attr() onThursday: boolean;

  @Attr() onTuesday: boolean;

  @Attr() onWednesday: boolean;

  @Attr() order: string;

  @Attr() organisationBrandId: number;

  @Attr() organisationId: number;

  @Attr() overviewNotes: string;

  @Attr() pdfReportTemplateName: string;

  @Attr() position: number;

  @Attr() prefillEmptyFolders: boolean;

  @Attr() recipients: any[];

  @Attr() requiresProofing: boolean;

  @Attr() saturdayRange: number;

  @Attr() scheduleOnPublicHoliday: boolean;

  @Attr() sendAsOrganisation: boolean;

  @Attr() showExcerpts: boolean;

  @Attr() showKeywordCount: boolean;

  @Attr() showRestrictedContent: boolean;

  @Attr() showRssLink: boolean;

  @Attr() sourcesNotes: string;

  @Attr() status: string;

  @Attr() subject: string;

  @Attr() summaryLinkBriefIds: number[];

  @Attr() summaryType: string;

  @Attr() sundayRange: number;

  @Attr() task: string;

  @Attr() thursdayRange: number;

  @Attr() timeZone: string;

  @Attr() title: string;

  @Attr() tuesdayRange: number;

  @Attr() useDistributionList: boolean;

  @Attr() userAccountId: number;

  @Attr() viewerNotes: string;

  @Attr() wednesdayRange: number;

  @Attr() weekdayAutoDraftAt: number;

  @Attr() weekendAutoDraftAt: number;

  @Attr() weekendDeliverAt: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default OrganisationReportSpec;
