import { getTimezone } from "shared/helpers/date";
import StorageService from "shared/services/StorageService";

let locale;

function defaultLocale() {
  return locale;
}

const VALID_LOCALES = {
  Australia: "en-AU",
  Europe: "en-GB",
  America: "en-US",
};

export default async () => {
  locale =
    import.meta.env.VITE_LOCALE ||
    (await StorageService.get("locale")) ||
    VALID_LOCALES[getTimezone().split("/")[0]] ||
    "en-AU";
};

export { defaultLocale };
