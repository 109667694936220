import load from "load-script";

const loadedUrls = {};

export default function loadScript(url) {
  if (loadedUrls[url]) {
    return loadedUrls[url];
  }

  loadedUrls[url] = new Promise((resolve, reject) => {
    load(url, (err, script) => {
      if (err) {
        reject(err);
      } else {
        resolve(script);
      }
    });
  });

  return loadedUrls[url];
}
