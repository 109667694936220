enum Type {
  fiat = "fiat",
  crypto = "crypto",
  metal = "metal",
}

export interface Currency {
  symbol: string;
  name: string;
  symbolNative: string;
  decimalDigits: number;
  rounding: number;
  code: string;
  iconName?: string;
  namePlural: string;
  type: Type;
  countries: string[];
  locale?: string;
}

export const currencies: Currency[] = [
  {
    symbol: "AED",
    name: "United Arab Emirates Dirham",
    symbolNative: "د.إ",
    decimalDigits: 2,
    rounding: 0,
    code: "AED",
    namePlural: "UAE dirhams",
    type: Type.fiat,
    countries: ["AE"],
    locale: "ar-AE",
  },
  {
    symbol: "Af",
    name: "Afghan Afghani",
    symbolNative: "؋",
    decimalDigits: 0,
    rounding: 0,
    code: "AFN",
    namePlural: "Afghan Afghanis",
    type: Type.fiat,
    countries: ["AF"],
    locale: "ps-AF",
  },
  {
    symbol: "ALL",
    name: "Albanian Lek",
    symbolNative: "Lek",
    decimalDigits: 0,
    rounding: 0,
    code: "ALL",
    namePlural: "Albanian lekë",
    type: Type.fiat,
    countries: ["AL"],
    locale: "sq-AL",
  },
  {
    symbol: "AMD",
    name: "Armenian Dram",
    symbolNative: "դր.",
    decimalDigits: 0,
    rounding: 0,
    code: "AMD",
    namePlural: "Armenian drams",
    type: Type.fiat,
    countries: ["AM"],
    locale: "hy-AM",
  },
  {
    symbol: "ƒ",
    name: "NL Antillean Guilder",
    symbolNative: "NAƒ",
    decimalDigits: 2,
    rounding: 0,
    code: "ANG",
    iconName: "ang",
    namePlural: "NL Antillean Guilders",
    type: Type.fiat,
    countries: ["CW", "SX"],
    locale: "nl",
  },
  {
    symbol: "Kz",
    name: "Angolan Kwanza",
    symbolNative: "Kz",
    decimalDigits: 2,
    rounding: 0,
    code: "AOA",
    iconName: "aoa",
    namePlural: "Angolan Kwanza",
    type: Type.fiat,
    countries: ["AO"],
    locale: "pt",
  },
  {
    symbol: "AR$",
    name: "Argentine Peso",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "ARS",
    namePlural: "Argentine pesos",
    type: Type.fiat,
    countries: ["AR"],
    locale: "es-AR",
  },
  {
    symbol: "AU$",
    name: "Australian Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "AUD",
    namePlural: "Australian dollars",
    type: Type.fiat,
    countries: ["AU", "CC", "CX", "HM", "KI", "NF", "NR", "TV"],
    locale: "en-AU",
  },
  {
    symbol: "Afl.",
    name: "Aruban Florin",
    symbolNative: "Afl.",
    decimalDigits: 2,
    rounding: 0,
    code: "AWG",
    iconName: "awg",
    namePlural: "Aruban Florin",
    type: Type.fiat,
    countries: ["AW"],
    locale: "nl",
  },
  {
    symbol: "man.",
    name: "Azerbaijani Manat",
    symbolNative: "ман.",
    decimalDigits: 2,
    rounding: 0,
    code: "AZN",
    namePlural: "Azerbaijani manats",
    type: Type.fiat,
    countries: ["AZ"],
    locale: "az-Cyrl-AZ",
  },
  {
    symbol: "KM",
    name: "Bosnia-Herzegovina Convertible Mark",
    symbolNative: "KM",
    decimalDigits: 2,
    rounding: 0,
    code: "BAM",
    namePlural: "Bosnia-Herzegovina convertible marks",
    type: Type.fiat,
    countries: ["BA"],
    locale: "bs-BA",
  },
  {
    symbol: "Bds$",
    name: "Barbadian Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "BBD",
    iconName: "bbd",
    namePlural: "Barbadian Dollars",
    type: Type.fiat,
    countries: ["BB"],
    locale: "en",
  },
  {
    symbol: "Tk",
    name: "Bangladeshi Taka",
    symbolNative: "৳",
    decimalDigits: 2,
    rounding: 0,
    code: "BDT",
    namePlural: "Bangladeshi takas",
    type: Type.fiat,
    countries: ["BD"],
    locale: "bn-BD",
  },
  {
    symbol: "BGN",
    name: "Bulgarian Lev",
    symbolNative: "лв.",
    decimalDigits: 2,
    rounding: 0,
    code: "BGN",
    namePlural: "Bulgarian leva",
    type: Type.fiat,
    countries: ["BG"],
    locale: "bg-BG",
  },
  {
    symbol: "BD",
    name: "Bahraini Dinar",
    symbolNative: "د.ب.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "BHD",
    namePlural: "Bahraini dinars",
    type: Type.fiat,
    countries: ["BH"],
    locale: "ar-BH",
  },
  {
    symbol: "FBu",
    name: "Burundian Franc",
    symbolNative: "FBu",
    decimalDigits: 0,
    rounding: 0,
    code: "BIF",
    namePlural: "Burundian francs",
    type: Type.fiat,
    countries: ["BI"],
    locale: "fr-BI",
  },
  {
    symbol: "BD$",
    name: "Bermudan Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "BMD",
    iconName: "bmd",
    namePlural: "Bermudan Dollars",
    type: Type.fiat,
    countries: ["BM"],
    locale: "en",
  },
  {
    symbol: "BN$",
    name: "Brunei Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "BND",
    namePlural: "Brunei dollars",
    type: Type.fiat,
    countries: ["BN"],
    locale: "ms-BN",
  },
  {
    symbol: "Bs",
    name: "Bolivian Boliviano",
    symbolNative: "Bs",
    decimalDigits: 2,
    rounding: 0,
    code: "BOB",
    namePlural: "Bolivian bolivianos",
    type: Type.fiat,
    countries: ["BO"],
    locale: "es-BO",
  },
  {
    symbol: "R$",
    name: "Brazilian Real",
    symbolNative: "R$",
    decimalDigits: 2,
    rounding: 0,
    code: "BRL",
    namePlural: "Brazilian reals",
    type: Type.fiat,
    countries: ["BR"],
    locale: "pt-BR",
  },
  {
    symbol: "B$",
    name: "Bahamian Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "BSD",
    iconName: "bsd",
    namePlural: "Bahamian Dollars",
    type: Type.fiat,
    countries: ["BS"],
    locale: "en",
  },
  {
    symbol: "Nu.",
    name: "Bhutanese Ngultrum",
    symbolNative: "Nu.",
    decimalDigits: 2,
    rounding: 0,
    code: "BTN",
    iconName: "btn",
    namePlural: "Bhutanese Ngultrum",
    type: Type.fiat,
    countries: ["BT"],
    locale: "dz",
  },
  {
    symbol: "BWP",
    name: "Botswanan Pula",
    symbolNative: "P",
    decimalDigits: 2,
    rounding: 0,
    code: "BWP",
    namePlural: "Botswanan pulas",
    type: Type.fiat,
    countries: ["BW", "ZW"],
    locale: "en-BW",
  },
  {
    symbol: "Br",
    name: "Belarusian ruble",
    symbolNative: "Br",
    decimalDigits: 2,
    rounding: 0,
    code: "BYN",
    namePlural: "Belarusian rubles",
    type: Type.fiat,
    countries: [],
    locale: "be-BY",
  },
  {
    symbol: "BYR",
    name: "Belarusian Ruble",
    symbolNative: "BYR",
    decimalDigits: 0,
    rounding: 0,
    code: "BYR",
    namePlural: "Belarusian rubles",
    type: Type.fiat,
    countries: ["BY"],
  },
  {
    symbol: "BZ$",
    name: "Belize Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "BZD",
    namePlural: "Belize dollars",
    type: Type.fiat,
    countries: ["BZ"],
    locale: "en-BZ",
  },
  {
    symbol: "CA$",
    name: "Canadian Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "CAD",
    namePlural: "Canadian dollars",
    type: Type.fiat,
    countries: ["CA"],
    locale: "en-CA",
  },
  {
    symbol: "CDF",
    name: "Congolese Franc",
    symbolNative: "FrCD",
    decimalDigits: 2,
    rounding: 0,
    code: "CDF",
    namePlural: "Congolese francs",
    type: Type.fiat,
    countries: ["CD"],
    locale: "fr-CD",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
    symbolNative: "CHF",
    decimalDigits: 2,
    rounding: 0,
    code: "CHF",
    namePlural: "Swiss francs",
    type: Type.fiat,
    countries: ["CH", "LI"],
    locale: "de-LI",
  },
  {
    symbol: "UF",
    name: "Unidad de Fomento",
    symbolNative: "UF",
    decimalDigits: 2,
    rounding: 0,
    code: "CLF",
    namePlural: "Unidad de Fomentos",
    type: Type.fiat,
    countries: ["CL"],
  },
  {
    symbol: "CL$",
    name: "Chilean Peso",
    symbolNative: "$",
    decimalDigits: 0,
    rounding: 0,
    code: "CLP",
    namePlural: "Chilean pesos",
    type: Type.fiat,
    countries: ["CL"],
    locale: "es-CL",
  },
  {
    symbol: "CN¥",
    name: "Chinese Yuan",
    symbolNative: "CN¥",
    decimalDigits: 2,
    rounding: 0,
    code: "CNY",
    namePlural: "Chinese yuan",
    type: Type.fiat,
    countries: ["CN"],
    locale: "zh-CN",
  },
  {
    symbol: "CO$",
    name: "Colombian Peso",
    symbolNative: "$",
    decimalDigits: 0,
    rounding: 0,
    code: "COP",
    namePlural: "Colombian pesos",
    type: Type.fiat,
    countries: ["CO"],
    locale: "es-CO",
  },
  {
    symbol: "₡",
    name: "Costa Rican Colón",
    symbolNative: "₡",
    decimalDigits: 0,
    rounding: 0,
    code: "CRC",
    namePlural: "Costa Rican colóns",
    type: Type.fiat,
    countries: ["CR"],
    locale: "es-CR",
  },
  {
    symbol: "CUC$",
    name: "Cuban Convertible Peso",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "CUC",
    iconName: "cuc",
    namePlural: "Cuban Convertible Peso",
    type: Type.fiat,
    countries: ["CU"],
    locale: "es",
  },
  {
    symbol: "$MN",
    name: "Cuban Peso",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "CUP",
    iconName: "cup",
    namePlural: "Cuban Peso",
    type: Type.fiat,
    countries: ["CU"],
  },
  {
    symbol: "CV$",
    name: "Cape Verdean Escudo",
    symbolNative: "CV$",
    decimalDigits: 2,
    rounding: 0,
    code: "CVE",
    namePlural: "Cape Verdean escudos",
    type: Type.fiat,
    countries: ["CV"],
    locale: "kea-CV",
  },
  {
    symbol: "Kč",
    name: "Czech Republic Koruna",
    symbolNative: "Kč",
    decimalDigits: 2,
    rounding: 0,
    code: "CZK",
    namePlural: "Czech Republic korunas",
    type: Type.fiat,
    countries: ["CZ"],
    locale: "cs-CZ",
  },
  {
    symbol: "Fdj",
    name: "Djiboutian Franc",
    symbolNative: "Fdj",
    decimalDigits: 0,
    rounding: 0,
    code: "DJF",
    namePlural: "Djiboutian francs",
    type: Type.fiat,
    countries: ["DJ"],
    locale: "fr-DJ",
  },
  {
    symbol: "Dkr",
    name: "Danish Krone",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "DKK",
    namePlural: "Danish kroner",
    type: Type.fiat,
    countries: ["DK", "FO", "GL"],
    locale: "da-DK",
  },
  {
    symbol: "RD$",
    name: "Dominican Peso",
    symbolNative: "RD$",
    decimalDigits: 2,
    rounding: 0,
    code: "DOP",
    namePlural: "Dominican pesos",
    type: Type.fiat,
    countries: ["DO"],
    locale: "es-DO",
  },
  {
    symbol: "DA",
    name: "Algerian Dinar",
    symbolNative: "د.ج.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "DZD",
    namePlural: "Algerian dinars",
    type: Type.fiat,
    countries: ["DZ"],
    locale: "ar-DZ",
  },
  {
    symbol: "EGP",
    name: "Egyptian Pound",
    symbolNative: "ج.م.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "EGP",
    namePlural: "Egyptian pounds",
    type: Type.fiat,
    countries: ["EG", "PS"],
    locale: "ar-EG",
  },
  {
    symbol: "Nfk",
    name: "Eritrean Nakfa",
    symbolNative: "Nfk",
    decimalDigits: 2,
    rounding: 0,
    code: "ERN",
    namePlural: "Eritrean nakfas",
    type: Type.fiat,
    countries: ["ER"],
    locale: "ti-ER",
  },
  {
    symbol: "Br",
    name: "Ethiopian Birr",
    symbolNative: "Br",
    decimalDigits: 2,
    rounding: 0,
    code: "ETB",
    namePlural: "Ethiopian birrs",
    type: Type.fiat,
    countries: ["ET"],
    locale: "am-ET",
  },
  {
    symbol: "€",
    name: "Euro",
    symbolNative: "€",
    decimalDigits: 2,
    rounding: 0,
    code: "EUR",
    namePlural: "Euros",
    type: Type.fiat,
    countries: [
      "AD",
      "AT",
      "AX",
      "BE",
      "BL",
      "CP",
      "CY",
      "DE",
      "EA",
      "EE",
      "ES",
      "EU",
      "FI",
      "FR",
      "FX",
      "GF",
      "GP",
      "GR",
      "IC",
      "IE",
      "IT",
      "LT",
      "LU",
      "LV",
      "MC",
      "ME",
      "MF",
      "MQ",
      "MT",
      "NL",
      "PM",
      "PT",
      "RE",
      "SI",
      "SK",
      "SM",
      "TF",
      "VA",
      "XK",
      "YT",
      "ZW",
    ],
    locale: "ca",
  },
  {
    symbol: "FJ$",
    name: "Fijian Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "FJD",
    iconName: "fjd",
    namePlural: "Fijian Dollar",
    type: Type.fiat,
    countries: ["FJ"],
    locale: "en",
  },
  {
    symbol: "FK£",
    name: "Falkland Islands Pound",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "FKP",
    iconName: "fkp",
    namePlural: "Falkland Islands Pound",
    type: Type.fiat,
    countries: ["FK"],
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "GBP",
    namePlural: "British pounds sterling",
    type: Type.fiat,
    countries: ["GB", "GG", "GS", "IM", "JE", "TA", "UK", "ZW"],
    locale: "en",
  },
  {
    symbol: "GEL",
    name: "Georgian Lari",
    symbolNative: "GEL",
    decimalDigits: 2,
    rounding: 0,
    code: "GEL",
    namePlural: "Georgian laris",
    type: Type.fiat,
    countries: ["GE"],
    locale: "ka-GE",
  },
  {
    symbol: "£",
    name: "Guernsey pound",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "GGP",
    namePlural: "Guernsey pounds",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "GH₵",
    name: "Ghanaian Cedi",
    symbolNative: "GH₵",
    decimalDigits: 2,
    rounding: 0,
    code: "GHS",
    namePlural: "Ghanaian cedis",
    type: Type.fiat,
    countries: ["GH"],
    locale: "ak-GH",
  },
  {
    symbol: "£",
    name: "Gibraltar Pound",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "GIP",
    iconName: "gip",
    namePlural: "Gibraltar Pounds",
    type: Type.fiat,
    countries: ["GI"],
    locale: "en",
  },
  {
    symbol: "D",
    name: "Gambian Dalasi",
    symbolNative: "D",
    decimalDigits: 2,
    rounding: 0,
    code: "GMD",
    iconName: "gmd",
    namePlural: "Gambian Dalasi",
    type: Type.fiat,
    countries: ["GM"],
    locale: "en",
  },
  {
    symbol: "FG",
    name: "Guinean Franc",
    symbolNative: "FG",
    decimalDigits: 0,
    rounding: 0,
    code: "GNF",
    namePlural: "Guinean francs",
    type: Type.fiat,
    countries: ["GN"],
    locale: "fr-GN",
  },
  {
    symbol: "GTQ",
    name: "Guatemalan Quetzal",
    symbolNative: "Q",
    decimalDigits: 2,
    rounding: 0,
    code: "GTQ",
    namePlural: "Guatemalan quetzals",
    type: Type.fiat,
    countries: ["GT"],
    locale: "es-GT",
  },
  {
    symbol: "G$",
    name: "Guyanaese Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "GYD",
    iconName: "gyd",
    namePlural: "Guyanaese Dollar",
    type: Type.fiat,
    countries: ["GY"],
    locale: "en",
  },
  {
    symbol: "HK$",
    name: "Hong Kong Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "HKD",
    namePlural: "Hong Kong dollars",
    type: Type.fiat,
    countries: ["HK"],
    locale: "en-HK",
  },
  {
    symbol: "HNL",
    name: "Honduran Lempira",
    symbolNative: "L",
    decimalDigits: 2,
    rounding: 0,
    code: "HNL",
    namePlural: "Honduran lempiras",
    type: Type.fiat,
    countries: ["HN"],
    locale: "es-HN",
  },
  {
    symbol: "kn",
    name: "Croatian Kuna",
    symbolNative: "kn",
    decimalDigits: 2,
    rounding: 0,
    code: "HRK",
    namePlural: "Croatian kunas",
    type: Type.fiat,
    countries: ["HR"],
  },
  {
    symbol: "G",
    name: "Haitian Gourde",
    symbolNative: "G",
    decimalDigits: 2,
    rounding: 0,
    code: "HTG",
    iconName: "htg",
    namePlural: "Haitian Gourde",
    type: Type.fiat,
    countries: ["HT"],
  },
  {
    symbol: "Ft",
    name: "Hungarian Forint",
    symbolNative: "Ft",
    decimalDigits: 0,
    rounding: 0,
    code: "HUF",
    namePlural: "Hungarian forints",
    type: Type.fiat,
    countries: ["HU"],
    locale: "hu-HU",
  },
  {
    symbol: "Rp",
    name: "Indonesian Rupiah",
    symbolNative: "Rp",
    decimalDigits: 0,
    rounding: 0,
    code: "IDR",
    namePlural: "Indonesian rupiahs",
    type: Type.fiat,
    countries: ["ID"],
    locale: "id-ID",
  },
  {
    symbol: "₪",
    name: "Israeli New Sheqel",
    symbolNative: "₪",
    decimalDigits: 2,
    rounding: 0,
    code: "ILS",
    namePlural: "Israeli new sheqels",
    type: Type.fiat,
    countries: ["IL", "PS"],
    locale: "he-IL",
  },
  {
    symbol: "£",
    name: "Manx pound",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "IMP",
    namePlural: "Manx pounds",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "Rs",
    name: "Indian Rupee",
    symbolNative: "₹",
    decimalDigits: 2,
    rounding: 0,
    code: "INR",
    namePlural: "Indian rupees",
    type: Type.fiat,
    countries: ["BT", "IN"],
    locale: "hi-IN",
  },
  {
    symbol: "IQD",
    name: "Iraqi Dinar",
    symbolNative: "د.ع.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "IQD",
    namePlural: "Iraqi dinars",
    type: Type.fiat,
    countries: ["IQ"],
    locale: "ar-IQ",
  },
  {
    symbol: "IRR",
    name: "Iranian Rial",
    symbolNative: "﷼",
    decimalDigits: 0,
    rounding: 0,
    code: "IRR",
    namePlural: "Iranian rials",
    type: Type.fiat,
    countries: ["IR"],
  },
  {
    symbol: "Ikr",
    name: "Icelandic Króna",
    symbolNative: "kr",
    decimalDigits: 0,
    rounding: 0,
    code: "ISK",
    namePlural: "Icelandic krónur",
    type: Type.fiat,
    countries: ["IS"],
    locale: "is-IS",
  },
  {
    symbol: "£",
    name: "Jersey pound",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "JEP",
    namePlural: "Jersey pound",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "J$",
    name: "Jamaican Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "JMD",
    namePlural: "Jamaican dollars",
    type: Type.fiat,
    countries: ["JM"],
    locale: "en-JM",
  },
  {
    symbol: "JD",
    name: "Jordanian Dinar",
    symbolNative: "د.أ.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "JOD",
    namePlural: "Jordanian dinars",
    type: Type.fiat,
    countries: ["JO", "PS"],
    locale: "ar-JO",
  },
  {
    symbol: "¥",
    name: "Japanese Yen",
    symbolNative: "￥",
    decimalDigits: 0,
    rounding: 0,
    code: "JPY",
    namePlural: "Japanese yen",
    type: Type.fiat,
    countries: ["JP"],
    locale: "ja-JP",
  },
  {
    symbol: "Ksh",
    name: "Kenyan Shilling",
    symbolNative: "Ksh",
    decimalDigits: 2,
    rounding: 0,
    code: "KES",
    namePlural: "Kenyan shillings",
    type: Type.fiat,
    countries: ["KE"],
    locale: "ebu-KE",
  },
  {
    symbol: "KGS",
    name: "Kyrgystani Som",
    symbolNative: "KGS",
    decimalDigits: 2,
    rounding: 0,
    code: "KGS",
    iconName: "kgs",
    namePlural: "Kyrgystani Som",
    type: Type.fiat,
    countries: ["KG"],
    locale: "ky",
  },
  {
    symbol: "KHR",
    name: "Cambodian Riel",
    symbolNative: "៛",
    decimalDigits: 2,
    rounding: 0,
    code: "KHR",
    namePlural: "Cambodian riels",
    type: Type.fiat,
    countries: ["KH"],
    locale: "km-KH",
  },
  {
    symbol: "CF",
    name: "Comorian Franc",
    symbolNative: "FC",
    decimalDigits: 0,
    rounding: 0,
    code: "KMF",
    namePlural: "Comorian francs",
    type: Type.fiat,
    countries: ["KM"],
    locale: "fr-KM",
  },
  {
    symbol: "₩",
    name: "North Korean Won",
    symbolNative: "₩",
    decimalDigits: 2,
    rounding: 0,
    code: "KPW",
    iconName: "kpw",
    namePlural: "North Korean Won",
    type: Type.fiat,
    countries: ["KP"],
    locale: "ko",
  },
  {
    symbol: "₩",
    name: "South Korean Won",
    symbolNative: "₩",
    decimalDigits: 0,
    rounding: 0,
    code: "KRW",
    namePlural: "South Korean won",
    type: Type.fiat,
    countries: ["KR"],
    locale: "ko-KR",
  },
  {
    symbol: "KD",
    name: "Kuwaiti Dinar",
    symbolNative: "د.ك.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "KWD",
    namePlural: "Kuwaiti dinars",
    type: Type.fiat,
    countries: ["KW"],
    locale: "ar-KW",
  },
  {
    symbol: "CI$",
    name: "Cayman Islands Dollar",
    symbolNative: "$‏",
    decimalDigits: 2,
    rounding: 0,
    code: "KYD",
    iconName: "kyd",
    namePlural: "Cayman Islands Dollar",
    type: Type.fiat,
    countries: ["KY"],
    locale: "en",
  },
  {
    symbol: "KZT",
    name: "Kazakhstani Tenge",
    symbolNative: "тңг.",
    decimalDigits: 2,
    rounding: 0,
    code: "KZT",
    namePlural: "Kazakhstani tenges",
    type: Type.fiat,
    countries: ["KZ"],
    locale: "kk-Cyrl-KZ",
  },
  {
    symbol: "₭N",
    name: "Laotian Kip",
    symbolNative: "₭‏‏",
    decimalDigits: 0,
    rounding: 0,
    code: "LAK",
    namePlural: "Laotian Kip",
    type: Type.fiat,
    countries: ["LA"],
    locale: "lo",
  },
  {
    symbol: "LB£",
    name: "Lebanese Pound",
    symbolNative: "ل.ل.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "LBP",
    namePlural: "Lebanese pounds",
    type: Type.fiat,
    countries: ["LB"],
    locale: "ar-LB",
  },
  {
    symbol: "SLRs",
    name: "Sri Lankan Rupee",
    symbolNative: "SL Re",
    decimalDigits: 2,
    rounding: 0,
    code: "LKR",
    namePlural: "Sri Lankan rupees",
    type: Type.fiat,
    countries: ["LK"],
    locale: "si-LK",
  },
  {
    symbol: "LD$",
    name: "Liberian Dollar",
    symbolNative: "L$",
    decimalDigits: 2,
    rounding: 0,
    code: "LRD",
    iconName: "lrd",
    namePlural: "Liberian Dollar",
    type: Type.fiat,
    countries: ["LR"],
    locale: "en",
  },
  {
    symbol: "L",
    name: "Lesotho Loti",
    symbolNative: "M",
    decimalDigits: 2,
    rounding: 0,
    code: "LSL",
    iconName: "lsl",
    namePlural: "Lesotho Loti",
    type: Type.fiat,
    countries: ["LS"],
  },
  {
    symbol: "Lt",
    name: "Lithuanian Litas",
    symbolNative: "Lt",
    decimalDigits: 2,
    rounding: 0,
    code: "LTL",
    namePlural: "Lithuanian litai",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "Ls",
    name: "Latvian Lats",
    symbolNative: "Ls",
    decimalDigits: 2,
    rounding: 0,
    code: "LVL",
    namePlural: "Latvian lati",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "LD",
    name: "Libyan Dinar",
    symbolNative: "د.ل.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "LYD",
    namePlural: "Libyan dinars",
    type: Type.fiat,
    countries: ["LY"],
    locale: "ar-LY",
  },
  {
    symbol: "MAD",
    name: "Moroccan Dirham",
    symbolNative: "د.م.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "MAD",
    namePlural: "Moroccan dirhams",
    type: Type.fiat,
    countries: ["EH", "MA"],
    locale: "ar-MA",
  },
  {
    symbol: "MDL",
    name: "Moldovan Leu",
    symbolNative: "MDL",
    decimalDigits: 2,
    rounding: 0,
    code: "MDL",
    namePlural: "Moldovan lei",
    type: Type.fiat,
    countries: ["MD"],
    locale: "ro-MD",
  },
  {
    symbol: "MGA",
    name: "Malagasy Ariary",
    symbolNative: "MGA",
    decimalDigits: 0,
    rounding: 0,
    code: "MGA",
    namePlural: "Malagasy Ariaries",
    type: Type.fiat,
    countries: ["MG"],
    locale: "fr-MG",
  },
  {
    symbol: "MKD",
    name: "Macedonian Denar",
    symbolNative: "MKD",
    decimalDigits: 2,
    rounding: 0,
    code: "MKD",
    namePlural: "Macedonian denari",
    type: Type.fiat,
    countries: ["MK"],
    locale: "mk-MK",
  },
  {
    symbol: "MMK",
    name: "Myanma Kyat",
    symbolNative: "K",
    decimalDigits: 0,
    rounding: 0,
    code: "MMK",
    namePlural: "Myanma kyats",
    type: Type.fiat,
    countries: ["MM"],
    locale: "my-MM",
  },
  {
    symbol: "₮",
    name: "Mongolian Tugrik",
    symbolNative: "₮",
    decimalDigits: 2,
    rounding: 0,
    code: "MNT",
    iconName: "mnt",
    namePlural: "Mongolian Tugrik",
    type: Type.fiat,
    countries: ["MN"],
    locale: "mn",
  },
  {
    symbol: "MOP$",
    name: "Macanese Pataca",
    symbolNative: "MOP$",
    decimalDigits: 2,
    rounding: 0,
    code: "MOP",
    namePlural: "Macanese patacas",
    type: Type.fiat,
    countries: ["MO"],
    locale: "zh-Hans-MO",
  },
  {
    symbol: "UM",
    name: "Mauritanian ouguiya",
    symbolNative: "UM",
    decimalDigits: 2,
    rounding: 0,
    code: "MRO",
    namePlural: "Mauritanian ouguiyas",
    type: Type.fiat,
    countries: ["MR"],
  },
  {
    symbol: "MURs",
    name: "Mauritian Rupee",
    symbolNative: "MURs",
    decimalDigits: 0,
    rounding: 0,
    code: "MUR",
    namePlural: "Mauritian rupees",
    type: Type.fiat,
    countries: ["MU"],
    locale: "en-MU",
  },
  {
    symbol: "MRf",
    name: "Maldivian Rufiyaa",
    symbolNative: "Rf",
    decimalDigits: 2,
    rounding: 0,
    code: "MVR",
    namePlural: "Maldivian Rufiyaa",
    type: Type.fiat,
    countries: ["MV"],
    locale: "dv",
  },
  {
    symbol: "MK",
    name: "Malawian Kwacha",
    symbolNative: "MK",
    decimalDigits: 2,
    rounding: 0,
    code: "MWK",
    iconName: "mwk",
    namePlural: "Malawian Kwacha",
    type: Type.fiat,
    countries: ["MW"],
    locale: "en",
  },
  {
    symbol: "MX$",
    name: "Mexican Peso",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "MXN",
    namePlural: "Mexican pesos",
    type: Type.fiat,
    countries: ["MX"],
    locale: "es-MX",
  },
  {
    symbol: "RM",
    name: "Malaysian Ringgit",
    symbolNative: "RM",
    decimalDigits: 2,
    rounding: 0,
    code: "MYR",
    namePlural: "Malaysian ringgits",
    type: Type.fiat,
    countries: ["MY"],
    locale: "ms-MY",
  },
  {
    symbol: "MTn",
    name: "Mozambican Metical",
    symbolNative: "MTn",
    decimalDigits: 2,
    rounding: 0,
    code: "MZN",
    namePlural: "Mozambican meticals",
    type: Type.fiat,
    countries: ["MZ"],
    locale: "pt-MZ",
  },
  {
    symbol: "N$",
    name: "Namibian Dollar",
    symbolNative: "N$",
    decimalDigits: 2,
    rounding: 0,
    code: "NAD",
    namePlural: "Namibian dollars",
    type: Type.fiat,
    countries: ["NA"],
  },
  {
    symbol: "₦",
    name: "Nigerian Naira",
    symbolNative: "₦",
    decimalDigits: 2,
    rounding: 0,
    code: "NGN",
    namePlural: "Nigerian nairas",
    type: Type.fiat,
    countries: ["NG"],
    locale: "ha-Latn-NG",
  },
  {
    symbol: "C$",
    name: "Nicaraguan Córdoba",
    symbolNative: "C$",
    decimalDigits: 2,
    rounding: 0,
    code: "NIO",
    namePlural: "Nicaraguan córdobas",
    type: Type.fiat,
    countries: ["NI"],
    locale: "es-NI",
  },
  {
    symbol: "Nkr",
    name: "Norwegian Krone",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "NOK",
    namePlural: "Norwegian kroner",
    type: Type.fiat,
    countries: ["BV", "NO", "SJ"],
    locale: "no",
  },
  {
    symbol: "NPRs",
    name: "Nepalese Rupee",
    symbolNative: "नेरू",
    decimalDigits: 2,
    rounding: 0,
    code: "NPR",
    namePlural: "Nepalese rupees",
    type: Type.fiat,
    countries: ["NP"],
    locale: "ne-NP",
  },
  {
    symbol: "NZ$",
    name: "New Zealand Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "NZD",
    namePlural: "New Zealand dollars",
    type: Type.fiat,
    countries: ["CK", "NU", "NZ", "PN", "TK"],
    locale: "en",
  },
  {
    symbol: "OMR",
    name: "Omani Rial",
    symbolNative: "ر.ع.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "OMR",
    namePlural: "Omani rials",
    type: Type.fiat,
    countries: ["OM"],
    locale: "ar-OM",
  },
  {
    symbol: "B/.",
    name: "Panamanian Balboa",
    symbolNative: "B/.",
    decimalDigits: 2,
    rounding: 0,
    code: "PAB",
    namePlural: "Panamanian balboas",
    type: Type.fiat,
    countries: ["PA"],
  },
  {
    symbol: "S/.",
    name: "Peruvian Nuevo Sol",
    symbolNative: "S/.",
    decimalDigits: 2,
    rounding: 0,
    code: "PEN",
    namePlural: "Peruvian nuevos soles",
    type: Type.fiat,
    countries: ["PE"],
    locale: "es-PE",
  },
  {
    symbol: "K",
    name: "Papua New Guinean Kina",
    symbolNative: "K",
    decimalDigits: 2,
    rounding: 0,
    code: "PGK",
    iconName: "pgk",
    namePlural: "Papua New Guinean Kina",
    type: Type.fiat,
    countries: ["PG"],
    locale: "en",
  },
  {
    symbol: "₱",
    name: "Philippine Peso",
    symbolNative: "₱",
    decimalDigits: 2,
    rounding: 0,
    code: "PHP",
    namePlural: "Philippine pesos",
    type: Type.fiat,
    countries: ["PH"],
    locale: "en-PH",
  },
  {
    symbol: "PKRs",
    name: "Pakistani Rupee",
    symbolNative: "₨",
    decimalDigits: 0,
    rounding: 0,
    code: "PKR",
    namePlural: "Pakistani rupees",
    type: Type.fiat,
    countries: ["PK"],
    locale: "en-PK",
  },
  {
    symbol: "zł",
    name: "Polish Zloty",
    symbolNative: "zł",
    decimalDigits: 2,
    rounding: 0,
    code: "PLN",
    namePlural: "Polish zlotys",
    type: Type.fiat,
    countries: ["PL"],
    locale: "pl-PL",
  },
  {
    symbol: "₲",
    name: "Paraguayan Guarani",
    symbolNative: "₲",
    decimalDigits: 0,
    rounding: 0,
    code: "PYG",
    namePlural: "Paraguayan guaranis",
    type: Type.fiat,
    countries: ["PY"],
    locale: "es-PY",
  },
  {
    symbol: "QR",
    name: "Qatari Rial",
    symbolNative: "ر.ق.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "QAR",
    namePlural: "Qatari rials",
    type: Type.fiat,
    countries: ["QA"],
    locale: "ar-QA",
  },
  {
    symbol: "RON",
    name: "Romanian Leu",
    symbolNative: "RON",
    decimalDigits: 2,
    rounding: 0,
    code: "RON",
    namePlural: "Romanian lei",
    type: Type.fiat,
    countries: ["RO"],
    locale: "ro-RO",
  },
  {
    symbol: "din.",
    name: "Serbian Dinar",
    symbolNative: "дин.",
    decimalDigits: 0,
    rounding: 0,
    code: "RSD",
    namePlural: "Serbian dinars",
    type: Type.fiat,
    countries: ["RS"],
    locale: "sr-Cyrl-RS",
  },
  {
    symbol: "RUB",
    name: "Russian Ruble",
    symbolNative: "₽",
    decimalDigits: 2,
    rounding: 0,
    code: "RUB",
    namePlural: "Russian rubles",
    type: Type.fiat,
    countries: ["RU", "SU"],
    locale: "ru-RU",
  },
  {
    symbol: "RWF",
    name: "Rwandan Franc",
    symbolNative: "FR",
    decimalDigits: 0,
    rounding: 0,
    code: "RWF",
    namePlural: "Rwandan francs",
    type: Type.fiat,
    countries: ["RW"],
    locale: "fr-RW",
  },
  {
    symbol: "SR",
    name: "Saudi Riyal",
    symbolNative: "ر.س.‏",
    decimalDigits: 2,
    rounding: 0,
    code: "SAR",
    namePlural: "Saudi riyals",
    type: Type.fiat,
    countries: ["SA"],
    locale: "ar-SA",
  },
  {
    symbol: "SI$",
    name: "Solomon Islands Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "SBD",
    iconName: "sbd",
    namePlural: "Solomon Islands Dollars",
    type: Type.fiat,
    countries: ["SB"],
    locale: "en",
  },
  {
    symbol: "SRe",
    name: "Seychellois Rupee",
    symbolNative: "SR",
    decimalDigits: 2,
    rounding: 0,
    code: "SCR",
    iconName: "scr",
    namePlural: "Seychellois Rupees",
    type: Type.fiat,
    countries: ["SC"],
    locale: "fr",
  },
  {
    symbol: "SDG",
    name: "Sudanese Pound",
    symbolNative: "SDG",
    decimalDigits: 2,
    rounding: 0,
    code: "SDG",
    namePlural: "Sudanese pounds",
    type: Type.fiat,
    countries: ["SD"],
    locale: "ar-SD",
  },
  {
    symbol: "Skr",
    name: "Swedish Krona",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "SEK",
    namePlural: "Swedish kronor",
    type: Type.fiat,
    countries: ["SE"],
    locale: "sv-SE",
  },
  {
    symbol: "S$",
    name: "Singapore Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "SGD",
    namePlural: "Singapore dollars",
    type: Type.fiat,
    countries: ["SG"],
    locale: "en-SG",
  },
  {
    symbol: "£",
    name: "Saint Helena Pound",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "SHP",
    iconName: "shp",
    namePlural: "Saint Helena Pounds",
    type: Type.fiat,
    countries: ["SH"],
    locale: "en",
  },
  {
    symbol: "Le",
    name: "Sierra Leonean Leone",
    symbolNative: "Le",
    decimalDigits: 2,
    rounding: 0,
    code: "SLL",
    iconName: "sll",
    namePlural: "Sierra Leonean Leone",
    type: Type.fiat,
    countries: ["SL"],
    locale: "en",
  },
  {
    symbol: "Ssh",
    name: "Somali Shilling",
    symbolNative: "Ssh",
    decimalDigits: 0,
    rounding: 0,
    code: "SOS",
    namePlural: "Somali shillings",
    type: Type.fiat,
    countries: ["SO"],
    locale: "so-SO",
  },
  {
    symbol: "$",
    name: "Surinamese Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "SRD",
    iconName: "srd",
    namePlural: "Surinamese Dollar",
    type: Type.fiat,
    countries: ["SR"],
    locale: "nl",
  },
  {
    symbol: "Db",
    name: "São Tomé and Príncipe dobra",
    symbolNative: "Db",
    decimalDigits: 2,
    rounding: 0,
    code: "STD",
    namePlural: "São Tomé and Príncipe dobras",
    type: Type.fiat,
    countries: ["ST"],
  },
  {
    symbol: "₡",
    name: "Salvadoran Colón",
    symbolNative: "₡",
    decimalDigits: 2,
    rounding: 0,
    code: "SVC",
    iconName: "svc",
    namePlural: "Salvadoran Colón",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "SY£",
    name: "Syrian Pound",
    symbolNative: "ل.س.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "SYP",
    namePlural: "Syrian pounds",
    type: Type.fiat,
    countries: ["SY"],
    locale: "ar-SY",
  },
  {
    symbol: "L",
    name: "Swazi Lilangeni",
    symbolNative: "E‏",
    decimalDigits: 2,
    rounding: 0,
    code: "SZL",
    iconName: "szl",
    namePlural: "Swazi Lilangeni",
    type: Type.fiat,
    countries: ["SZ"],
  },
  {
    symbol: "฿",
    name: "Thai Baht",
    symbolNative: "฿",
    decimalDigits: 2,
    rounding: 0,
    code: "THB",
    namePlural: "Thai baht",
    type: Type.fiat,
    countries: ["TH"],
    locale: "th-TH",
  },
  {
    symbol: "TJS",
    name: "Tajikistani Somoni",
    symbolNative: "TJS",
    decimalDigits: 2,
    rounding: 0,
    code: "TJS",
    iconName: "tjs",
    namePlural: "Tajikistani Somoni",
    type: Type.fiat,
    countries: ["TJ"],
    locale: "tg",
  },
  {
    symbol: "T",
    name: "Turkmenistani Manat",
    symbolNative: "T‏",
    decimalDigits: 2,
    rounding: 0,
    code: "TMT",
    iconName: "tmt",
    namePlural: "Turkmenistani Manat",
    type: Type.fiat,
    countries: ["TM"],
    locale: "tk",
  },
  {
    symbol: "DT",
    name: "Tunisian Dinar",
    symbolNative: "د.ت.‏",
    decimalDigits: 3,
    rounding: 0,
    code: "TND",
    namePlural: "Tunisian dinars",
    type: Type.fiat,
    countries: ["TN"],
    locale: "ar-TN",
  },
  {
    symbol: "T$",
    name: "Tongan Paʻanga",
    symbolNative: "T$",
    decimalDigits: 2,
    rounding: 0,
    code: "TOP",
    namePlural: "Tongan paʻanga",
    type: Type.fiat,
    countries: ["TO"],
    locale: "to-TO",
  },
  {
    symbol: "TL",
    name: "Turkish Lira",
    symbolNative: "₺",
    decimalDigits: 2,
    rounding: 0,
    code: "TRY",
    namePlural: "Turkish Lira",
    type: Type.fiat,
    countries: ["TR"],
    locale: "tr-TR",
  },
  {
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "TTD",
    namePlural: "Trinidad and Tobago dollars",
    type: Type.fiat,
    countries: ["TT"],
    locale: "en-TT",
  },
  {
    symbol: "NT$",
    name: "New Taiwan Dollar",
    symbolNative: "NT$",
    decimalDigits: 2,
    rounding: 0,
    code: "TWD",
    namePlural: "New Taiwan dollars",
    type: Type.fiat,
    countries: ["TW"],
    locale: "zh-Hant-TW",
  },
  {
    symbol: "TSh",
    name: "Tanzanian Shilling",
    symbolNative: "TSh",
    decimalDigits: 0,
    rounding: 0,
    code: "TZS",
    namePlural: "Tanzanian shillings",
    type: Type.fiat,
    countries: ["TZ"],
    locale: "asa-TZ",
  },
  {
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    symbolNative: "₴",
    decimalDigits: 2,
    rounding: 0,
    code: "UAH",
    namePlural: "Ukrainian hryvnias",
    type: Type.fiat,
    countries: ["UA"],
    locale: "uk-UA",
  },
  {
    symbol: "USh",
    name: "Ugandan Shilling",
    symbolNative: "USh",
    decimalDigits: 0,
    rounding: 0,
    code: "UGX",
    namePlural: "Ugandan shillings",
    type: Type.fiat,
    countries: ["UG"],
    locale: "cgg-UG",
  },
  {
    symbol: "$",
    name: "US Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "USD",
    namePlural: "US dollars",
    type: Type.fiat,
    countries: [
      "AC",
      "AS",
      "BQ",
      "DG",
      "EC",
      "FM",
      "GU",
      "HT",
      "IO",
      "MH",
      "MP",
      "PA",
      "PR",
      "PW",
      "SV",
      "TC",
      "TL",
      "UM",
      "US",
      "VG",
      "VI",
      "ZW",
    ],
    locale: "en-US",
  },
  {
    symbol: "$U",
    name: "Uruguayan Peso",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "UYU",
    namePlural: "Uruguayan pesos",
    type: Type.fiat,
    countries: ["UY"],
    locale: "es-UY",
  },
  {
    symbol: "UZS",
    name: "Uzbekistan Som",
    symbolNative: "UZS",
    decimalDigits: 0,
    rounding: 0,
    code: "UZS",
    namePlural: "Uzbekistan som",
    type: Type.fiat,
    countries: ["UZ"],
    locale: "uz-Cyrl-UZ",
  },
  {
    symbol: "Bs.F.",
    name: "Venezuelan Bolívar",
    symbolNative: "Bs.F.",
    decimalDigits: 2,
    rounding: 0,
    code: "VEF",
    namePlural: "Venezuelan bolívars",
    type: Type.fiat,
    countries: ["VE"],
  },
  {
    symbol: "₫",
    name: "Vietnamese Dong",
    symbolNative: "₫",
    decimalDigits: 0,
    rounding: 0,
    code: "VND",
    namePlural: "Vietnamese dong",
    type: Type.fiat,
    countries: ["VN"],
    locale: "vi-VN",
  },
  {
    symbol: "VUV",
    name: "Vanuatu Vatu",
    symbolNative: "VT",
    decimalDigits: 0,
    rounding: 0,
    code: "VUV",
    iconName: "vuv",
    namePlural: "Vanuatu Vatu",
    type: Type.fiat,
    countries: ["VU"],
    locale: "bi",
  },
  {
    symbol: "WS$",
    name: "Samoan Tala",
    symbolNative: "T",
    decimalDigits: 2,
    rounding: 0,
    code: "WST",
    iconName: "wst",
    namePlural: "Samoan Tala",
    type: Type.fiat,
    countries: ["WS"],
    locale: "sm",
  },
  {
    symbol: "FCFA",
    name: "CFA Franc BEAC",
    symbolNative: "FCFA",
    decimalDigits: 0,
    rounding: 0,
    code: "XAF",
    namePlural: "CFA francs BEAC",
    type: Type.fiat,
    countries: ["CF", "CG", "CM", "GA", "GQ", "TD"],
    locale: "fr-CM",
  },
  {
    symbol: "XAG",
    name: "Silver Ounce",
    symbolNative: "XAG",
    decimalDigits: 2,
    rounding: 0,
    code: "XAG",
    namePlural: "Silver Ounces",
    type: Type.metal,
    countries: [],
  },
  {
    symbol: "XAU",
    name: "Gold Ounce",
    symbolNative: "XAU",
    decimalDigits: 2,
    rounding: 0,
    code: "XAU",
    namePlural: "Gold Ounces",
    type: Type.metal,
    countries: [],
  },
  {
    symbol: "EC$",
    name: "East Caribbean Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "XCD",
    iconName: "xcd",
    namePlural: "East Caribbean Dollars",
    type: Type.fiat,
    countries: ["AG", "AI", "DM", "GD", "KN", "LC", "MS", "VC"],
    locale: "en",
  },
  {
    symbol: "SDR",
    name: "Special drawing rights",
    symbolNative: "SDR",
    decimalDigits: 2,
    rounding: 0,
    code: "XDR",
    namePlural: "Special drawing rights",
    type: Type.fiat,
    countries: [],
    locale: "fa-IR",
  },
  {
    symbol: "CFA",
    name: "CFA Franc BCEAO",
    symbolNative: "CFA",
    decimalDigits: 0,
    rounding: 0,
    code: "XOF",
    namePlural: "CFA francs BCEAO",
    type: Type.fiat,
    countries: ["BF", "BJ", "CI", "GW", "ML", "NE", "SN", "TG"],
    locale: "fr-BJ",
  },
  {
    symbol: "CFP",
    name: "CFP Franc",
    symbolNative: "CFP",
    decimalDigits: 0,
    rounding: 0,
    code: "XPF",
    iconName: "xpf",
    namePlural: "CFP francs",
    type: Type.fiat,
    countries: ["NC", "PF", "WF"],
    locale: "fr",
  },
  {
    symbol: "YR",
    name: "Yemeni Rial",
    symbolNative: "ر.ي.‏",
    decimalDigits: 0,
    rounding: 0,
    code: "YER",
    namePlural: "Yemeni rials",
    type: Type.fiat,
    countries: ["YE"],
    locale: "ar-YE",
  },
  {
    symbol: "R",
    name: "South African Rand",
    symbolNative: "R",
    decimalDigits: 2,
    rounding: 0,
    code: "ZAR",
    namePlural: "South African rand",
    type: Type.fiat,
    countries: ["LS", "NA", "ZA", "ZW"],
    locale: "en",
  },
  {
    symbol: "ZK",
    name: "Zambian Kwacha",
    symbolNative: "ZK",
    decimalDigits: 0,
    rounding: 0,
    code: "ZMK",
    namePlural: "Zambian kwachas",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "ZK",
    name: "Zambian Kwacha",
    symbolNative: "ZK",
    decimalDigits: 0,
    rounding: 0,
    code: "ZMW",
    namePlural: "Zambian kwachas",
    type: Type.fiat,
    countries: ["ZM"],
    locale: "bem-ZM",
  },
  {
    symbol: "ZWL",
    name: "Zimbabwean dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "ZWL",
    namePlural: "Zimbabwean dollars",
    type: Type.fiat,
    countries: [],
    locale: "en-ZW",
  },
  {
    symbol: "XPT",
    name: "Platinum Ounce",
    symbolNative: "XPT",
    decimalDigits: 6,
    rounding: 0,
    code: "XPT",
    namePlural: "Platinum Ounces",
    type: Type.metal,
    countries: [],
  },
  {
    symbol: "XPD",
    name: "Palladium Ounce",
    symbolNative: "XPD",
    decimalDigits: 6,
    rounding: 0,
    code: "XPD",
    namePlural: "Palladium Ounces",
    type: Type.metal,
    countries: [],
  },
  {
    symbol: "₿",
    name: "Bitcoin",
    symbolNative: "₿",
    decimalDigits: 8,
    rounding: 0,
    code: "BTC",
    namePlural: "Bitcoins",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "Ξ",
    name: "Ethereum",
    symbolNative: "Ξ",
    decimalDigits: 18,
    rounding: 0,
    code: "ETH",
    namePlural: "Ethereum",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "BNB",
    name: "Binance",
    symbolNative: "BNB",
    decimalDigits: 8,
    rounding: 0,
    code: "BNB",
    namePlural: "Binance",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "XRP",
    name: "Ripple",
    symbolNative: "XRP",
    decimalDigits: 6,
    rounding: 0,
    code: "XRP",
    namePlural: "Ripple",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "SOL",
    name: "Solana",
    symbolNative: "SOL",
    decimalDigits: 9,
    rounding: 0,
    code: "SOL",
    namePlural: "Solana",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "DOT",
    name: "Polkadot",
    symbolNative: "DOT",
    decimalDigits: 10,
    rounding: 0,
    code: "DOT",
    namePlural: "Polkadot",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "AVAX",
    name: "Avalanche",
    symbolNative: "AVAX",
    decimalDigits: 18,
    rounding: 0,
    code: "AVAX",
    namePlural: "Avalanche",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "MATIC",
    name: "Matic Token",
    symbolNative: "MATIC",
    decimalDigits: 18,
    rounding: 0,
    code: "MATIC",
    namePlural: "Matic Tokens",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "Ł",
    name: "Litecoin",
    symbolNative: "Ł",
    decimalDigits: 8,
    rounding: 0,
    code: "LTC",
    namePlural: "Litecoins",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "ADA",
    name: "Cardano",
    symbolNative: "ADA",
    decimalDigits: 6,
    rounding: 0,
    code: "ADA",
    namePlural: "Cardanos",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "USDT",
    name: "Tether",
    symbolNative: "USDT",
    decimalDigits: 2,
    rounding: 0,
    code: "USDT",
    namePlural: "Tethers",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "USDC",
    name: "USD Coin",
    symbolNative: "USDC",
    decimalDigits: 2,
    rounding: 0,
    code: "USDC",
    namePlural: "USD Coins",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "DAI",
    name: "Dai",
    symbolNative: "DAI",
    decimalDigits: 2,
    rounding: 0,
    code: "DAI",
    namePlural: "Dais",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "ARB",
    name: "Arbitrum",
    symbolNative: "ARB",
    decimalDigits: 8,
    rounding: 0,
    code: "ARB",
    namePlural: "Arbitrums",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "OP",
    name: "Optimism",
    symbolNative: "OP",
    decimalDigits: 8,
    rounding: 0,
    code: "OP",
    namePlural: "Optimism",
    type: Type.crypto,
    countries: [],
  },
  {
    symbol: "Bs.S.",
    name: "Venezuelan Bolívar",
    symbolNative: "Bs.S.",
    decimalDigits: 2,
    rounding: 0,
    code: "VES",
    namePlural: "Venezuelan bolívars",
    type: Type.fiat,
    countries: [],
  },
  {
    symbol: "STN",
    name: "São Tomé and Príncipe dobra",
    symbolNative: "STN",
    decimalDigits: 2,
    rounding: 0,
    code: "STN",
    namePlural: "dobra",
    type: Type.fiat,
    countries: [],
    locale: "pt",
  },
  {
    symbol: "MRU",
    name: "Mauritanian ouguiya",
    symbolNative: "MRU",
    decimalDigits: 2,
    rounding: 0,
    code: "MRU",
    namePlural: "ouguiya",
    type: Type.fiat,
    countries: [],
    locale: "ar",
  },
];

const currencyCodesInUse: string[] = [
  "AED",
  "ARS",
  "AUD",
  "BRL",
  "BTC",
  "CAD",
  "CHF",
  "CNY",
  "COP",
  "DKK",
  "EGP",
  "EUR",
  "GBP",
  "HKD",
  "ILS",
  "INR",
  "ISK",
  "JOD",
  "JPY",
  "KRW",
  "KWD",
  "MXN",
  "NZD",
  "PHP",
  "RUB",
  "SEK",
  "SGD",
  "TRY",
  "USD",
  "ZAR",
  "BND",
  "IDR",
  "MYR",
  "THB",
  "VND",
];

export const enabledCurrencies: Currency[] = currencies.filter(
  (currency: Currency) => currencyCodesInUse.includes(currency.code)
);
