import { Attr, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContactMailingListsContact from "./ContactMailingListsContact";
import ContentVersion from "./ContentVersion";
import MediaReleaseRecipient from "./MediaReleaseRecipient";

@Model()
class ContactMailingList extends ApplicationRecord {
  static jsonapiType = "contact_mailing_lists";

  @HasMany() contactMailingListsContacts: ContactMailingListsContact[];

  @HasMany() mediaReleaseRecipients: MediaReleaseRecipient[];

  @HasMany() versions: ContentVersion[];

  @Attr() name: string;

  @Attr() publishedAt: string;

  @Attr() createdAt: string;

  @Attr() updatedAt: string;

  @Attr() recipientsCount: number;
}

export default ContactMailingList;
