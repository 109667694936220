export default {
  showSideBar() {
    this.sideBarOpen = true;
  },
  hideSideBar() {
    this.sideBarOpen = false;
  },
  setSideBarContent({ title, subtitle, component, data }) {
    this.sideBarTitle = title;
    this.sideBarSubtitle = subtitle;
    this.sideBarComponent = component;
    this.sideBarData = data;
  },
  setSideBarSubtitle(subtitle) {
    this.sideBarSubtitle = subtitle;
  },
};
