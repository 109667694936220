import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "podcasts",
    path: "/podcasts",
    component: () => import("pages/PodcastsPage.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Podcasts",
    },
  },
  {
    name: "podcasts-new",
    path: "/podcasts/new",
    component: () => import("pages/PodcastEdit.vue"),
    props: true,
    meta: {
      title: "New Podcast",
    },
  },
  {
    name: "podcasts-edit",
    path: "/podcasts/:id/edit",
    component: () => import("pages/PodcastEdit.vue"),
    props: true,
    meta: {
      title: "Edit Podcast",
    },
  },
];
