import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Category from "./Category";

@Model()
class YoutubeChannel extends ApplicationRecord {
  static jsonapiType = "youtube_channels";

  @BelongsTo() category: Category;

  @BelongsTo() location: Location;

  @Attr() youtubeId: string;

  @Attr() audience: number;

  @Attr() name: string;

  @Attr() timeZone: string;

  @Attr() description: string;

  @Attr() logoUrl: string;

  @Attr() enabled: boolean;

  @Attr() categoryId: number;

  @Attr() locationId: number;

  @Attr() platformLogo: string;

  @Attr() url: string;
}

export default YoutubeChannel;
