import { getLocale, getLocaleNumber, getLocaleValue } from "shared/boot/i18n";

export function clampBetweenValues(
  number: number,
  min: number,
  max: number
): number {
  return Math.min(max, Math.max(min, number));
}

const INVALID_NUMBER_VALUES = new Set([null, "", true, false]);

export function isNumber(input: any): boolean {
  return !INVALID_NUMBER_VALUES.has(input) && !Number.isNaN(Number(input));
}

export function ordinalNumber(value: any, lang?: Intl.LocalesArgument): string {
  const pluralRules = new Intl.PluralRules(lang ?? getLocale(), {
    type: "ordinal",
  });

  const suffixes = getLocaleValue("helpers.ordinal_numbers");
  const number = Number(value);

  const rule = pluralRules.select(number);

  return `${number}${suffixes[`${rule}_rule`]}`;
}

export function suffix(number: any): string {
  return getLocaleNumber(number, "suffix");
}

export function prettyNumber(number: any, option?: string): string | number {
  if (!number) {
    return 0;
  }

  return getLocaleNumber(Number(number), option);
}

export function notificationNumber(number: number): string | number {
  return number > 99 ? "99+" : number;
}

export function zeroPad(number: any, places: number): string {
  return String(number).padStart(places, "0");
}

export function inRange(
  number: number,
  {
    max,
    min,
    inclusive = true,
  }: { max: number; min: number; inclusive?: boolean }
): boolean {
  if (inclusive) {
    return number >= min && number <= max;
  }

  return number > min && number < max;
}

export function calculateAspectRatioFit(
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
): { width: number; height: number } {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export function inputNumberOnly(event: KeyboardEvent): boolean | undefined {
  const { key } = event;

  const isAllowedKey =
    /[0-9]/i.test(key) ||
    /NumLock|Backspace|Clear|Tab|Enter|Shift|Control|Alt|Escape|CapsLock|Delete/i.test(
      key
    );

  let result;

  if (isAllowedKey) {
    result = true;
  } else {
    event.preventDefault();
    result = undefined;
  }

  return result;
}
