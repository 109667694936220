import { appInstance } from "shared/boot/app";

export default function useProductRouting() {
  function doesMatchRoutePrefix(routePrefix, to = null) {
    const currentRoute = to || appInstance.config.globalProperties.$route;

    if (currentRoute.params?.product) {
      return currentRoute.params.product === routePrefix;
    }

    return currentRoute.matched.some(
      (matchedRoute) => matchedRoute.name === routePrefix
    );
  }

  function routeProductName(to) {
    if (doesMatchRoutePrefix("social", to)) {
      return "social";
    }

    if (doesMatchRoutePrefix("competitor-dashboard", to)) {
      return "competitor";
    }

    if (doesMatchRoutePrefix("crm", to)) {
      return "crm";
    }

    if (doesMatchRoutePrefix("outreach", to)) {
      return "outreach";
    }

    if (doesMatchRoutePrefix("csl", to)) {
      return "csl";
    }

    return "media";
  }

  return { routeProductName };
}
