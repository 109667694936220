import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContactMailingList from "./ContactMailingList";
import MediaRelease from "./MediaRelease";

@Model()
class MediaReleasesContactMailingList extends ApplicationRecord {
  static jsonapiType = "media_releases_contact_mailing_lists";

  @BelongsTo() mediaRelease: MediaRelease;

  @BelongsTo() contactMailingList: ContactMailingList;

  @Attr() mediaReleaseId: number;

  @Attr() contactMailingListId: number;
}

export default MediaReleasesContactMailingList;
