import { track } from "shared/boot/analytics";
import useTeamPicker from "shared/composables/useTeamPicker";
import { dateToTimestamp, parseDate } from "shared/helpers/date";
import { getSource } from "shared/helpers/media";
import { mentionKeywords, sentimentRating } from "shared/helpers/mentions";
import features from "shared/services/features";

import ApiClient from "./ApiClient";

class OrganisationReportService extends ApiClient {
  constructor() {
    super("/organisation_reports");
  }

  // API calls

  create(options = {}) {
    track("Created Organisation Report");

    return super.create(options);
  }

  update(reportId, options = {}) {
    track("Updated Organisation Report");

    return super.update(reportId, options);
  }

  destroy(reportId, options = {}) {
    track("Deleted Organisation Report");

    return super.destroy(reportId, options);
  }

  email(id, options = {}) {
    track("Sent Organisation Report");

    return this.post(`/${id}/email`, options);
  }

  testEmail(id, options = {}) {
    return this.post(`/${id}/test_email`, options);
  }

  preview(options = {}) {
    return this.post("/preview", options);
  }

  savedPreview(id, options = {}) {
    return this.get(`/${id}/preview`, options);
  }

  analytics(id, options = {}) {
    return this.get(`/${id}/analytics`, options);
  }

  aggregateAnalytics(options = {}) {
    return this.get("/analytics", options);
  }

  duplicateReport(reportId) {
    track("Duplicating Organisation Report");

    return this.post(`/${reportId}/duplicate`);
  }

  /**
   * @param {Object} mention
   * @param {string} summaryType
   * @param {boolean} isDebuilder
   * @param {number|null} specificationId
   * @return {AxiosPromise<{summary: string, count?: number}>}
   */
  mentionSummary(
    mention,
    summaryType,
    isDerBuilder = false,
    specificationId = null
  ) {
    return this.get(`/mention_summary`, {
      params: {
        target_id: mention.id,
        target_type: mention.type,
        keywords: Object.keys(mention.keywords).map((keywords) =>
          keywords.replace(/<[^>]*>?/gm, "")
        ),
        summary_type: summaryType,
        is_der_builder: isDerBuilder,
        specification_id: specificationId,
      },
    });
  }

  summaryCount(increment = false) {
    return this.get("/summary_count", {
      params: {
        increment_count: increment,
      },
    });
  }

  decrementSummaryCount(decrementBy = 1) {
    return this.put("/decrement_summary_count", {
      params: {
        decrement_by: decrementBy,
      },
    });
  }

  // Utility Methods

  parseSendingOptions(options) {
    const defaults = { pdfType: "" };

    const recipients =
      typeof options.recipients === "string"
        ? options.recipients.split(",")
        : options.recipients;

    if (options.include_complete_pdf) {
      defaults.pdfType = "all";
    }

    if (options.include_print_clips_pdf) {
      defaults.pdfType = "print";
    }

    defaults.pdfReportTemplateName = options.pdf_report_template_name;
    defaults.prefillEmptyFolders = options.prefill_empty_folders;
    defaults.emptyFolderMessage = options.empty_folder_message;

    defaults.includePdf = defaults.pdfType.length > 0;

    defaults.includePrintClipsPdfWithAfrClippings =
      options.include_print_clips_pdf_with_afr_clippings;

    defaults.includeCsv = options.include_csv;

    defaults.includeFolderPrintClipsPdf =
      options.include_folder_print_clips_pdf;

    defaults.displayExcerpts = options.show_excerpts;
    defaults.displayKeywordCount = options.show_keyword_count;
    defaults.displayRSSLink = options.show_rss_link;
    defaults.mentionsDisplay = options.mentions_display;
    defaults.fontSize = options.font_size;
    defaults.message = options.message ? options.message : "";
    defaults.accountManagementNotes = options.account_management_notes;
    defaults.disclaimer = options.disclaimer ? options.disclaimer : "";
    defaults.footer = options.footer ? options.footer : "";
    defaults.showRestrictedContent = options.show_restricted_content;
    defaults.showSentiment = options.show_sentiment;

    if (options.send_as_organisation) {
      defaults.sendAs = "team";
    } else if (options.email_domain_id) {
      defaults.sendAs = "organisation_domain";
    } else if (options.custom_sender && options.custom_sender.length) {
      defaults.sendAs = "custom";
      defaults.customSender = options.custom_sender;
    } else {
      defaults.sendAs = "me";
    }

    defaults.recipients = recipients
      ? recipients.filter((recipient) => recipient)
      : [];

    defaults.includeDisclaimer = options.include_disclaimer;
    if (options.disclaimer) defaults.disclaimer = options.disclaimer;

    if (options.enable_organisation_report_viewer) {
      defaults.enableOrganisationReportViewer =
        options.enable_organisation_report_viewer;
    }

    if (options.organisation_brand_id) {
      defaults.organisation_brand_id = options.organisation_brand_id;
    }

    if (!features.has("has_combined_print_clippings_pdf")) {
      defaults.includePdf = false;
    }

    return defaults;
  }

  setDefaultsFromSpecification(specification) {
    return {
      ...specification,
      ...this.parseSendingOptions(specification),
      ...{
        recipients: specification.recipients
          .split(",")
          .map((email) => email.trim()),
      },
      specification_id: specification.id,
    };
  }

  parseFolders(report) {
    return report.folders.map((folder) => {
      const items = folder.items.map((item) => {
        const publishedAt =
          item.type === "customer_article"
            ? parseDate(item.published_at)
            : item.published_at;

        const createdAt = item.created_at;

        let isTvOrRadioMention = false;

        if (
          !["customer_article", "transcript_request", "external_item"].includes(
            item.type
          )
        ) {
          const source = getSource(item.type);

          isTvOrRadioMention =
            source && ["TV", "Radio"].includes(source.medium);
        }

        const timestamp =
          item.isMediaItem || isTvOrRadioMention
            ? Math.trunc(item.start_time)
            : dateToTimestamp(item.timestamp);

        const summaryStatus = ["in-progress", "limit-reached"].includes(
          item.summaryStatus
        )
          ? "error"
          : item.summaryStatus;

        return {
          ...item,
          summaryStatus,
          id: item.selectedSyndication || item.id,
          folder,
          timestamp,
          published_at: publishedAt,
          created_at: createdAt,
        };
      });

      return {
        ...folder,
        items,
        open: false,
        editing: false,
      };
    });
  }

  parseEmailOptions(report) {
    const { folders, ...emailOptions } = report;

    return {
      ...emailOptions,
      ...this.parseSendingOptions(emailOptions),
    };
  }

  buildItem(item) {
    let sentiment = null;

    if (item.stream) {
      sentiment = sentimentRating(item, item.stream).sentiment;
    }

    return {
      id: item.selectedSyndication || item.id,
      type: item.type,
      token: item.token,
      isMediaItem: item.isMediaItem,
      timestamp: item.timestamp,
      keywords: mentionKeywords(item),
      source_stream_id: item.sourceStream?.id,
      ...(sentiment ? { sentiment } : {}),
      ...(item.summary ? { summary: item.summary } : {}),
      ...(item.summaryStatus ? { summaryStatus: item.summaryStatus } : {}),
      ...(item.title ? { summary_title: item.title } : {}),
    };
  }

  buildFolders(folders, emailOptions = {}) {
    return folders.map((folderToSave, index) => ({
      label: folderToSave.label,
      order: index,
      heading_summary: this.headingSummary(
        folderToSave,
        emailOptions.prefillEmptyFolders,
        emailOptions.emptyFolderMessage
      ),
      sortingOptions: folderToSave.sortingOptions,
      items: folderToSave.items.map(this.buildItem.bind(this)),
    }));
  }

  headingSummary(folder, prefill, customMessage = null) {
    const prefillMessage =
      customMessage && customMessage.length
        ? customMessage
        : `There is no relevant coverage for '${folder.label}' at this time.`;

    if (!prefill && folder.heading_summary === prefillMessage) {
      return "";
    }

    if (folder.heading_summary) {
      return folder.heading_summary;
    }

    if (
      prefill &&
      !folder.items.length &&
      !["TV & Radio Transcripts", "TV & Radio Media Items"].includes(
        folder.label
      )
    ) {
      return prefillMessage;
    }

    return "";
  }

  buildRequestBody(
    reportFolders,
    emailOptions,
    shareWith,
    customSharedUserIds,
    summaryType
  ) {
    const folders = this.buildFolders(
      reportFolders,
      emailOptions.prefillEmptyFolders
    );

    const senderOptions = {
      send_as_organisation: emailOptions.sendAs === "team",
      custom_sender:
        emailOptions.sendAs === "custom" ? emailOptions.customSender : "",
      ...(emailOptions.sendAs === "organisation_domain"
        ? {
            custom_sender: "",
            email_domain_id: emailOptions.email_domain_id,
            sender_email: emailOptions.sender_email,
            sender_name: emailOptions.sender_name,
          }
        : {}),
    };

    const { selectedTeamPreference } = useTeamPicker();

    return {
      folders,
      recipients: emailOptions.recipients,
      show_excerpts: emailOptions.displayExcerpts,
      show_keyword_count: emailOptions.displayKeywordCount,
      show_rss_link: emailOptions.displayRSSLink,
      mentions_display: emailOptions.mentionsDisplay,
      subject: emailOptions.subject,
      title: emailOptions.title,
      message: emailOptions.message,
      account_management_notes: emailOptions.accountManagementNotes,
      content_date: emailOptions.content_date,
      include_disclaimer: emailOptions.includeDisclaimer,
      disclaimer: emailOptions.disclaimer,
      footer: emailOptions.footer,
      theme_color: emailOptions.themeColor,
      font_size: emailOptions.fontSize,
      logo_url: emailOptions.logoUrl,
      prefill_empty_folders: emailOptions.prefillEmptyFolders,
      organisation_brand_id: emailOptions.organisation_brand_id,
      enable_organisation_report_viewer:
        emailOptions.enableOrganisationReportViewer,
      ...(emailOptions.pdfReportTemplateName
        ? { pdf_report_template_name: emailOptions.pdfReportTemplateName }
        : {}),
      ...(emailOptions.drafter_id
        ? { drafter_id: emailOptions.drafter_id }
        : {}),
      ...(emailOptions.specification_id
        ? { specification_id: emailOptions.specification_id }
        : {}),
      ...(emailOptions.includePdf
        ? {
            include_complete_pdf: emailOptions.pdfType === "all",
            include_print_clips_pdf: emailOptions.pdfType === "print",
          }
        : {
            include_complete_pdf: false,
            include_print_clips_pdf: false,
          }),
      include_print_clips_pdf_with_afr_clippings:
        emailOptions.includePrintClipsPdfWithAfrClippings,
      ...senderOptions,
      ...(emailOptions.after ? { after: emailOptions.after } : {}),
      ...(emailOptions.before ? { before: emailOptions.before } : {}),
      include_csv: emailOptions.includeCsv,
      include_folder_print_clips_pdf: emailOptions.includeFolderPrintClipsPdf,
      share_with: shareWith,
      custom_shared_user_ids: customSharedUserIds,
      summary_type: summaryType,
      show_restricted_content: emailOptions.showRestrictedContent,
      ...(features.has("has_team_workspaces")
        ? { organisation_team_id: selectedTeamPreference.value }
        : {}),
      language: emailOptions.language,
      show_sentiment: emailOptions.showSentiment,
    };
  }
}

export default new OrganisationReportService();
