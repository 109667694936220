import { Attr, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class NewspaperFrontpage extends ApplicationRecord {
  static jsonapiType = "newspaper_frontpages";

  @HasOne() source: any;

  @Attr() name: string;

  @Attr() publishedAt: string;

  @Attr() pdfUrl: string;

  @Attr() sourceGroup: string;

  @Attr({ persist: false }) publicLink: string;

  @Attr({ persist: false }) thumbnailUrl: string;

  static latest() {
    return NewspaperFrontpage.order({ publishedAt: "desc" }).where({
      publishedAt: { lte: new Date().toUTCString() },
    });
  }
}

export default NewspaperFrontpage;
