import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

export const useUniversalPlayerStore = defineStore("universalPlayer", {
  state,
  getters,
  actions,
});

export default useUniversalPlayerStore;
