export default [
  {
    name: "mention-review-index",
    path: "/mention_review",
    props: (route) => ({
      type: route.query.type,
      tab: route.query.tab,
      targetId: route.query.target_id,
    }),
    component: () => import("pages/mention_review/Index.vue"),
    meta: {
      title: "QC (Mention Review)",
      accessLevel: "limited",
    },
  },
  {
    name: "qc-triggers-index",
    path: "/quality_control_triggers",
    component: () => import("pages/mention_review/Triggers.vue"),
    meta: {
      title: "QC Triggers",
    },
  },
  {
    name: "qc-prediction-models-edit",
    path: "/quality-control-triggers/:id/predictors",
    component: () => import("pages/mention_review/PredictionModelsEdit.vue"),
    props: true,
    meta: {
      title: "Edit Prediction Models",
    },
  },
];
