import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import User from "./User";

@Model()
class AudienceReport extends ApplicationRecord {
  static jsonapiType = "audience_reports";

  @BelongsTo() user: User;

  @Attr({ persist: false }) after: number;

  @Attr({ persist: false }) before: number;

  @Attr({ persist: false }) pdf: boolean;

  @Attr({ persist: false }) csv: boolean;

  @Attr({ persist: false }) pdfUrl: string;

  @Attr({ persist: false }) csvUrl: string;

  @Attr({ persist: false }) interval: string;

  @Attr({ persist: false }) createdAt: string;
}

export default AudienceReport;
