export default [
  {
    name: "organisation-report-spec-groups",
    path: "/organisation-report-spec-groups",
    props: (route) => ({
      view: "spec-groups",
      ...route.params,
      ...route.query,
    }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "der",
      title: "Organisation Report - Spec Groups",
    },
  },
  {
    name: "organisation-report-spec-group-new",
    path: "/organisation-report-spec-groups/new",
    component: () => import("pages/OrganisationReportSpecGroupEdit.vue"),
    meta: {
      accessLevel: "der",
    },
  },
  {
    name: "organisation-report-spec-group-edit",
    path: "/organisation-report-spec-groups/:groupId/edit",
    props: true,
    component: () => import("pages/OrganisationReportSpecGroupEdit.vue"),
    meta: {
      accessLevel: "der",
    },
  },
];
