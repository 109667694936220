export default [
  {
    name: "add-social-stream",
    path: "/organisations/:organisationId/social-streams/new",
    props: true,
    component: () => import("pages/social_streams/SocialStreamsEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "New Social Stream",
    },
  },
  {
    name: "social-streams-edit",
    path: "/organisations/:organisationId/social-streams/:streamId/edit",
    props: true,
    component: () => import("pages/social_streams/SocialStreamsEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Social Stream",
    },
  },
];
