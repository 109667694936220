export default [
  {
    name: "organisation-brand-add",
    path: "/organisations/:organisationId/organisation_brands/new",
    props: true,
    component: () => import("pages/OrganisationBrandEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "New Organisation Brand",
    },
  },
  {
    name: "organisation-brand-edit",
    path: "/organisations/:organisationId/organisation_brands/:organisationBrandId/edit",
    props: true,
    component: () => import("pages/OrganisationBrandEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Organisation Brand",
    },
  },
];
