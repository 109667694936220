import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "magazine-article-list",
    path: "/magazine_articles",
    component: () => import("pages/MagazineArticleList.vue"),
    meta: {
      title: "Magazine Articles",
    },
  },
  {
    name: "magazine-article-new",
    path: "/magazine_articles/new",
    component: () => import("pages/MagazineArticleEdit.vue"),
    props: true,
    meta: {
      title: "New Magazine Article",
    },
  },
  {
    name: "magazine-article-edit",
    path: "/magazine_articles/:id/edit",
    component: () => import("pages/MagazineArticleEdit.vue"),
    props: true,
    meta: {
      title: "Edit Magazine Article",
    },
  },
  {
    name: "magazines-list",
    path: "/magazines",
    component: () => import("pages/MagazineList.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Magazines",
    },
  },
  {
    name: "magazine-viewer",
    path: "/magazine-viewer",
    component: () => import("pages/Viewer.vue"),
    props: { title: "Magazine Viewer", sourceType: "magazine" },
  },
  {
    name: "magazines-new",
    path: "/magazines/new",
    component: () => import("pages/MagazineEdit.vue"),
    props: true,
    meta: {
      title: "New Magazine",
    },
  },
  {
    name: "magazines-edit",
    path: "/magazines/:id/edit",
    component: () => import("pages/MagazineEdit.vue"),
    props: true,
    meta: {
      title: "Edit Magazine",
    },
  },
  {
    name: "magazine-pages-index",
    path: "/magazine-pages",
    component: () => import("pages/PrintPageList.vue"),
    props: { type: "magazine_page" },
    meta: {
      title: "Magazine Pages",
    },
  },
];
