import { Attr, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import User from "./User";

@Model()
class OrganisationAggregationMultiplier extends ApplicationRecord {
  static jsonapiType = "organisation_aggregation_multipliers";

  @HasOne() organisation: Organisation;

  @HasOne() aveMultiplierLastUpdatedBy: User;

  @HasOne() audienceMultiplierLastUpdatedBy: User;

  @Attr() aveMultiplier: number;

  @Attr({ persist: false }) aveMultiplierLastUpdatedAt: string;

  @Attr() audienceMultiplier: number;

  @Attr({ persist: false }) audienceMultiplierLastUpdatedAt: string;

  @Attr() organisationId: number;
}

export default OrganisationAggregationMultiplier;
