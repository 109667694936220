import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import UserShift from "./UserShift";

@Model()
class UserShiftSpec extends ApplicationRecord {
  static jsonapiType = "user_shift_specs";

  @BelongsTo() userShift: UserShift;

  @Attr() userShiftId: number;

  @Attr() userId: number;

  @Attr() specificationId: number;

  @Attr() startTime: number;

  @Attr() role: string;
}

export default UserShiftSpec;
