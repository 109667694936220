export default [
  {
    name: "transcript-requests-index",
    path: "/transcript-requests",
    component: () => import("pages/TranscriptRequestList.vue"),
    props: (route) => ({ ...route.params, ...route.query }),
    meta: {
      title: "Transcript Item Requests",
      accessLevel: "limited",
    },
  },
  {
    name: "transcript-requests-edit",
    path: "/transcript-requests/:transcriptRequestId/edit",
    component: () => import("pages/TranscriptRequestEdit.vue"),
    props: true,
    meta: {
      title: "Edit Transcript Request",
    },
  },
  {
    name: "transcript-requests-new",
    path: "/transcript-requests/new",
    component: () => import("pages/TranscriptRequestEdit.vue"),
    props: true,
    meta: {
      title: "New Transcript Item Request",
      accessLevel: "limited",
    },
  },
  {
    name: "media-requests-index",
    path: "/media-requests",
    component: () => import("pages/MediaRequestList.vue"),
    meta: {
      title: "Media Item Requests",
      accessLevel: "limited",
    },
  },
];
