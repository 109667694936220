import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "newspaper-article-list",
    path: "/newspaper_articles",
    component: () => import("pages/NewspaperArticleList.vue"),
    meta: {
      title: "Newspaper Articles",
    },
  },
  {
    name: "paper-articles-new",
    path: "/newspaper_articles/new",
    component: () => import("pages/NewspaperArticleEdit.vue"),
    props: true,
    meta: {
      title: "New Newspaper Article",
    },
  },
  {
    name: "newspaper-article-edit",
    path: "/newspaper_articles/:id/edit",
    component: () => import("pages/NewspaperArticleEdit.vue"),
    props: true,
    meta: {
      title: "Edit Newspaper Article",
    },
  },
  {
    name: "newspapers-list",
    path: "/newspapers",
    component: () => import("pages/NewspaperList.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Newspapers",
    },
  },
  {
    name: "newspaper-viewer",
    path: "/newspaper-viewer",
    component: () => import("pages/Viewer.vue"),
    props: { title: "Newspaper Viewer", sourceType: "newspaper" },
  },
  {
    name: "newspapers-new",
    path: "/newspapers/new",
    component: () => import("pages/NewspaperEdit.vue"),
    props: true,
    meta: {
      title: "New Newspaper",
    },
  },
  {
    name: "newspapers-edit",
    path: "/newspapers/:id/edit",
    component: () => import("pages/NewspaperEdit.vue"),
    props: true,
    meta: {
      title: "Edit Newspaper",
    },
  },
  {
    name: "newspaper-pages-index",
    path: "/newspaper-pages",
    component: () => import("pages/PrintPageList.vue"),
    props: { type: "newspaper_page" },
    meta: {
      title: "Newspaper Pages",
    },
  },
];
