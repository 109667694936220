import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import FilterSet from "./FilterSet";
import Organisation from "./Organisation";
import QuerySnippetGroup from "./QuerySnippetGroup";
import QuerySnippetLink from "./QuerySnippetLink";

@Model()
class QuerySnippet extends ApplicationRecord {
  static jsonapiType = "query_snippets";

  @HasMany() filterSets: FilterSet[];

  @HasMany() querySnippetLinks: QuerySnippetLink[];

  @BelongsTo() organisation: Organisation;

  @HasOne() group: QuerySnippetGroup;

  @Attr({ persist: false }) archivedAt: string;

  @Attr() organisationId: number;

  @Attr() booleanQuery: string;

  @Attr({ persist: false }) code: string;

  @Attr({ persist: false }) filterSetIds: number[];

  @Attr() enabledMedia: string;

  @Attr() groupId: number;

  @Attr() label: string;

  @Attr() notes: string;

  @Attr() querySnippetLinksCount: number;

  @Attr() position: number;

  @Attr() invalidQuery: boolean;
}

export default QuerySnippet;
