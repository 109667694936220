import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class StreamSource extends ApplicationRecord {
  static jsonapiType = "stream_sources";

  @Attr() streamId: number;

  @Attr() targetId: number;

  @Attr() targetType: string;

  @Attr({ persist: false }) targetName: string;

  @Attr() action: string;
}

export default StreamSource;
