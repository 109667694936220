import { BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import RadioStation from "./RadioStation";

@Model()
class RadioClip extends ApplicationRecord {
  static jsonapiType = "radio_clips";

  @BelongsTo() source: RadioStation;
}

export default RadioClip;
