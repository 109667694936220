import { useUserStore } from "shared/stores/user";

class Permissions {
  isAdminUser() {
    return useUserStore().isAdminUser;
  }

  isCustomerView() {
    return useUserStore().customerViewEnabled;
  }

  filteredPermissions(product) {
    let permissions = [];

    if (product === "traditional") {
      permissions = useUserStore().currentUser.permissions;
    } else if (product === "social") {
      permissions = useUserStore().currentUser.social_permissions;
    } else if (product === "outreach") {
      permissions = useUserStore().currentUser.outreach_permissions;
    }

    return permissions;
  }

  has(permission, product = "traditional") {
    if (this.isAdminUser() && !this.isCustomerView()) return true;

    return (
      useUserStore() && this.filteredPermissions(product)?.includes(permission)
    );
  }

  hasAny(permissions = [], product = "traditional") {
    return permissions.some((permission) => this.has(permission, product));
  }

  hasAll(permissions = []) {
    return permissions.every((permission) => this.has(permission));
  }
}

export default Permissions;
