/* eslint-disable import/prefer-default-export */
import {
  connectionError,
  forbiddenError,
  serverError,
  unauthorizedError,
} from "shared/boot/errors";

import type { CreateAxiosInstance, CreateAxiosInstanceError } from "./types";

function handleErrors(error: CreateAxiosInstanceError) {
  if (error?.response?.status) {
    switch (error.response.status) {
      case 422: // Got validation error
        return Promise.reject(error);
      default: // When any other error is received...
        return serverError(error);
    }
  } else {
    return connectionError(error);
  }
}

export async function errorHandler(
  error: CreateAxiosInstanceError
): Promise<CreateAxiosInstance> {
  if (
    [401].includes(Number(error?.response?.status)) &&
    error?.response?.data?.message !== "sudo access required"
  ) {
    unauthorizedError(error);

    if (error?.response?.data?.two_factor_required) {
      return Promise.reject(error);
    }

    return new Promise(() => {});
  }

  if (Number(error?.response?.status) === 403) {
    forbiddenError(error);

    return new Promise(() => {});
  }

  if (error?.config?.handleErrors === true) {
    handleErrors(error);

    return new Promise(() => {});
  }

  return Promise.reject(error);
}
