import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class OutreachLocation extends ApplicationRecord {
  static jsonapiType = "outreach_locations";

  @BelongsTo() parent: OutreachLocation;

  @HasMany() children: OutreachLocation[];

  @Attr() name: string;

  @Attr() locationType: string;

  @Attr() parentId: number;

  @Attr() visible: boolean;

  @Attr() selfAndParentIds: number[];

  @Attr() selfAndParentNames: string[];
}

export default OutreachLocation;
