import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

export const useOrganisationFeaturesStore = defineStore(
  "organisationFeatures",
  {
    state,
    getters,
    actions,
  }
);

export default useOrganisationFeaturesStore;
