export default () => ({
  navbarCollapsed: false,
  navbarTitle: "",
  navbarLeftIcon: null,
  disableMobileTrap: false,

  // Global data loaded on app starting
  locations: [],
  cities: [],
  categories: [],
  socialCategories: [],

  globalDataLoaded: false,
  globalDataError: false,

  analyticsIntegration: null,
  technicalServerError: false,

  appConfig: {},
  routerViewKey: null,
});
