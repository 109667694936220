export default [
  {
    name: "organisation-report-specs",
    path: "/organisation-report-specs",
    props: (route) => ({ view: "specs", ...route.params, ...route.query }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "der",
      title: "Organisation Reports",
    },
  },
  {
    name: "organisation-report-specs-new",
    path: "/organisation-report-specs/new",
    component: () => import("pages/OrganisationReportSpecEdit.vue"),
    meta: {
      accessLevel: "der",
      title: "New Organisation Report Specs",
    },
  },
  {
    name: "organisation-report-specs-edit",
    path: "/organisation-report-specs/:specificationId/edit",
    props: true,
    component: () => import("pages/OrganisationReportSpecEdit.vue"),
    meta: {
      accessLevel: "der",
      title: "Edit Organisation Report Specs",
    },
  },
];
