import mixpanel from "mixpanel-browser";
import { storeToRefs } from "pinia";
import { Platform } from "quasar";

import {
  identify as identifyAnalytics,
  updateIntercom,
} from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { initialize as initializeLogrocket } from "shared/boot/logrocket";
import { identify as identifyRollbar } from "shared/boot/rollbar";
import { getCurrentTimestamp } from "shared/helpers/date";
import { AnalyticsIntegration } from "shared/resources";
import appConfig from "shared/services/appConfig";
import initChurnZero from "shared/services/churnzero";
import { useAlertsStore } from "shared/stores/alerts";
import { useBookmarkStreamsStore } from "shared/stores/bookmarkStreams";
import { useFeatureTeasersStore } from "shared/stores/featureTeasers";
import { useMediaRequestsStore } from "shared/stores/mediaRequests";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useStreamGroupsStore } from "shared/stores/streamGroups";
import { useStreamsStore } from "shared/stores/streams";
import { useTranscriptRequestsStore } from "shared/stores/transcriptRequests";
import { useUserStore } from "shared/stores/user";

const handleErrors = true;

export default {
  setGlobalDataLoading() {
    this.globalDataLoaded = false;
    this.globalDataError = false;
  },
  setGlobalDataLoaded() {
    this.globalDataLoaded = true;
    this.globalDataError = false;
  },
  disableMobileTrapPage() {
    this.disableMobileTrap = true;
  },
  setAppConfig(config) {
    this.appConfig = config;
  },
  async getAppData() {
    const { fetchAlertCount } = useAlertsStore();
    const { fetchFeatureTeasers } = useFeatureTeasersStore();

    const userStore = useUserStore();

    // Initialize LogRocket as soon as possible
    const LogRocket = initializeLogrocket({
      adminUser: userStore.isAdminUser,
    });

    updateIntercom({ hide_default_launcher: true });

    await userStore.getUser();

    this.setGlobalDataLoading();

    const result = await Promise.all([
      useStreamGroupsStore().getStreamGroups(),
      this.getLocations(),
      this.getCategories(),
      useStreamsStore().getStreams(),
    ]);

    await Promise.all([
      useUserStore().getOrganisation(),
      useUserStore().getTeam(),
    ]);

    this.setGlobalDataLoaded();

    setTimeout(() => {
      useMentionCountsStore().fetchMentionCounts({ first_load: true });
      useBookmarkStreamsStore().fetchBookmarkCounts();
      fetchAlertCount();
      useMediaRequestsStore().fetchNewMediaRequestsCount();
      useTranscriptRequestsStore().fetchTranscriptRequestCount();
      fetchFeatureTeasers();
      this.fetchAnalyticsIntegration();
      useUserStore().getMultiplier();
    }, 500);

    const user = useUserStore();
    const { currentUser: currentUserRef, isAdminUser } = storeToRefs(user);
    const currentUser = currentUserRef.value;

    const organisationRegion = user.organisation.region;

    if (!["au", "nz"].includes(organisationRegion)) {
      const config = appConfig.getConfig(organisationRegion);

      this.setAppConfig(config);
    }

    if (!isAdminUser.value) {
      useUserStore().updateUser({ last_accessed_at: getCurrentTimestamp() });
    }

    const userId = isAdminUser.value ? `AM-${currentUser.id}` : currentUser.id;

    const displayName =
      isAdminUser.value && user.accountManager
        ? `${user.accountManager.name} as ${currentUser.first_name} ${currentUser.last_name}`
        : `${currentUser.first_name} ${currentUser.last_name}`;

    identifyRollbar({
      id: userId,
      username: displayName,
      email: currentUser.email,
    });

    // identify with logrocket only when a admin user to track sessions
    const identityFn =
      isAdminUser.value && LogRocket
        ? (id, opts) => LogRocket.identify(id, opts)
        : identifyAnalytics;

    const identityAttributes = {
      id: currentUser.id,
      user_hash: currentUser.user_hash,
      email: currentUser.email,
      phone: currentUser.international_phone_number,
      name: `${currentUser.first_name} ${currentUser.last_name}`,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      role: currentUser.role,
      show_intercom: currentUser.show_intercom,
      auto_refresh_enabled: currentUser.auto_refresh_streams,
      organisation_id: user.organisation.id,
      organisation_name: user.organisation.name,
      global_customer_id: user.organisation.global_customer_id || "",
      company: { id: user.organisation.id },
      ...(Platform.is.mobile && {
        last_platform: "Mobile",
        mobile_app_version: "__version_number__",
      }),
      ...(Platform.is.desktop && {
        last_platform: "Desktop",
        desktop_app_version: "__version_number__",
      }),
      ...(Platform.is.ios && { mobile_type: "iOS" }),
      ...(Platform.is.android && { mobile_type: "Android" }),
    };

    identityFn(currentUser.id, identityAttributes);

    if (!isAdminUser.value) {
      initChurnZero(identityAttributes);
    }

    if (!isAdminUser.value && import.meta.env.VITE_MIXPANEL_TOKEN) {
      mixpanel.set_group("organisation_id", user.organisation.id);
    }

    return result;
  },
  getLocations() {
    return $streemApiV1
      .get("locations", {
        params: {
          visible: true,
        },
        handleErrors,
      })
      .then((response) => {
        this.setLocations(response.data);
      });
  },
  setLocations(locations) {
    this.locations = locations;
  },
  getCities() {
    return $streemApiV1
      .get("locations", {
        params: {
          type: "City",
        },
        handleErrors,
      })
      .then((response) => {
        this.cities = response.data;
      });
  },
  getCategories() {
    return $streemApiV1
      .get("categories", {
        params: {
          include_social: true,
        },
        handleErrors,
      })
      .then((response) => {
        this.setCategories(response.data);
      });
  },
  setCategories(data) {
    this.categories = data.filter(({ social }) => !social);
    this.socialCategories = data.filter(({ social }) => social);
  },
  fetchAnalyticsIntegration() {
    const userStore = useUserStore();
    const { currentUser } = storeToRefs(userStore);

    AnalyticsIntegration.includes(["analyticsDataStreams"])
      .where({
        organisationId: currentUser.value.organisation.id,
      })
      .selectExtra(["isIntegrated"])
      .first()
      .then((response) => {
        this.analyticsIntegration = response.data;
      })
      .catch(() => {
        // do nothing
      });
  },
  turnOffUserStreamsNotifications() {
    return $streemApiV1
      .put("streams/snooze_notifications", { handleErrors })
      .then(() => useStreamsStore().getStreams());
  },
  setNavbarTitle(title) {
    this.navbarTitle = title;
  },
  setNavbarLeftIcon(icon) {
    this.navbarLeftIcon = icon;
  },
  setRouterViewKey(key) {
    this.routerViewKey = key;
  },
};
