import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class SourceTopic extends ApplicationRecord {
  static jsonapiType = "source_topics";

  @Attr() name: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default SourceTopic;
