import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Advertisement from "./Advertisement";
import TvCaptionFragment from "./TvCaptionFragment";

@Model()
class AdvertisementFragment extends ApplicationRecord {
  static jsonapiType = "advertisement_fragments";

  @BelongsTo() advertisment: Advertisement;

  @BelongsTo() tvCaptionFragment: TvCaptionFragment;

  @Attr() text: string;

  @Attr() timestamp: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default AdvertisementFragment;
