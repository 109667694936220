import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Category from "./Category";
import SourceGroup from "./SourceGroup";

@Model()
class Blog extends ApplicationRecord {
  static jsonapiType = "blogs";

  @BelongsTo() category: Category;

  @BelongsTo() sourceGroup: SourceGroup;

  @Attr() categoryId: number;

  @Attr() locationId: number;

  @Attr() logoUrl: string;

  @Attr() name: string;

  @Attr() notes: string;

  @Attr() sourceGroupId: number;

  @Attr() timeZone: string;

  @Attr() url: string;

  @Attr() visible: boolean;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default Blog;
