export default [
  {
    name: "query-snippet-group-add",
    path: "/organisations/:organisationId/query-snippet-groups/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/QuerySnippetGroupEditPage.vue"),
    meta: {
      title: "Create Query Snippet Group",
    },
  },
  {
    name: "query-snippet-group-edit",
    path: "/organisations/:organisationId/query-snippet-groups/:querySnippetGroupId/edit",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/QuerySnippetGroupEditPage.vue"),
    meta: {
      title: "Edit Query Snippet Group",
    },
  },
];
