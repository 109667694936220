import { Attr, BelongsTo, Model } from "spraypaint";

import { attachmentUrl } from "shared/helpers/attachment";
import { ApplicationRecord } from "shared/services/spraypaint";

import ActiveStorageBlob from "./ActiveStorageBlob";

type AttachmentBlobAttributes = {
  readonly video?: boolean;
  readonly url: string;
  readonly proxyUrl: string;
};

@Model()
class ActiveStorageAttachment extends ApplicationRecord {
  static jsonapiType = "active_storage_attachments";

  @BelongsTo({ name: "blob" }) activeStorageBlob: ActiveStorageBlob;

  @Attr() blobId: string;

  @Attr() description: string;

  @Attr() name: string;

  @Attr() position: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) filename: string;

  @Attr({ persist: false }) url: string;

  blobAttributes(): AttachmentBlobAttributes {
    const url = attachmentUrl(this.activeStorageBlob);

    return {
      video: this.activeStorageBlob.video,
      url,
      proxyUrl: url.replace("/redirect/", "/proxy/"),
    };
  }
}

export default ActiveStorageAttachment;
