import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import InsightsQuery from "./InsightsQuery";

@Model()
class InsightsKeyTopic extends ApplicationRecord {
  static jsonapiType = "insights_key_topics";

  @BelongsTo() insightsQuery: InsightsQuery;

  @Attr() booleanQuery: string;

  @Attr() color: string;

  @Attr() label: string;
}

export default InsightsKeyTopic;
