import { HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import QualityControlPredictor from "./QualityControlPredictor";
import Version from "./Version";

@Model()
class QualityControlTrigger extends ApplicationRecord {
  static jsonapiType = "quality_control_trigger";

  @HasMany() versions: Version[];

  @HasMany() qualityControlPredictors: QualityControlPredictor[];
}

export default QualityControlTrigger;
