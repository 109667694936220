export default [
  {
    name: "manual-broadcast-uploads",
    path: "/manual-broadcast-uploads",
    component: () =>
      import("pages/manual_broadcast_uploads/ManualBroadcastUploadList.vue"),
    meta: {
      title: "Manual Broadcast Uploads",
    },
  },
  {
    name: "manual-broadcast-uploads-new",
    path: "/manual-broadcast-uploads/new",
    component: () =>
      import("pages/manual_broadcast_uploads/ManualBroadcastUpload.vue"),
    meta: {
      title: "New Manual Broadcast Upload",
    },
  },
];
