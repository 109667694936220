export default [
  {
    name: "organisation-report-filtered-specs",
    path: "/organisation-report-filtered-specs",
    props: (route) => ({
      view: "filtered-specs",
      ...route.params,
      ...route.query,
    }),
    component: () => import("pages/OrganisationReports.vue"),
    meta: {
      accessLevel: "der",
      title: "Organisation Reports",
    },
  },
  {
    name: "organisation-report-filtered-spec-new",
    path: "/organisation-report-filtered-specs/new",
    component: () => import("pages/OrganisationReportFilteredSpecEdit.vue"),
    meta: {
      accessLevel: "der",
    },
  },
  {
    name: "organisation-report-filtered-spec-edit",
    path: "/organisation-report-filtered-specs/:filteredSpecId/edit",
    props: true,
    component: () => import("pages/OrganisationReportFilteredSpecEdit.vue"),
    meta: {
      accessLevel: "der",
    },
  },
];
