import { Attr, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Role from "./Role";

@Model()
class PermissionsRole extends ApplicationRecord {
  static jsonapiType = "permissions_roles";

  @HasOne() role: Role;

  @Attr() permission: string;

  @Attr() permissionId: number;
}

export default PermissionsRole;
