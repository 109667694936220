import { Attr, BelongsTo, Model } from "spraypaint";

import SourceRecord from "shared/resources/SourceRecord";

import Category from "./Category";
import CopyrightAgency from "./CopyrightAgency";
import Location from "./Location";
import Outlet from "./Outlet";

enum Frequency {
  "daily" = 0,
  "multiple_times_per_week" = 1,
  "weekly" = 2,
  "multiple_times_per_month" = 3,
  "monthly" = 4,
  "quarterly" = 5,
  "bimonthly" = 6,
  "yearly" = 7,
}

enum Origin {
  "mediatrack" = 0,
  "physical" = 1,
  "publisher" = 2,
  "cision" = 3,
}

@Model()
class Magazine extends SourceRecord {
  static jsonapiType = "magazines";

  @BelongsTo() copyrightAgency: CopyrightAgency;

  @BelongsTo() location: Location;

  @BelongsTo() sourceGroup: SourceRecord;

  @BelongsTo() category: Category;

  @BelongsTo() outlet: Outlet;

  @Attr() name: string;

  @Attr() label: string;

  @Attr({ persist: false }) medium: string;

  @Attr() locationId: number;

  @Attr() timeZone: string;

  @Attr() categoryId: number;

  @Attr() satirical: boolean;

  @Attr() logoUrl: string;

  @Attr() tier: number;

  @Attr() audience: number;

  @Attr() sourceGroupId: number;

  @Attr() frequency: Frequency;

  @Attr() origin: Origin;

  @Attr({ persist: false }) lastArticleAt: string;

  @Attr() subscriptionExpiry: string;

  @Attr() notes: string;

  @Attr() visible: boolean;

  @Attr() filenamePattern: string;

  @Attr() aliases: string[];

  @Attr() relatedWebsiteId: number;

  @Attr() archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default Magazine;
