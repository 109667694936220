import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class SocialEngagePostIntegrationEngagement extends ApplicationRecord {
  static jsonapiType = "social_engage_post_integration_engagements";

  @Attr({ persist: false }) likes: number;

  @Attr({ persist: false }) comments: number;

  @Attr({ persist: false }) shares: number;

  @Attr({ persist: false }) performance: number;

  @Attr({ persist: false }) reach: number;

  @Attr({ persist: false }) impressions: number;

  @Attr({ persist: false }) metadata: Record<string, any>;
}

export default SocialEngagePostIntegrationEngagement;
