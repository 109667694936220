export default {
  findFeatureId: (state) => (featureName) =>
    Number(
      state.featureTeasers.find(
        (featureTeaser) => featureTeaser.featureName === featureName
      ).id
    ),
  findFeature: (state) => (featureName) =>
    state.featureTeasers.find(
      (featureTeaser) => featureTeaser.featureName === featureName
    ),
};
