import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "broadcast-recorder-list",
    path: "/broadcast-recorders",
    component: () => import("pages/BroadcastRecorderList.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Broadcast Recorders",
    },
  },
  {
    name: "broadcast-recorder-new",
    path: "/broadcast-recorders/new",
    component: () => import("pages/BroadcastRecorderEdit.vue"),
    meta: {
      title: "New Broadcast Recorder",
    },
  },
  {
    name: "broadcast-recorder-edit",
    path: "/broadcast-recorders/:id/edit",
    component: () => import("pages/BroadcastRecorderEdit.vue"),
    props: true,
    meta: {
      title: "Edit Broadcast Recorder",
    },
  },
];
