import { storeToRefs } from "pinia";
import { computed } from "vue";

import { ErrorDialog } from "shared/boot/alert";
import { getLocaleText } from "shared/boot/i18n";
import features from "shared/services/features";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

import useProductRouting from "./useProductRouting";

export default function useTeamPicker(route = null) {
  const userStore = useUserStore();

  const {
    currentUser,
    preferences,
    sortedUserOrganisationTeams,
    sortedAllOrganisationTeams,
  } = storeToRefs(userStore);

  const { updateUserPreferences, isSuperRole } = userStore;

  const globalStore = useGlobalStore();
  const { routerViewKey } = storeToRefs(globalStore);
  const { setRouterViewKey } = globalStore;

  const { routeProductName } = useProductRouting();

  const currentRouteProductMenu = computed(() => routeProductName(route));

  const selectedTeamPreference = computed(
    () => preferences.value[`selected_team_${currentRouteProductMenu.value}`]
  );

  const isUserRoleSuper = computed(() =>
    isSuperRole(currentRouteProductMenu.value)
  );

  const organisationTeamOptions = computed(() => {
    if (features.has("has_team_workspaces")) {
      return isUserRoleSuper.value
        ? sortedAllOrganisationTeams.value
        : sortedUserOrganisationTeams.value;
    }

    return [];
  });

  const showTeamPicker = computed(() =>
    Boolean(organisationTeamOptions.value.length > 1)
  );

  const isTeamOptionsAvailable = computed(() =>
    Boolean(organisationTeamOptions.value.length)
  );

  const defaultTeamId = computed(() =>
    isTeamOptionsAvailable.value
      ? currentUser.value.primary_organisation_team_id ||
        organisationTeamOptions.value[0]?.id
      : null
  );

  async function updateSelectedTeamPreference(teamId) {
    try {
      if (routerViewKey.value !== "") {
        setRouterViewKey(teamId);
      }

      await updateUserPreferences({
        id: currentUser.value.id,
        attribute: `selected_team_${currentRouteProductMenu.value}`,
        value: teamId,
      });
    } catch (error) {
      ErrorDialog(
        getLocaleText("team_picker.errors.updating_team_preference"),
        error
      );
    }
  }

  function updateTeamPreferenceMismatch(organisationTeamId) {
    if (
      isTeamOptionsAvailable.value &&
      selectedTeamPreference.value !== organisationTeamId
    ) {
      updateSelectedTeamPreference(organisationTeamId);
    }
  }

  function organisationTeamFromId(id) {
    return organisationTeamOptions.value.find(
      (organisationTeam) => organisationTeam.id === id
    );
  }

  const organisationTeamFromPreference = computed(() =>
    organisationTeamFromId(selectedTeamPreference.value)
  );

  function teamSelectorFilterParams(value) {
    if (!value) {
      return organisationTeamOptions.value
        .map((team) => team.id)
        .concat(["null"]);
    }

    return value;
  }

  return {
    selectedTeamPreference,
    organisationTeamOptions,
    updateSelectedTeamPreference,
    organisationTeamFromId,
    organisationTeamFromPreference,
    defaultTeamId,
    showTeamPicker,
    updateTeamPreferenceMismatch,
    isUserRoleSuper,
    teamSelectorFilterParams,
  };
}
