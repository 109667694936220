import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import InvoiceLineItem from "./InvoiceLineItem";
import Organisation from "./Organisation";
import type { InvoiceLedger, InvoiceStatus } from "./types";
import User from "./User";

type InvoiceType = "contract" | "usage";

@Model()
class Invoice extends ApplicationRecord {
  static jsonapiType = "invoices";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() reviewer: User;

  @HasMany() invoiceLineItems: InvoiceLineItem[];

  @Attr() invoiceType: InvoiceType;

  @Attr({ persist: false }) from: string;

  @Attr({ persist: false }) to: string;

  @Attr() status: InvoiceStatus;

  @Attr() ledger: InvoiceLedger;

  @Attr() signedAttachmentUrl: string;

  @Attr() writeOffReason: string;

  @Attr() notes: string;

  @Attr({ persist: false }) totalCost: number;

  @Attr({ persist: false }) approvedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() invoiceDate: string;

  reviewerName() {
    if (!this.reviewer) return "";

    return this.reviewer.name;
  }
}

export default Invoice;
