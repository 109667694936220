export default [
  {
    name: "organisation-insights-plus",
    path: "/insights-plus/:organisationId/",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/OrganisationInsights.vue"),
    meta: {
      title: "Organisation Insights Plus",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-query-add",
    path: "/insights-plus/:organisationId/insights-query/new",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/EditInsightsQuery.vue"),
    meta: {
      title: "Create Query",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-query-edit",
    path: "/insights-plus/:organisationId/insights-query/:queryId/edit",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/EditInsightsQuery.vue"),
    meta: {
      title: "Edit Query",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-report-add",
    path: "/insights-plus/:organisationId/insights-report/new",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/EditInsightsReport.vue"),
    meta: {
      title: "Create Insights Plus Report",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-report-edit",
    path: "/insights-plus/:organisationId/insights-report/:reportId/edit",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/EditInsightsReport.vue"),
    meta: {
      title: "Edit Insights Plus Report",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-report",
    path: "/insights-plus/:organisationId/insights-report/:reportId/queries",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/InsightsReportPage.vue"),
    meta: {
      title: "View Insights Plus Report",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-sentiment-rating",
    path: "/insights-plus/:organisationId/insights-report/:reportId/sentiment-rating/:queryId",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/EditInsightsSentimentAnalysis.vue"),
    meta: {
      title: "Insights Plus Sentiment Rating",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-group-add",
    path: "/insights-plus/:organisationId/insights-report-group/new",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/EditInsightsReportGroup.vue"),
    meta: {
      title: "Create Insights Plus Report Group",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-group-edit",
    path: "/insights-plus/:organisationId/insights-report-group/:groupId/edit",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/EditInsightsReportGroup.vue"),
    meta: {
      title: "Edit Insights Plus Report Group",
      accessLevel: "media_analyst",
    },
  },
  {
    name: "insights-plus-report-analyse",
    path: "/insights-plus/:organisationId/insights-report/:reportId/analyse",
    props: (route) => ({
      ...route.params,
      ...route.query,
      reportType: "insights_plus",
    }),
    component: () => import("pages/insights/AnalyseInsightsReport.vue"),
    meta: {
      title: "Analyse Insights Plus Report",
      accessLevel: "media_analyst",
    },
  },
];
