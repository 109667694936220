export default [
  // Source Groups
  {
    name: "source-groups",
    path: "/source_groups",
    component: () => import("pages/sourceGroups/SourceGroupsList.vue"),
    meta: {
      title: "Source Groups",
    },
  },
  {
    name: "source-groups-add",
    path: "/source_groups/new",
    component: () => import("pages/sourceGroups/SourceGroupsView.vue"),
    meta: {
      title: "New Source Group",
    },
  },
  {
    name: "source-groups-edit",
    path: "/source_groups/:sourceGroupId/edit",
    props: true,
    component: () => import("pages/sourceGroups/SourceGroupsView.vue"),
    meta: {
      title: "Edit Source Group",
    },
  },
];
