import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class SocialEngagePostPerformanceReport extends ApplicationRecord {
  static jsonapiType = "social_engage_post_performance_reports";

  @Attr() userId: number;

  @Attr() after: string;

  @Attr() before: string;

  @Attr() options: Record<string, any>;

  @Attr() sortBy: string;

  @Attr() sortOrder: string;

  @Attr() status: string;

  @Attr() htmlUrl: string;

  @Attr() pdfUrl: string;

  @Attr() csvUrl: string;

  @Attr() organisationTeamId: number;

  @Attr() language: string;

  @Attr() csv: boolean;

  @Attr() pdf: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default SocialEngagePostPerformanceReport;
