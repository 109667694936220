import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import QualityControlTrigger from "./QualityControlTrigger";

@Model()
class QualityControlPredictor extends ApplicationRecord {
  static jsonapiType = "quality_control_predictors";

  @BelongsTo() qualityControlTrigger: QualityControlTrigger;

  @Attr() qualityControlTriggerId: number;

  @Attr() medium: string;

  @Attr() enabled: boolean;

  @Attr() approveThreshold: number;

  @Attr() rejectThreshold: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default QualityControlPredictor;
