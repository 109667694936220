import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class ReportFile extends ApplicationRecord {
  static jsonapiType = "report_files";

  @Attr() fileType: string;

  @Attr() identifier: string;

  @Attr() name: string;

  @Attr() url: string;

  @Attr({ persist: false }) createdAt: string;
}

export default ReportFile;
