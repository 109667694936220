import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import User from "./User";
import UserShiftSpec from "./UserShiftSpec";

@Model()
class UserShift extends ApplicationRecord {
  static jsonapiType = "user_shifts";

  @BelongsTo() user: User;

  @HasMany() specifications: UserShiftSpec[];

  @Attr() rosterId: number;

  @Attr() externalId: string;

  @Attr() startTime: number;

  @Attr() endTime: number;
}

export default UserShift;
