import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

export const useStreamGroupsStore = defineStore("streamGroups", {
  state,
  getters,
  actions,
});

export default useStreamGroupsStore;
