import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import { getLocaleText } from "shared/boot/i18n";
import { ApplicationRecord } from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import Organisation from "./Organisation";
import OutreachActivity from "./OutreachActivity";
import OutreachCommunicationContact from "./OutreachCommunicationContact";
import OutreachCommunicationUser from "./OutreachCommunicationUser";
import Tag from "./Tag";
import User from "./User";

enum Status {
  open = "open",
  complete = "complete",
  declined = "declined",
  cancelled = "cancelled",
}

export const getOutreachCommunicationStatuses = () => [
  {
    label: getLocaleText("resources.outreach_communication.statuses.open"),
    value: Status.open,
    backgroundColor: "#D6E4FF",
    color: "#096DD7",
  },
  {
    label: getLocaleText("resources.outreach_communication.statuses.complete"),
    value: Status.complete,
    backgroundColor: "#C5F7D0",
    color: "#4BA05D",
  },
  {
    label: getLocaleText("resources.outreach_communication.statuses.declined"),
    value: Status.declined,
    backgroundColor: "#FFCCC7",
    color: "#CD1323",
  },
  {
    label: getLocaleText("resources.outreach_communication.statuses.cancelled"),
    value: Status.cancelled,
    backgroundColor: "#FFCCC7",
    color: "#CD1323",
  },
];

enum CommunicationType {
  call = "call",
  email = "email",
  meeting = "meeting",
  interview = "interview",
  external_media_release = "external_media_release",
  event = "event",
  other = "other",
}

export const getOutreachCommunicationTypes = () => [
  {
    label: getLocaleText("resources.outreach_communication.types.call"),
    value: CommunicationType.call,
    color: "#FFAE6B",
  },
  {
    label: getLocaleText("resources.outreach_communication.types.email"),
    value: CommunicationType.email,
    color: "#7DED95",
  },
  {
    label: getLocaleText("resources.outreach_communication.types.meeting"),
    value: CommunicationType.meeting,
    color: "#597EF7",
  },
  {
    label: getLocaleText("resources.outreach_communication.types.interview"),
    value: CommunicationType.interview,
    color: "#B37FEB",
  },
  {
    label: getLocaleText(
      "resources.outreach_communication.types.external_media_release"
    ),
    value: CommunicationType.external_media_release,
    color: "#13C3C3",
  },
  {
    label: getLocaleText("resources.outreach_communication.types.event"),
    value: CommunicationType.event,
    color: "#6BC1FF",
  },
  {
    label: getLocaleText("resources.outreach_communication.types.other"),
    value: CommunicationType.other,
    color: "#FF85C0",
  },
];

@Model()
class OutreachCommunication extends ApplicationRecord {
  static jsonapiType = "outreach_communications";

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @HasMany({ name: "taggable" }) tags: Tag[];

  @HasMany() activeStorageAttachments: ActiveStorageAttachment[];

  @HasMany()
  outreachCommunicationUsers: OutreachCommunicationUser[];

  @HasMany()
  outreachCommunicationContacts: OutreachCommunicationContact[];

  @HasOne({ name: "activity" }) outreachActivity: OutreachActivity;

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() title: string;

  @Attr() notes: string;

  @Attr() private: boolean;

  @Attr() communicationType: CommunicationType;

  @Attr() status: Status;

  @Attr() sentAt: string;

  @Attr({ persist: false }) outreachCommunicationContactsCount: number;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default OutreachCommunication;
