export default [
  {
    name: "distribution-list-add",
    path: "/organisations/:organisationId/distribution-lists/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () =>
      import("pages/distribution_lists/DistributionListEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Create Distribution List",
    },
  },
  {
    name: "distribution-list-edit",
    path: "/organisations/:organisationId/distribution-lists/:distributionListId/edit",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () =>
      import("pages/distribution_lists/DistributionListEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Distribution List",
    },
  },
];
