import { defineStore } from "pinia";

import actions from "./actions";
import state from "./state";

export const useTranscriptRequestsStore = defineStore("transcriptRequests", {
  state,
  actions,
});

export default useTranscriptRequestsStore;
