import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import OrganisationTeam from "./OrganisationTeam";
import User from "./User";

@Model()
class UsersOrganisationTeam extends ApplicationRecord {
  static jsonapiType = "users_organisation_teams";

  @BelongsTo() user: User;

  @BelongsTo() organisationTeam: OrganisationTeam;

  @Attr() userId: number;

  @Attr() organisationTeamId: number;

  @Attr() primary: boolean;
}

export default UsersOrganisationTeam;
