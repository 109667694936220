import { Attr, HasMany, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import Organisation from "./Organisation";
import OrganisationReportSpec from "./OrganisationReportSpec";
import Stream from "./Stream";
import User from "./User";

@Model()
class OrganisationReportComment extends ApplicationRecord {
  static jsonapiType = "organisation_report_comments";

  @HasMany() attachments: ActiveStorageAttachment[];

  // eslint-disable-next-line no-use-before-define
  @HasMany() subComments: OrganisationReportComment[];

  @HasOne() accountManager: User;

  @HasOne() approver: User;

  @HasOne() assignee: User;

  @HasOne() author: User;

  @HasOne() organisation: Organisation;

  @HasOne() specification: OrganisationReportSpec;

  @HasOne() stream: Stream;

  @Attr() accountManagerId: number;

  @Attr() approverId: number;

  @Attr() assignedTo: string;

  @Attr() assigneeId: number;

  @Attr() authorId: number;

  @Attr() category: string;

  @Attr() closed: boolean;

  @Attr() expiresAt: string;

  @Attr() mentionId: number;

  @Attr() mentionType: string;

  @Attr() organisationId: number;

  @Attr() parentId: number;

  @Attr() specificationId: number;

  @Attr() streamId: number;

  @Attr() text: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedat: string;
}

export default OrganisationReportComment;
