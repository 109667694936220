import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Forum from "./Forum";

@Model()
class ForumPost extends ApplicationRecord {
  static jsonapiType = "forum_posts";

  @BelongsTo() source: Forum;

  @Attr() forumId: number;

  @Attr() externalId: string;

  @Attr() title: string;

  @Attr() body: string;

  @Attr() publishedAt: string;

  @Attr() author: string;

  @Attr() url: string;

  @Attr() comment: boolean;

  @Attr() urlHash: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default ForumPost;
