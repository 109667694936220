import type { CurrentUser, Organisation } from "shared/stores/user/types";

export function getInitialCurrentUserState(): CurrentUser {
  return {
    access_level: "limited",
    id: 0,
    email: "",
    first_name: "",
    last_name: "",
    phone_number: null,
    phone_country_code: null,
    international_phone_number: null,
    time_zone: "",
    active: false,
    hidden: false,
    admin: false,
    welcome_sent_at: null,
    role: "",
    social_role: "",
    outreach_role: "",
    show_intercom: false,
    auto_refresh_streams: false,
    primary_organisation_team_id: 0,
    secondary_team_ids: [],
    two_factor_auth_carrier: null,
    two_factor_auth_enabled: false,
    enforce_two_factor: false,
    outreach_countries: [],
    outreach_country: [],
    permissions: [],
    social_permissions: [],
    outreach_permissions: [],
    notifications_enabled: false,
    push_to_team_active: false,
    organisation: {
      id: 0,
      name: "",
      logo_url: "",
      dashboard_date_range: "",
      region: "",
    },
    organisation_teams: [],
    organisation_brand: {
      name: "",
      logo_url: "",
      theme_color: "",
    },
    alerts_read_at: null,
    news_summary_read_at: null,
    front_page_summary_read_at: null,
    media_requests_seen_at: null,
    transcripts_read_at: null,
    language: "",
    locale: "",
    currency_code: "",
    user_hash: "",
    preferences: {},
    other_organisations: [],
    organisation_team_id: null,
  };
}

export function getInitialOrganisationState(): Organisation {
  return {
    id: 0,
    name: "",
    region: "",
    logo_url: "",
    theme_color: "",
    dashboard_date_range: "",
    max_date_range: 0,
    help_message_option: "",
    custom_help_message: "",
    service_level: "",
    aircall_option: "",
    pr_agency: false,
    global_customer_id: null,
    allowed_phone_country_codes: [],
    custom_help_center_contact: "",
    help_center_contact_option: "",
    support_contact_option: null,
    custom_support_contact: null,
    social_help_center_contact_option: "",
    social_support_contact_option: "",
    features: [],
    daily_report_range_offset: 0,
    daily_report_range_duration: 0,
    default_role_id: 0,
    outreach_unsubscribed_recipients: [],
    organisation_teams: [],
    organisation_brands: [],
    account_manager: {
      id: 0,
      name: "",
      email: "",
    },
    secondary_account_managers: [],
    active: false,
    stock_symbol: null,
  };
}
