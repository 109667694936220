import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class Category extends ApplicationRecord {
  static jsonapiType = "categories";

  @Attr() name: string;

  @Attr() social: boolean;
}

export default Category;
