export default [
  {
    name: "audience-files",
    path: "/audience-files/:fileType",
    props: true,
    component: () => import("pages/AudienceFileList.vue"),
    meta: {
      title: "Audience Files",
    },
  },
];
