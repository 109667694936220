import { Attr, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import OrganisationReportFilteredSpec from "./OrganisationReportFilteredSpec";

@Model()
class FilteredSpecStreams extends ApplicationRecord {
  static jsonapiType = "filtered_spec_streams";

  @HasOne()
  filteredSpec: OrganisationReportFilteredSpec;

  @Attr() filterSetId: number;

  @Attr() filteredSpecId: number;

  @Attr() streamId: number;
}

export default FilteredSpecStreams;
