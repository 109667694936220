import { Notify, Platform } from "quasar";

import { getLocaleText } from "shared/boot/i18n";

const position = Platform.is.capacitor ? "center" : "bottom";

export const notifyRemovalOfMentionFromBookmarkStream = (label) => {
  Notify.create({
    message: getLocaleText("helpers.action_notifications.removed_from", {
      label,
    }),
    timeout: 1300,
    color: "primary",
    position,
  });
};

export const notifyAdditionOfMentionToBookmarkStream = (label) => {
  Notify.create({
    message: getLocaleText("helpers.action_notifications.added_to", { label }),
    timeout: 1300,
    color: "primary",
    position,
  });
};
