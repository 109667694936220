import { defineStore } from "pinia";

import actions from "./actions";
import state from "./state";

export const useSideBarStore = defineStore("sideBar", {
  state,
  actions,
});

export default useSideBarStore;
