import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Journalist from "./Journalist";
import Magazine from "./Magazine";
import Newspaper from "./Newspaper";
import Podcast from "./Podcast";
import RadioStation from "./RadioStation";
import SourceGroup from "./SourceGroup";
import TvChannel from "./TvChannel";
import Website from "./Website";

@Model()
class JournalistPosition extends ApplicationRecord {
  static jsonapiType = "journalist_positions";

  @BelongsTo() source:
    | SourceGroup
    | Website
    | Newspaper
    | Magazine
    | RadioStation
    | TvChannel
    | Podcast;

  @BelongsTo() journalist: Journalist;

  @Attr() journalistId: number;

  @Attr() sourceType: string;

  @Attr() sourceId: number;

  @Attr() jobTitle: string;

  @Attr() medium: string;

  @Attr() section: string;

  @Attr() startYear: number;

  @Attr() endYear: number;

  @Attr() primary: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default JournalistPosition;
