export default [
  {
    name: "filter-set-add",
    path: "/organisations/:organisationId/filter_sets/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/filter_sets/FilterSetEditPage.vue"),
    meta: {
      title: "Create Filter Set",
    },
  },
  {
    name: "filter-set-edit",
    path: "/organisations/:organisationId/filter_sets/:filterSetId/edit",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/filter_sets/FilterSetEditPage.vue"),
    meta: {
      title: "Edit Filter Set",
    },
  },
];
