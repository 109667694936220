export default [
  {
    name: "login",
    path: "/sign-in",
    alias: "/login",
    component: () => import("pages/Login.vue"),
    meta: {
      title: "Login",
      accessLevel: "limited",
      freeAccess: true,
    },
    props: (route) => ({
      redirect: route.query.redirect,
    }),
  },
  {
    name: "logout",
    path: "/logout",
    redirect: { name: "login" },
  },
];
