import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import BriefSet from "./BriefSet";
import OrganisationTeam from "./OrganisationTeam";

@Model()
class BriefSetsOrganisationTeam extends ApplicationRecord {
  static jsonapiType = "brief_sets_organisation_teams";

  @BelongsTo() briefSet: BriefSet;

  @BelongsTo() organisationTeam: OrganisationTeam;

  @Attr() organisationTeamId: number;

  @Attr() briefSetId: number;
}

export default BriefSetsOrganisationTeam;
