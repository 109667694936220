import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class InsightsQualitativeTopic extends ApplicationRecord {
  static jsonapiType = "insights_qualitative_topics";

  @BelongsTo() organisation: Organisation;

  @Attr() name: string;

  @Attr() subTopics: string[];

  @Attr() organisationId: string;

  @Attr() archivedAt: string;
}

export default InsightsQualitativeTopic;
