import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import JournalistEnquiriesUser from "./JournalistEnquiriesUser";
import JournalistEnquiryEntry from "./JournalistEnquiryEntry";
import JournalistEnquiryRecipient from "./JournalistEnquiryRecipient";
import JournalistEnquiryTag from "./JournalistEnquiryTag";
import type { ErrorWithResponse } from "./types";
import User from "./User";

type Priority = "Low" | "Medium" | "High";

@Model()
class JournalistEnquiry extends ApplicationRecord {
  static jsonapiType = "journalist_enquiries";

  @BelongsTo() user: User;

  @HasMany() journalistEnquiriesUsers: JournalistEnquiriesUser[];

  @HasMany() journalistEnquiryRecipients: JournalistEnquiryRecipient[];

  @HasMany() journalistEnquiryEntries: JournalistEnquiryEntry[];

  @HasMany() journalistEnquiryTags: JournalistEnquiryTag[];

  @Attr() title: string;

  @Attr() description: string;

  @Attr() source: string;

  @Attr() status: string;

  @Attr() priority: Priority;

  @Attr() endDate: string;

  @Attr() organisationId: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr() userId: number;
}

JournalistEnquiry.afterFetch = async (
  response,
  json: JSON & { message?: string }
) => {
  if (response.status === 401 && json.message === "sudo access required") {
    const error: ErrorWithResponse = new Error("sudo access required");
    error.response = Object.assign(response, { data: json });

    throw error;
  } else {
    ApplicationRecord.middlewareStack.afterFetch(response, json);
  }
};

export default JournalistEnquiry;
