import { escapeRegExp, uniq } from "lodash-es";

import { stockAliases } from "shared/constants";

export function humanize(value) {
  if (!value) {
    return value;
  }

  return value.replace(/[-_]/g, " ");
}

export function pluralize(value, ...args) {
  return args.length > 1
    ? args[value % (10 - 1)] || args[args.length - 1]
    : args[0] + (value === 1 ? "" : "s");
}

export function capitalize(value) {
  if (!value) return value;

  return value.replace(/\b\w/g, (string) => string.toUpperCase());
}

export function capitalizeName(value) {
  if (!value) return value;

  // Short names are most likely abbreviations like AAP or ABC
  if (value.length < 5) return value;

  // Names containing more than 1 period are likely intentional
  const periodMatches = value.match(/\./g);
  if (periodMatches && periodMatches.length > 1) return value;

  if (value === value.toLowerCase() || value === value.toUpperCase()) {
    return capitalize(value.toLowerCase());
  }

  return value;
}

export function parseDocumentId(value) {
  // Split any length of underscores by its last underscore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
  const [type, _, id] = value.split(/(_(?=[^_]*$))/);

  return { type, id };
}

export function upperCase(value) {
  if (!value) {
    return value;
  }

  return value.toUpperCase();
}

export function lowerCase(value) {
  if (!value) {
    return value;
  }

  return value.toLowerCase();
}

export function constantize(value) {
  if (!value) {
    return value;
  }

  return value
    .split("_")
    .map((item) => [item.charAt(0).toUpperCase(), item.substring(1)].join(""))
    .join("");
}

export function singularize(value) {
  return value.slice(0, -1);
}

export function nl2br(value, maxNewLines = null) {
  if (!value) return null;

  let output = value;

  if (maxNewLines) {
    const newLineRegex = new RegExp(
      `((\r\n|\n\r|\r|\n){${maxNewLines},})`,
      "g"
    );

    output = output.replace(newLineRegex, "\n".repeat(maxNewLines));
  }

  return output.replace(/(\r\n|\n\r|\r|\n)/g, "<br /><br />");
}

export function prettyType(value) {
  switch (value) {
    case "article":
    case "online":
      return "Online";
    case "paper_article":
      return "Print";
    case "magazine_article":
    case "magazine":
      return "Magazine";
    case "podcast_episode":
    case "podcast":
      return "Podcast";
    case "caption":
    case "tv_caption":
    case "tv_super":
    case "tv_logo_appearance":
    case "tv":
      return "TV";
    case "radio_clip":
    case "radio_article":
    case "radio":
      return "Radio";
    case "tweet":
    case "facebook_post":
    case "youtube_video":
    case "instagram_post":
    case "reddit_post":
    case "reddit_post_comment":
    case "blog_post":
    case "forum_post":
    case "social":
      return "Social";
    case "tv_segment":
    case "tv_media_request":
      return "TvSegment";
    case "radio_segment":
    case "radio_media_request":
      return "RadioSegment";
    case "transcript_request":
      return "TranscriptRequest";
    case "journalist":
      return "Journalist";
    case "external_item":
      return "ExternalItem";
    default:
      return "";
  }
}

export function cleanStockSymbol(symbol) {
  if (!symbol) return "";

  const regexString = Object.keys(stockAliases)
    .map((alias) => alias.replace("^", "\\^"))
    .join("|");

  const aliasRegex = new RegExp(`^(${regexString})$`);

  return symbol
    .replace(/\.\w+$/, "")
    .replace(aliasRegex, (_match, group) => stockAliases[group]);
}

export function highlight(text, keywords) {
  if (!keywords || !keywords.length) return text;

  // filter out empty keywords, sort longest first
  const filteredKeywords = keywords
    .filter((keyword) => Boolean(keyword))
    .sort((keywordA, keywordB) => keywordB.length - keywordA.length)
    .map((keyword) => escapeRegExp(keyword))
    .join("|");

  // don't highlight if filtered keywords is empty
  if (!filteredKeywords) return text;

  const regexp = new RegExp(`\\b(${filteredKeywords})\\b`, "gi");

  return text.replace(regexp, "<em>$1</em>");
}

export function truncateWords(text, length) {
  if (text.indexOf(" ") === -1) return `${text.substring(0, 35)}...`;

  const words = text.split(" ");

  return words.length > length
    ? `${words.slice(0, length).join(" ")}...`
    : text;
}

export function extractKeywords(text) {
  if (!text) {
    return [];
  }

  const fieldsToKeepForHighlight = [
    "text.case_sensitive",
    "text",
    "title",
    "tv_super",
  ];

  const fieldsToKeepRegex = new RegExp(
    `(${fieldsToKeepForHighlight.join("|")}):`,
    "ig"
  );

  let keywords = text
    .replace(/\s+/g, " ") // clean up whitespace
    .replace(/NOT \(?[a-zA-Z0-9:$ "()]*"*\)/g, "") // nested NOT (keyword/"keyword"/AND/OR)
    .replace(/NOT (\(.+\)|"[\w ]+"|\w+)/g, "") // NOT "keyword"/keyword
    .replace(fieldsToKeepRegex, "") // remove field names we want to keep keywords for
    .replace(
      /[a-zA-Z]+?\.?[a-zA-Z_]*?: ?("?(\d+|[a-zA-Z0-9]+_?[a-zA-Z_0-9]*)"?|\(.+?\))/g,
      ""
    ) // remove non-highlight fields
    .replace(/\(|\)|AND|OR/g, "") // nested AND/OR
    .replace(/,(?!(\d))/, "") // comma separated not followed by digits
    .replace(/\(|\)|((NEAR|ONEAR|FIRST)\/[0-9]+)/g, "") // nested NEAR/ONEAR/FIRST operators
    .replace(
      /(-"[a-zA-Z0-9:$\- ]+"|-[a-zA-Z0-9:$-]+(?=(?:[^"]|"[^"]*")*$))/g,
      ""
    ) // remove keywords prefixed with "-" operator
    .replace(/\+/g, "") // remove "+" operator from keywords
    .replace(/\/\*(.|\n)*?\*\//g, "") // remove comments
    .match(/(?:")(.+?)(?=")|(?:[^"]|\s|^)(\b.+?)(?=\s|$)/g); // extract keywords

  keywords = [...keywords]
    .flat()
    .map((word) => word.replace(/"/g, "").trim()) // remove grouping quotes
    .filter(
      (word) =>
        // remaining keywords containing special characters used in query and all numerical keywords
        // or are in reference to media types
        word.length &&
        !word.match(
          /((\w+([:]+\w*))|^\d+$|caption|tv_super|article|radio_clip|tweet|paper_article)/i
        )
    );

  return uniq(keywords);
}

export function truncate(text, maxLength = 65) {
  let truncatedText = text.substr(0, maxLength);

  if (text.length > maxLength) {
    if (text.indexOf(" ") === -1) return truncatedText.concat("...");

    // re-trim if we are in the middle of a word
    truncatedText = truncatedText.substr(
      0,
      Math.min(truncatedText.length, truncatedText.lastIndexOf(" "))
    );

    return truncatedText.concat("...");
  }

  return text;
}

export function isValidHTMLContent(text) {
  return (
    typeof text === "string" &&
    Boolean(text.trim().length) &&
    text.trim() !== "<p></p>"
  );
}

export function countWords(text) {
  return text.split(/\s+/)?.filter(Boolean).length ?? 0;
}
