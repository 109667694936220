import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Journalist from "./Journalist";
import OutreachRecipientList from "./OutreachRecipientList";

@Model()
class OutreachRecipientListRecipient extends ApplicationRecord {
  static jsonapiType = "outreach_recipient_list_recipients";

  @BelongsTo() journalist: Journalist;

  @BelongsTo() outreachRecipientList: OutreachRecipientList;

  @Attr() name: string;

  @Attr() email: string;

  @Attr() journalistId: number;

  @Attr() outreachRecipientListId: number;
}

export default OutreachRecipientListRecipient;
