import { getLocaleText } from "shared/boot/i18n";
import { streamTypes } from "shared/constants";
import { mediumColors } from "shared/helpers/colors";
import features from "shared/services/features";
import { Stream } from "shared/types";
import { MentionType, SocialMentionType } from "shared/types/mentions";

export type MediumName =
  | "Online"
  | "Print"
  | "Magazine"
  | "TV"
  | "Radio"
  | "Podcast"
  | "Social";

export interface RelatedType {
  label: string;
  appLabel: string;
  field: MentionType;
  featureFlag: string;
  featureId?: number;
  checked: boolean;
  hidden?: boolean;
  color?: string;
  icon?: string;
}

export enum SocialPlatform {
  TWITTER = "twitter",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  YOUTUBE = "youtube",
  REDDIT = "reddit",
  BLOG = "blog",
  FORUM = "forum",
}

export enum MediumField {
  ONLINE = "online",
  PRINT = "print",
  MAGAZINE = "magazine",
  PODCAST = "podcast",
  TV = "tv",
  RADIO = "radio",
  SOCIAL = "social",
}

export interface MediumPlatform {
  label: string;
  platform: SocialPlatform;
  color: string;
  fields: SocialMentionType[];
  featureFlag: string;
  icon: string;
  checked: boolean;
  widgets?: string[];
}

export interface BaseMedium {
  medium: MediumName;
  label: string;
  field: MediumField;
  icon: string;
  color: string;
  relatedFields: string[];
  relatedLabels: string[];
  relatedLabel?: string;
  relatedTypes: RelatedType[];
  relatedCamelCaseFields: string[];
  relatedCamelCaseField?: string;
  sourceLabel: string;
  classField: string;
  resource: string;
  featureFlag: string;
  displayExcerptsFeature: string;
  triggerAlert: boolean;
  hasNotification: boolean;
  filterEnabled: boolean;
  categoryLocationFiltersEnabled: boolean;
  createActivity?: boolean;
  viewFeatureFlag?: string;
  featureAllowanceFlag?: string;
  viewWarningFeatureFlag?: string;
  viewPopupShown?: string;
  viewPopupAllowOverspend?: boolean;
  featureName?: string;
}

export interface OnlineMedium extends BaseMedium {
  field: MediumField.ONLINE;
}

export interface PrintMedium extends BaseMedium {
  field: MediumField.PRINT;
}

export interface MagazineMedium extends BaseMedium {
  field: MediumField.MAGAZINE;
}

export interface PodcastMedium extends BaseMedium {
  field: MediumField.PODCAST;
}

export interface TvMedium extends BaseMedium {
  field: MediumField.TV;
}

export interface RadioMedium extends BaseMedium {
  field: MediumField.RADIO;
}

export interface SocialMedium
  extends Omit<BaseMedium, "resource" | "classField"> {
  field: MediumField.SOCIAL;
  platforms: MediumPlatform[];
}

export interface InfluencerSocialMedium
  extends Omit<BaseMedium, "resource" | "classField"> {
  field: MediumField.SOCIAL;
}

export type ExternalSource = Omit<
  BaseMedium,
  "medium" | "field" | "displayExcerptsFeature"
>;

export type Medium =
  | OnlineMedium
  | PrintMedium
  | MagazineMedium
  | PodcastMedium
  | TvMedium
  | RadioMedium
  | SocialMedium
  | InfluencerSocialMedium;

export const online: OnlineMedium = {
  medium: "Online",
  label: "Online",
  field: MediumField.ONLINE,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/online.svg`,
  color: mediumColors.online,
  relatedFields: [MentionType.article],
  relatedLabels: ["Online Article"],
  relatedTypes: [
    {
      label: "Online Article",
      appLabel: "Online Articles",
      field: MentionType.article,
      featureFlag: "read_online_article",
      featureId: 20,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["Article"],
  sourceLabel: "Publisher",
  classField: "website",
  resource: "websites",
  featureFlag: "read_online_article",
  displayExcerptsFeature: "read_online_article",
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const print: PrintMedium = {
  medium: "Print",
  label: "Print",
  field: MediumField.PRINT,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/print.svg`,
  color: mediumColors.print,
  relatedFields: [MentionType.paper_article],
  relatedLabels: ["Print Article"],
  relatedTypes: [
    {
      label: "Paper Article",
      appLabel: "Print Articles",
      field: MentionType.paper_article,
      featureFlag: "view_paper_articles",
      featureId: 18,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["PaperArticle"],
  sourceLabel: "Publication",
  classField: "newspaper",
  resource: "newspapers",
  featureFlag: "view_paper_articles",
  displayExcerptsFeature: "display_print_excerpts",
  viewFeatureFlag: "view_paper_articles",
  featureAllowanceFlag: "view_print_articles",
  viewWarningFeatureFlag: "view_print_articles_warning",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "Print Items",
  createActivity: false,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const magazine: MagazineMedium = {
  medium: "Magazine",
  label: "Magazine",
  field: MediumField.MAGAZINE,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/magazine.svg`,
  color: mediumColors.magazine,
  relatedFields: [MentionType.magazine_article],
  relatedLabels: ["Magazine Article"],
  relatedTypes: [
    {
      label: "Magazine Article",
      appLabel: "Magazine Articles",
      field: MentionType.magazine_article,
      featureFlag: "view_magazine_articles",
      featureId: 33,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["MagazineArticle"],
  sourceLabel: "Magazine",
  classField: "magazine",
  resource: "magazines",
  featureFlag: "view_magazine_articles",
  displayExcerptsFeature: "display_magazine_excerpts",
  viewFeatureFlag: "view_magazine_articles",
  featureAllowanceFlag: "view_print_articles",
  viewWarningFeatureFlag: "view_print_articles_warning",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "Magazine Items",
  createActivity: false,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const podcast: PodcastMedium = {
  medium: "Podcast",
  label: "Podcast",
  field: MediumField.PODCAST,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/podcast.svg`,
  color: mediumColors.podcast,
  relatedFields: [MentionType.podcast_episode],
  relatedLabels: ["Podcast Episode"],
  relatedTypes: [
    {
      label: "Podcast Episode",
      appLabel: "Podcast Episodes",
      field: MentionType.podcast_episode,
      featureFlag: "view_podcast_episode",
      featureId: 116,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["PodcastEpisode"],
  sourceLabel: "Podcast",
  classField: "podcast",
  resource: "podcasts",
  featureFlag: "podcast_mentions",
  displayExcerptsFeature: "podcast_mentions",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "Podcast Items",
  createActivity: false,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const tv: TvMedium = {
  medium: "TV",
  label: "TV",
  field: MediumField.TV,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/tv.svg`,
  color: mediumColors.tv,
  relatedFields: [
    MentionType.tv_caption,
    MentionType.tv_super,
    "tv_media_request",
    MentionType.tv_logo_appearance,
  ],
  relatedLabels: [
    "TV Caption",
    "TV Super",
    "TV Media Request",
    "Tv Logo Appearance",
  ],
  relatedTypes: [
    {
      label: "Caption",
      appLabel: "Spoken",
      field: MentionType.tv_caption,
      featureFlag: "view_tv_caption",
      checked: true,
    },
    {
      label: "Super",
      appLabel: "On-screen text",
      field: MentionType.tv_super,
      featureFlag: "view_tv_super",
      checked: false,
    },
    {
      label: "Logo Appearance",
      appLabel: "On-screen logos",
      field: MentionType.tv_logo_appearance,
      featureFlag: "view_tv_logo_appearance",
      checked: false,
    },
  ],
  relatedCamelCaseFields: [
    "TvCaption",
    "TvSuper",
    "TvSegment",
    "TvLogoAppearance",
  ],
  sourceLabel: "Channel",
  classField: "tv_channel",
  resource: "tv_channels",
  featureFlag: "tv_mentions",
  displayExcerptsFeature: "tv_mentions",
  viewFeatureFlag: "play_tv_clips",
  featureAllowanceFlag: "play_tv_clips",
  featureName: "TV Replay",
  createActivity: true,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const radio: RadioMedium = {
  medium: "Radio",
  label: "Radio",
  field: MediumField.RADIO,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/radio.svg`,
  color: mediumColors.radio,
  relatedFields: [MentionType.radio_clip, "radio_media_request"],
  relatedLabels: ["Radio Clip", "Radio Media Request"],
  relatedTypes: [
    {
      label: "Radio Clip",
      appLabel: "Radio Clip",
      field: MentionType.radio_clip,
      featureFlag: "view_radio_clip",
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["RadioClip", "RadioSegment"],
  sourceLabel: "Station",
  classField: "radio_station",
  resource: "radio_stations",
  displayExcerptsFeature: "radio_excerpts",
  featureFlag: "radio_mentions",
  viewFeatureFlag: "play_radio_clips",
  featureAllowanceFlag: "play_radio_clips",
  featureName: "Radio Replay",
  createActivity: true,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const influencerSocial: InfluencerSocialMedium = {
  medium: "Social",
  label: "Influencer Social",
  field: MediumField.SOCIAL,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/social.svg`,
  color: mediumColors.social,
  relatedFields: [
    MentionType.tweet,
    MentionType.facebook_post,
    MentionType.youtube_video,
    MentionType.instagram_post,
    MentionType.reddit_post,
    MentionType.reddit_post_comment,
    MentionType.blog_post,
    MentionType.forum_post,
  ],
  relatedLabels: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  relatedTypes: [
    {
      label: "Tweet",
      appLabel: "Twitter",
      color: "#77BDF1",
      field: MentionType.tweet,
      featureFlag: "view_tweet",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/twitter.svg`,
      checked: true,
    },
    {
      label: "Facebook Post",
      appLabel: "Facebook",
      color: "#61749D",
      field: MentionType.facebook_post,
      featureFlag: "view_facebook_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/facebook.svg`,
      checked: true,
    },
    {
      label: "Instagram Post",
      appLabel: "Instagram",
      color: "#E3787C",
      field: MentionType.instagram_post,
      featureFlag: "view_instagram_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/instagram.svg`,
      checked: true,
    },
    {
      label: "YouTube Video",
      appLabel: "Youtube",
      color: "#D54B3F",
      field: MentionType.youtube_video,
      featureFlag: "view_youtube_video",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/youtube.svg`,
      checked: true,
    },
    {
      label: "Reddit Post",
      appLabel: "Reddit Posts",
      color: "#FB7A4A",
      field: MentionType.reddit_post,
      featureFlag: "view_reddit_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/reddit.svg`,
      checked: true,
      hidden: true,
    },
    {
      label: "Reddit Post Comment",
      appLabel: "Reddit Comments",
      color: "#FB7A4A",
      field: MentionType.reddit_post_comment,
      featureFlag: "view_reddit_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/reddit.svg`,
      checked: true,
      hidden: true,
    },
    {
      label: "Blog Post",
      appLabel: "Blog Posts",
      color: "#F06A35",
      field: MentionType.blog_post,
      featureFlag: "view_blog_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/blog.svg`,
      checked: true,
      hidden: true,
    },
    {
      label: "Forum Post",
      appLabel: "Forum Posts",
      color: "#FFB649",
      field: MentionType.forum_post,
      featureFlag: "view_forum_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/forum.svg`,
      checked: true,
      hidden: true,
    },
  ],
  relatedCamelCaseFields: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  sourceLabel: "Source",
  featureFlag: "social_mentions",
  displayExcerptsFeature: "social_mentions",
  triggerAlert: false,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const social: SocialMedium = {
  medium: "Social",
  label: "Social",
  field: MediumField.SOCIAL,
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/social.svg`,
  color: mediumColors.social,
  relatedFields: [
    MentionType.tweet,
    MentionType.facebook_post,
    MentionType.youtube_video,
    MentionType.instagram_post,
    MentionType.reddit_post,
    MentionType.reddit_post_comment,
    MentionType.blog_post,
    MentionType.forum_post,
  ],
  relatedLabels: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  platforms: [
    {
      label: "X | Twitter",
      platform: SocialPlatform.TWITTER,
      color: "#77BDF1",
      fields: [SocialMentionType.tweet],
      featureFlag: "view_tweet",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/twitter.svg`,
      checked: true,
      widgets: ["key_twitter_influencers", "top_tweets"],
    },
    {
      label: "Facebook",
      platform: SocialPlatform.FACEBOOK,
      color: "#61749D",
      fields: [SocialMentionType.facebook_post],
      featureFlag: "view_facebook_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/facebook.svg`,
      checked: true,
      widgets: ["top_facebook_pages", "top_facebook_posts"],
    },
    {
      label: "Instagram",
      platform: SocialPlatform.INSTAGRAM,
      color: "#E3787C",
      fields: [SocialMentionType.instagram_post],
      featureFlag: "view_instagram_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/instagram.svg`,
      checked: true,
      widgets: ["key_instagram_influencers"],
    },
    {
      label: "YouTube",
      platform: SocialPlatform.YOUTUBE,
      color: "#D54B3F",
      fields: [SocialMentionType.youtube_video],
      featureFlag: "view_youtube_video",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/youtube.svg`,
      checked: true,
    },
    {
      label: "Reddit",
      platform: SocialPlatform.REDDIT,
      color: "#FB7A4A",
      fields: [
        SocialMentionType.reddit_post,
        SocialMentionType.reddit_post_comment,
      ],
      featureFlag: "view_reddit_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/reddit.svg`,
      checked: true,
    },
    {
      label: "Blogs",
      platform: SocialPlatform.BLOG,
      color: "#F06A35",
      fields: [SocialMentionType.blog_post],
      featureFlag: "view_blog_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/blog.svg`,
      checked: true,
    },
    {
      label: "Forums",
      platform: SocialPlatform.FORUM,
      color: "#FFB649",
      fields: [SocialMentionType.forum_post],
      featureFlag: "view_forum_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/forum.svg`,
      checked: true,
    },
  ],
  relatedTypes: [
    {
      label: "Tweet",
      appLabel: "Twitter",
      color: "#77BDF1",
      field: MentionType.tweet,
      featureFlag: "view_tweet",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/twitter.svg`,
      checked: true,
    },
    {
      label: "Facebook Post",
      appLabel: "Facebook",
      color: "#61749D",
      field: MentionType.facebook_post,
      featureFlag: "view_facebook_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/facebook.svg`,
      checked: true,
    },
    {
      label: "Instagram Post",
      appLabel: "Instagram",
      color: "#E3787C",
      field: MentionType.instagram_post,
      featureFlag: "view_instagram_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/instagram.svg`,
      checked: true,
    },
    {
      label: "YouTube Video",
      appLabel: "Youtube",
      color: "#D54B3F",
      field: MentionType.youtube_video,
      featureFlag: "view_youtube_video",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/youtube.svg`,
      checked: true,
    },
    {
      label: "Reddit Post",
      appLabel: "Reddit Posts",
      color: "#FB7A4A",
      field: MentionType.reddit_post,
      featureFlag: "view_reddit_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/reddit.svg`,
      checked: true,
      hidden: true,
    },
    {
      label: "Reddit Post Comment",
      appLabel: "Reddit Comments",
      color: "#FB7A4A",
      field: MentionType.reddit_post_comment,
      featureFlag: "view_reddit_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/reddit.svg`,
      checked: true,
      hidden: true,
    },
    {
      label: "Blog Post",
      appLabel: "Blog Posts",
      color: "#F06A35",
      field: MentionType.blog_post,
      featureFlag: "view_blog_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/blog.svg`,
      checked: true,
      hidden: true,
    },
    {
      label: "Forum Post",
      appLabel: "Forum Posts",
      color: "#FFB649",
      field: MentionType.forum_post,
      featureFlag: "view_forum_post",
      icon: `${import.meta.env.VITE_ASSETS_URL}/icons/forum.svg`,
      checked: true,
      hidden: true,
    },
  ],
  relatedCamelCaseFields: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  sourceLabel: "Source",
  featureFlag: "has_social_platform",
  displayExcerptsFeature: "has_social_platform",
  triggerAlert: false,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const externalItem: ExternalSource = {
  label: "External Item",
  icon: `${import.meta.env.VITE_ASSETS_URL}/icons/external-item.svg`,
  color: "#E5EAEF",
  relatedFields: [MentionType.external_item],
  relatedLabels: [MentionType.external_item],
  relatedTypes: [
    {
      label: "External Item",
      appLabel: "External Items",
      field: MentionType.external_item,
      featureFlag: "has_external_items",
      featureId: 132,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["ExternalItem"],
  sourceLabel: "External Item",
  classField: MentionType.external_item,
  resource: "external_items",
  featureFlag: "has_external_items",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "External Items",
  createActivity: false,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: false,
  categoryLocationFiltersEnabled: false,
};

const sources: Medium[] = [
  online,
  print,
  tv,
  radio,
  magazine,
  influencerSocial,
  podcast,
];

type MediumByClass = Exclude<Medium, InfluencerSocialMedium | SocialMedium>;

function isSourceWithClassField(source: Medium): source is MediumByClass {
  return "classField" in source;
}

const sourceByClass: {
  [key: string]: MediumByClass;
} = sources
  .filter((source) => isSourceWithClassField(source))
  .reduce(
    (lookup, source) => ({
      ...lookup,
      [(source as MediumByClass).classField]: source,
    }),
    {}
  );

const sourceByField: { [key: string]: Medium } = sources.reduce(
  (lookup, source) => ({
    ...lookup,
    [source.field]: source,
  }),
  {}
);

const sourceByMedium: { [key: string]: Medium } = sources.reduce(
  (lookup, source) => ({
    ...lookup,
    [source.medium]: source,
  }),
  {}
);

const sourceByRelatedFields: { [key: string]: Medium } = sources.reduce(
  (lookup, source) => {
    source.relatedFields.forEach((field) => {
      Object.assign(lookup, { [field]: source });
    });

    return lookup;
  },
  {}
);

function adminDefaultContentToggles(): { [key: string]: string[] } {
  const defaults: { [key: string]: string[] } = {};

  sources.forEach((source) => {
    defaults[`selected_${source.field}_content`] = source.relatedTypes.map(
      ({ field }) => field
    );
  });

  return defaults;
}

function adminDefaultCheckedContentToggles(): { [key: string]: string[] } {
  const defaults: { [key: string]: string[] } = {};

  sources.forEach((source) => {
    defaults[`selected_${source.field}_content`] = source.relatedTypes
      .filter(({ checked }) => checked)
      .map(({ field }) => field);
  });

  return defaults;
}

function defaultContentToggles(): { [key: string]: string[] } {
  const defaults: { [key: string]: string[] } = {};

  sources.forEach((source) => {
    if (features.has(source.featureFlag)) {
      defaults[`selected_${source.field}_content`] = source.relatedTypes
        .filter(
          (type) =>
            (!type.featureFlag || features.has(type.featureFlag)) &&
            !type.hidden
        )
        .map(({ field }) => field);
    }
  });

  return defaults;
}

function defaultSocialContentToggles(): { [key: string]: string[] } {
  const defaults: { [key: string]: string[] } = {};

  sources.forEach((source) => {
    if (features.has(source.featureFlag)) {
      defaults[`selected_${source.field}_content`] = [];
    }
  });

  if (features.has(social.featureFlag)) {
    defaults.selected_social_content = social.relatedTypes
      .filter((type) => !type.featureFlag || features.has(type.featureFlag))
      .map(({ field }) => field);
  }

  return defaults;
}

function defaultCheckedContentToggles(): { [key: string]: string[] } {
  const defaults: { [key: string]: string[] } = {};

  sources.forEach((source) => {
    if (features.has(source.featureFlag)) {
      defaults[`selected_${source.field}_content`] = source.relatedTypes
        .filter(
          (type) =>
            type.checked &&
            (!type.featureFlag || features.has(type.featureFlag)) &&
            !type.hidden
        )
        .map(({ field }) => field);
    }
  });

  return defaults;
}

function getLocalisedSourceLabels(localSources: Medium[]): Medium[] {
  return localSources.map((source) => {
    const { relatedTypes } = source;

    const localisedRelatedTypes = relatedTypes.map((relatedType) => ({
      ...relatedType,
      label: getLocaleText(
        `helpers.sources.${source.field}.related_type_${relatedType.field}_label`
      ),
      appLabel: getLocaleText(
        `helpers.sources.${source.field}.related_type_${relatedType.field}_app_label`
      ),
    }));

    return {
      ...source,
      label: getLocaleText(`helpers.sources.${source.field}.label`),
      relatedTypes: localisedRelatedTypes,
    };
  });
}

function getLocalisedPlatformLabels(
  platforms: MediumPlatform[]
): MediumPlatform[] {
  return platforms.map((platform) => ({
    ...platform,
    label: getLocaleText(
      `helpers.sources.platform_labels.${platform.platform}`
    ),
  }));
}

function getSources(): Medium[] {
  const localisedSources = getLocalisedSourceLabels(sources);

  const enabledSources = localisedSources.filter(
    (source) => source.filterEnabled
  );

  if (
    features.has("has_outreach_2") &&
    !features.hasAny(["has_media_platform", "has_social_platform"])
  ) {
    return enabledSources;
  }

  return localisedSources.filter((source) => features.has(source.featureFlag));
}

function getTypesForSources(selectedSources: Medium[]): string[] {
  const types = selectedSources.reduce<MentionType[]>(
    (acc, source) =>
      acc.concat(
        source.relatedTypes
          .filter((type) => features.has(type.featureFlag))
          .map(({ field }) => field)
      ),
    []
  );

  if (
    features.has("view_tv_logo_appearance") &&
    selectedSources.filter((source) => source.medium === "TV").length
  ) {
    types.push(MentionType.tv_logo_appearance);
  }

  return Array.from(new Set(types));
}

function getTypesFromSelectedContent(stream: Stream): string[] {
  const types = sources
    .reduce(
      (acc, source) =>
        acc.concat(stream[`selected_${source.field}_content`] as MentionType[]),
      [] as MentionType[]
    )
    .filter((type) => Boolean(type));

  return Array.from(new Set(types));
}

function getTypesForStreamFromSources(
  stream: Stream,
  selectedSources: Medium[]
): MentionType[] {
  const types = selectedSources
    .reduce((acc, source) => {
      const sourceTypes = stream[`${source.field}_content`]
        ? stream[`selected_${source.field}_content`]
        : [];

      return acc.concat(sourceTypes as MentionType[]);
    }, [] as MentionType[])
    .filter((type) => type?.length);

  return Array.from(new Set(types));
}

function getTypesForStream(stream: Stream): string[] {
  const isSocialStream =
    stream.type === streamTypes.socialStream || stream.social_bookmarks_stream;

  const allowedSources = isSocialStream ? [social] : getSources();

  return getTypesForStreamFromSources(stream, allowedSources);
}

function getTypesForMedia(media: string[] = []): MentionType[] {
  const searchTypes: MentionType[] = [];

  sources.forEach((source) => {
    if (media.includes(source.medium)) {
      const enabledRelatedTypes = source.relatedTypes
        .filter(
          ({ featureFlag, hidden }) =>
            (!featureFlag || features.has(featureFlag)) && !hidden
        )
        .map(({ field }) => field);

      searchTypes.push(...enabledRelatedTypes);
    }
  });

  return searchTypes;
}

function getMediaForStream(stream: Stream): MediumName[] {
  return getSources()
    .filter((source) => stream[`${source.field}_content`])
    .map((source) => source.medium);
}

function getMediaForInsightsQuery(insightsQuery: any): string[] {
  return getSources()
    .filter((source) => insightsQuery[`${source.field}Content`])
    .map((source) => source.medium);
}

function getSourcesForStream(stream: Stream): Medium[] {
  return getSources().filter(({ field }) => stream[`${field}_content`]);
}

function getSource(type: string): Medium {
  const source = { ...sourceByRelatedFields[type] };
  const relatedIndex = source.relatedFields.indexOf(type);
  source.relatedLabel = source.relatedLabels[relatedIndex];
  source.relatedCamelCaseField = source.relatedCamelCaseFields[relatedIndex];

  return source;
}

function getSourceFromGroup(field: string): Medium {
  return sourceByField[field];
}

function getAllRelatedFields(): string[] {
  return getSources().reduce<string[]>(
    (acc, value) => [...acc, ...value.relatedFields],
    []
  );
}

function getSourceByField(field: string): Medium {
  return sourceByField[field];
}

function getSourceByClass(classField: string): MediumByClass {
  return sourceByClass[classField];
}

function getSocialPlatforms(): MediumPlatform[] {
  const platforms = getLocalisedPlatformLabels(social.platforms);

  return platforms.filter(({ featureFlag }) => features.has(featureFlag));
}

function getEnabledSocialPlatformsForStream(stream: Stream): MediumPlatform[] {
  return getSocialPlatforms().filter((platform) =>
    Boolean(
      stream.selected_social_content?.some((value) =>
        platform.fields.includes(value)
      )
    )
  );
}

function sourceIcon(sourceType: string): string {
  switch (sourceType) {
    case MentionType.facebook_post:
      return "ion-logo-facebook";
    case MentionType.instagram_post:
      return "ion-logo-instagram";
    case MentionType.reddit_post:
    case MentionType.reddit_post_comment:
      return "ion-logo-reddit";
    case MentionType.youtube_video:
      return "ion-logo-youtube";
    case MentionType.tweet:
      return `img:${import.meta.env.VITE_ASSETS_URL}/icons/twitterx-light.svg`;
    case MentionType.blog_post:
      return `img:${import.meta.env.VITE_ASSETS_URL}/icons/blog.svg`;
    case MentionType.forum_post:
      return `img:${import.meta.env.VITE_ASSETS_URL}/icons/forum.svg`;
    default:
      return "ion-person";
  }
}

function socialIcon(socialType: SocialMentionType): string | undefined {
  return {
    [MentionType.facebook_post]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/facebook.svg`,
    [MentionType.instagram_post]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/instagram.svg`,
    [MentionType.reddit_post]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/reddit.svg`,
    [MentionType.reddit_post_comment]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/reddit.svg`,
    [MentionType.tweet]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/twitter.svg`,
    [MentionType.youtube_video]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/youtube.svg`,
    [MentionType.blog_post]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/blog.svg`,
    [MentionType.forum_post]: `img:${
      import.meta.env.VITE_ASSETS_URL
    }/icons/forum.svg`,
  }[socialType];
}

const externalItemIcon: string = `img:${
  import.meta.env.VITE_ASSETS_URL
}/icons/external-item.svg`;

function filterSocialPlatformsByTypes(types: string[] = []): MediumPlatform[] {
  const { platforms } = social;

  if (!types.length) {
    return platforms;
  }

  return platforms.filter((platform) =>
    platform.fields.some((field) => types.includes(field))
  );
}

function getMediaFromTypes(types: string[]): MediumName[] {
  return getSources()
    .filter(
      ({ relatedFields }) => types?.some((type) => relatedFields.includes(type))
    )
    .map(({ medium }) => medium);
}

export default sources;
export {
  adminDefaultCheckedContentToggles,
  adminDefaultContentToggles,
  defaultCheckedContentToggles,
  defaultContentToggles,
  defaultSocialContentToggles,
  externalItemIcon,
  filterSocialPlatformsByTypes,
  getAllRelatedFields,
  getEnabledSocialPlatformsForStream,
  getMediaForInsightsQuery,
  getMediaForStream,
  getMediaFromTypes,
  getSocialPlatforms,
  getSource,
  getSourceByClass,
  getSourceByField,
  getSourceFromGroup,
  getSources,
  getSourcesForStream,
  getTypesForMedia,
  getTypesForSources,
  getTypesForStream,
  getTypesForStreamFromSources,
  getTypesFromSelectedContent,
  socialIcon,
  sourceByMedium,
  sourceIcon,
  sources,
};
