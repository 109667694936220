import { Attr, HasOne, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class OrganisationBrand extends ApplicationRecord {
  static jsonapiType = "organisation_brands";

  @HasOne() organisation: Organisation;

  @Attr() name: string;

  @Attr() logoUrl: string;

  @Attr() grayscaleLogoUrl: string;

  @Attr() themeColor: string;

  @Attr() primary: boolean;

  @Attr() internalUseOnly: boolean;

  @Attr() headerText: string;

  @Attr() headerImageUrl: string;

  @Attr() footerImageUrl: string;

  @Attr() outreachHeaderImageUrl: string;

  @Attr() outreachFooterImageUrl: string;

  @Attr() type: string;

  @Attr() font: string;

  @Attr() customDisclaimer: string;

  @Attr() organisationId: number;

  @Attr() emailSubject: string;

  @Attr() organisationTeamId: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default OrganisationBrand;
