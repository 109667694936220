import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model({ apiNamespace: "/social_integrations", endpoint: "/facebook" })
class FacebookSocialIntegration extends ApplicationRecord {
  static jsonapiType = "facebook_social_integrations";

  @BelongsTo() organisation: Organisation;

  @Attr() expiresAt: string;

  @Attr() hasDraftPosts: boolean;

  @Attr() hasScheduledPosts: boolean;

  @Attr() label: string;

  @Attr() metadata: Record<string, any>;

  @Attr() organisationId: number;

  @Attr() organisationTeamIds: number[];

  @Attr() permissions: string;

  @Attr() remoteId: string;

  @Attr() socialPlatform: string;

  @Attr() token: string;

  @Attr({ persist: false }) archived: boolean;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) updatedAt: string;

  platformIcon(whiteIcon = false) {
    const format = whiteIcon ? "15x15" : "";

    return `${import.meta.env.VITE_ASSETS_URL}/icons/facebook${format}.svg`;
  }

  platformLabel() {
    return "Facebook";
  }
}

export default FacebookSocialIntegration;
