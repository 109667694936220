export default [
  {
    name: "add-mention-stream",
    path: "/organisations/:organisationId/mention-streams/new",
    props: true,
    component: () => import("pages/mention_streams/MentionStreamsEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "New Mention Stream",
    },
  },
  {
    name: "mention-streams-edit",
    path: "/organisations/:organisationId/mention-streams/:streamId/edit",
    props: true,
    component: () => import("pages/mention_streams/MentionStreamsEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Mention Stream",
    },
  },
];
