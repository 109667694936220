import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Blog from "./Blog";

@Model()
class BlogPost extends ApplicationRecord {
  static jsonapiType = "blog_posts";

  @BelongsTo() source: Blog;

  @Attr() blogId: number;

  @Attr() externalId: string;

  @Attr() title: string;

  @Attr() body: string;

  @Attr() publishedAt: string;

  @Attr() author: string;

  @Attr() url: string;

  @Attr() comment: boolean;

  @Attr() urlHash: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default BlogPost;
