export default [
  {
    name: "exports",
    path: "/exports",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/ExportList.vue"),
    meta: {
      title: "Exports",
      accessLevel: "super",
    },
  },
];
