import { Attr, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContactList from "./ContactList";
import Influencer from "./Influencer";
import Outlet from "./Outlet";
import OutletDesk from "./OutletDesk";
import PrivateInfluencer from "./PrivateInfluencer";
import PrivateOutlet from "./PrivateOutlet";
import type { Email } from "./types";

@Model()
export class Contact extends ApplicationRecord {
  static jsonapiType = "contacts";

  @HasMany() contactLists: ContactList[];

  @Attr({ persist: false }) documentId: string;

  @Attr() avatarUrl?: string;

  @Attr() name: string;

  @Attr() emails: Email[];

  get email(): string | undefined {
    return this.emails?.find((email) => email.defaultFlag)?.email;
  }
}

export function isOutlet(contact: Contact): contact is Outlet {
  return contact.resourceIdentifier.type.includes("outlets");
}

export function isInfluencer(contact: Contact): contact is Influencer {
  return contact.resourceIdentifier.type.includes("influencers");
}

export function isOutletDesk(contact: Contact): contact is OutletDesk {
  return contact.resourceIdentifier.type.includes("outlet_desks");
}

export function isPrivateInfluencer(
  contact: Contact
): contact is PrivateInfluencer {
  return contact.resourceIdentifier.type.includes("private_influencers");
}

export function isPrivateOutlet(contact: Contact): contact is PrivateOutlet {
  return contact.resourceIdentifier.type.includes("private_outlets");
}

export default Contact;
