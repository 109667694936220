export default [
  {
    name: "organisation-outreach-campaign-template-add",
    path: "/organisations/:organisationId/organisation_outreach_campaign_templates/new",
    props: true,
    component: () =>
      import("pages/OrganisationOutreachCampaignTemplateEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "New Organisation Outreach Campaign Template",
    },
  },
  {
    name: "organisation-outreach-campaign-template-edit",
    path: "/organisations/:organisationId/organisation_outreach_campaign_templates/:organisationOutreachCampaignTemplateId/edit",
    props: true,
    component: () =>
      import("pages/OrganisationOutreachCampaignTemplateEdit.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Organisation Outreach Campaign Template",
    },
  },
];
