export default [
  {
    name: "mention-expectations-index",
    path: "/mention_expectations",
    component: () => import("pages/MentionExpectationsList.vue"),
    meta: {
      title: "Expected Mention Requests",
      accessLevel: "limited",
    },
  },
  {
    name: "mention-expectations-edit",
    path: "/mention_expectations/:itemId/edit",
    component: () => import("pages/MentionExpectationEdit.vue"),
    props: true,
    meta: {
      title: "Edit Expected Mention Request",
      accessLevel: "limited",
    },
  },
  {
    name: "mention-expectations-new",
    path: "/mention_expectations/new",
    component: () => import("pages/MentionExpectationEdit.vue"),
    props: true,
    meta: {
      title: "New Expected Mention Request",
      accessLevel: "limited",
    },
  },
];
