import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import DistributionList from "./DistributionList";
import Journalist from "./Journalist";
import MediaRequest from "./MediaRequest";
import Organisation from "./Organisation";
import OrganisationContract from "./OrganisationContract";
import OrganisationFeatureAllowance from "./OrganisationFeatureAllowance";
import OrganisationReportSpec from "./OrganisationReportSpec";
import OrganisationsFeature from "./OrganisationsFeature";
import QuerySnippet from "./QuerySnippet";
import Stream from "./Stream";
import TranscriptRequest from "./TranscriptRequest";
import User from "./User";

@Model()
class Version extends ApplicationRecord {
  static jsonapiType = "versions";

  @BelongsTo() user: User;

  @BelongsTo() item:
    | DistributionList
    | Organisation
    | OrganisationReportSpec
    | OrganisationContract
    | OrganisationsFeature
    | OrganisationFeatureAllowance
    | Stream
    | TranscriptRequest
    | User
    | Journalist
    | MediaRequest
    | QuerySnippet;

  @Attr() event: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr() objectChanges: Record<string, any>;

  @Attr() object: Record<string, any>;

  @Attr() itemId: number;

  @Attr() itemType: string;

  @Attr() feature: string;
}

export default Version;
