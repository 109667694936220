<template>
  <div class="player-popout-buttons-wrapper flex justify-center">
    <div
      class="player-popout-buttons"
      :style="playerIsExpanded ? `margin: 0 10px 0 20px` : null"
    >
      <QBtn
        v-if="showPrevious"
        :class="{ unavailable: isPreviousDisabled }"
        flat
        padding="sm 12px"
        icon="ion-ios-skip-backward"
        :aria-label="$t('player_popout_buttons.previous_item_in_queue')"
        @click="canSkipPrevious && $emit('skip-previous')"
      >
        <StreemTooltip>{{
          $t("player_popout_buttons.previous_item_in_playlist")
        }}</StreemTooltip>
      </QBtn>
      <QBtn
        flat
        padding="sm 12px"
        icon="ion-ios-rewind"
        :disable="firstPlay || disabled"
        :aria-label="$t('player_popout_buttons.30_seconds_back_aria')"
        @click="$emit('rewind')"
      >
        <StreemTooltip>{{
          $t("player_popout_buttons.30_seconds_back")
        }}</StreemTooltip>
      </QBtn>
      <QBtn
        flat
        padding="sm 12px"
        :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
        :disable="loading || disabled"
        :aria-label="$t('player_popout_buttons.play_or_pause')"
        @click="$emit('toggle-play')"
      >
        <StreemTooltip>{{
          isPlaying
            ? $t("player_popout_buttons.pause")
            : $t("player_popout_buttons.play")
        }}</StreemTooltip>
      </QBtn>
      <QBtn
        flat
        padding="sm 12px"
        icon="ion-ios-fastforward"
        :disable="firstPlay || disabled"
        :aria-label="$t('player_popout_buttons.30_seconds_forward')"
        @click="$emit('fastforward')"
      >
        <StreemTooltip>{{
          $t("player_popout_buttons.30_seconds_forward")
        }}</StreemTooltip>
      </QBtn>
      <QBtn
        v-if="showNext"
        :class="{ unavailable: isNextDisabled }"
        flat
        padding="sm 12px"
        icon="ion-ios-skip-forward"
        :aria-label="$t('player_popout_buttons.next_item_in_queue')"
        @click="canSkipNext && $emit('skip-next')"
      >
        <StreemTooltip>{{
          $t("player_popout_buttons.next_item_in_playlist")
        }}</StreemTooltip>
      </QBtn>
      <QBtn
        v-if="showExpandButton"
        flat
        padding="sm 12px"
        icon="ion-expand"
        :aria-label="$t('player_popout_buttons.expand_player')"
        @click="setPlayerExpanded(true)"
      >
        <StreemTooltip>{{
          $t("player_popout_buttons.expand_player")
        }}</StreemTooltip>
      </QBtn>
      <QBtn
        v-if="showShrinkButton"
        flat
        padding="sm 12px"
        icon="ion-contract"
        :aria-label="$t('player_popout_buttons.shrink_player')"
        @click="setPlayerExpanded(false)"
      >
        <StreemTooltip>{{
          $t("player_popout_buttons.shrink_player")
        }}</StreemTooltip>
      </QBtn>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { POPOUT_PLAYER_TARGET } from "shared/constants";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "PlayerPopoutButtons",
  props: {
    disabled: {
      type: Boolean,
    },
    isPlaying: {
      type: Boolean,
    },
    firstPlay: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    showPrevious: {
      type: Boolean,
    },
    noPreviousClip: {
      type: Boolean,
    },
    showNext: {
      type: Boolean,
    },
    noNextClip: {
      type: Boolean,
    },
    isAudio: {
      type: Boolean,
    },
  },
  emits: ["skip-previous", "rewind", "toggle-play", "fastforward", "skip-next"],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();
    const { setPlayerExpanded } = universalPlayerStore;

    const { playerPopoutTarget, playerIsExpanded } =
      storeToRefs(universalPlayerStore);

    return {
      setPlayerExpanded,
      playerPopoutTarget,
      playerIsExpanded,
    };
  },
  computed: {
    isPreviousDisabled() {
      return (
        this.noPreviousClip ||
        this.disabled ||
        !this.$isDesktop ||
        !this.isPopoutPoppingOut
      );
    },
    canSkipPrevious() {
      return (
        !this.noPreviousClip &&
        !this.disabled &&
        this.$isDesktop &&
        this.isPopoutPoppingOut
      );
    },
    isNextDisabled() {
      return (
        this.noNextClip ||
        this.disabled ||
        !this.$isDesktop ||
        !this.isPopoutPoppingOut
      );
    },
    canSkipNext() {
      return (
        !this.noNextClip &&
        !this.disabled &&
        this.$isDesktop &&
        this.isPopoutPoppingOut
      );
    },
    isPopoutPoppingOut() {
      return this.playerPopoutTarget === POPOUT_PLAYER_TARGET;
    },
    showExpandButton() {
      return !this.playerIsExpanded && this.isPoppedOutVideo;
    },
    showShrinkButton() {
      return this.playerIsExpanded && this.isPoppedOutVideo;
    },
    isPoppedOutVideo() {
      return this.isPopoutPoppingOut && !this.isAudio;
    },
  },
};
</script>

<style lang="scss" scoped>
.player-popout-buttons-wrapper {
  min-width: 210px;
}

.player-popout-buttons {
  :deep(.q-btn__wrapper) {
    padding: 4px 14px;
  }
}

.unavailable {
  cursor: default;
  opacity: 0.3;
}
</style>
