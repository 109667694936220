import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import DateRange from "shared/helpers/DateRange";
import {
  ApplicationRecord,
  objectDirtyChecker,
} from "shared/services/spraypaint";
import type { Stream } from "shared/types";
import type {
  ChartIndex,
  SavedChartSetting,
} from "shared/types/instantInsights";

import User from "./User";
import UserAccessible from "./UserAccessible";
import ViewedItem from "./ViewedItem";

@Model()
class InstantInsightsReport extends ApplicationRecord {
  static jsonapiType = "instant_insights_reports";

  static withUserAccessibles() {
    return InstantInsightsReport.selectExtra([
      "canModify",
      "canShare",
    ]).includes(["userAccessibles"]);
  }

  @BelongsTo() user: User;

  @HasMany() userAccessibles: UserAccessible[];

  @HasMany({ name: "target" }) viewedItems: ViewedItem[];

  @Attr() userId: number;

  @Attr() organisationTeamId: number;

  @Attr() name: string;

  @Attr() summary: string;

  @Attr() rangeLabel: string;

  @Attr() before: number;

  @Attr() after: number;

  @Attr({ dirtyChecker: objectDirtyChecker }) streamIds: number[];

  @Attr() onlineContent: boolean;

  @Attr() printContent: boolean;

  @Attr() magazineContent: boolean;

  @Attr() tvContent: boolean;

  @Attr() radioContent: boolean;

  @Attr() socialContent: boolean;

  @Attr() podcastContent: boolean;

  @Attr({ dirtyChecker: objectDirtyChecker }) selectedCharts: any[];

  @Attr() collapseSyndicated: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false, dirtyChecker: () => false })
  streamsData: Stream[];

  @Attr({ persist: false }) canShare: boolean;

  @Attr({ persist: false }) canModify: boolean;

  @Attr({ persist: false }) organisationBrandId: number;

  @Attr({ dirtyChecker: objectDirtyChecker }) chartSettings: ChartIndex;

  @Attr({ dirtyChecker: objectDirtyChecker })
  newChartSettings: SavedChartSetting[];

  selectedContent() {
    return [
      ...(this.onlineContent ? ["Online"] : []),
      ...(this.printContent ? ["Print"] : []),
      ...(this.magazineContent ? ["Magazine"] : []),
      ...(this.tvContent ? ["TV"] : []),
      ...(this.radioContent ? ["Radio"] : []),
      ...(this.socialContent ? ["Social"] : []),
      ...(this.podcastContent ? ["Podcast"] : []),
    ];
  }

  relativeRange() {
    if (this.rangeLabel) {
      return DateRange.rangeForLabel(this.rangeLabel);
    }

    return null;
  }

  absoluteRange() {
    const { before, after } = this;

    if (this.before && this.after) {
      return new DateRange({ before, after } as DateRange);
    }

    return null;
  }

  isSharedBy(userId: number) {
    return (
      this.userId === userId &&
      this.userAccessibles?.some((record) => record.userId !== userId)
    );
  }
}

export default InstantInsightsReport;
