import addMinutes from "date-fns/addMinutes";
import differenceInCalendarYears from "date-fns/differenceInCalendarYears";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInSeconds from "date-fns/differenceInSeconds";
import differenceInYears from "date-fns/differenceInYears";
import endOfDay from "date-fns/endOfDay";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import getTime from "date-fns/getTime";
import getUnixTime from "date-fns/getUnixTime";
import isDate from "date-fns/isDate";
import isSameMonth from "date-fns/isSameMonth";
import isSameYear from "date-fns/isSameYear";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import subHours from "date-fns/subHours";
import subMinutes from "date-fns/subMinutes";
import subMonths from "date-fns/subMonths";
import subSeconds from "date-fns/subSeconds";
import subYears from "date-fns/subYears";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import {
  getLocaleDate,
  getLocalePluralText,
  getLocaleText,
} from "shared/boot/i18n";
import { defaultTimezone } from "shared/constants";

export type DateValue = Date | string | number;

enum SinceRange {
  "6_hours" = 21600,
  "12_hours" = 43200,
  "24_hours" = 86400,
  "3_days" = 259200,
  "7_days" = 604800,
  "14_days" = 1209600,
}

export function parseDate(value: DateValue): Date {
  if (isDate(value)) return value as Date;

  let parsedDate: DateValue;

  // If the date follow "yyyy-mm-dd" format, we have to add timezone offset
  if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value as string)) {
    parsedDate = new Date(value);

    parsedDate.setTime(
      parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60 * 1000
    );
  } else {
    parsedDate = value;
  }

  if (Number.isInteger(parsedDate) && parsedDate.toString().length > 10) {
    parsedDate = Math.floor((parsedDate as number) / 1000);
  }

  return Number.isInteger(parsedDate)
    ? new Date((parsedDate as number) * 1000)
    : new Date(parsedDate);
}

export function formatDate(
  date: DateValue,
  template: string,
  options: Intl.DateTimeFormatOptions = {}
) {
  return format(parseDate(date), template, options);
}

export function formatIntlDate(
  date: DateValue,
  options: Intl.DateTimeFormatOptions = {}
): string {
  return getLocaleDate(parseDate(date), options);
}

export function dateYmdhms(date: DateValue) {
  return formatDate(parseDate(date), "yyyy-MM-dd HH:mm:ss");
}

export function timeAgo(
  date: DateValue,
  {
    month = "short",
    day = "numeric",
    hour,
    minute,
    second,
  }: Intl.DateTimeFormatOptions = {},
  { showOnDay = false } = {}
): string {
  const parsed = parseDate(date);

  const now = new Date();
  const diffYears = differenceInYears(now, parsed);
  const diffHours = differenceInHours(now, parsed);
  const diffMinutes = differenceInMinutes(now, parsed);
  const diffSeconds = differenceInSeconds(now, parsed);

  if (diffYears >= 1) {
    const formattedDate = formatIntlDate(parsed, { dateStyle: "medium" });

    if (showOnDay) {
      return getLocaleText("date.time_ago.on_day", { date: formattedDate });
    }

    return formattedDate;
  }

  if (diffHours > 12 || diffSeconds > SinceRange["12_hours"]) {
    const formattedDate = formatIntlDate(parsed, {
      month,
      day,
      hour,
      minute,
      second,
    });

    if (showOnDay) {
      return getLocaleText("date.time_ago.on_day", { date: formattedDate });
    }

    return formattedDate;
  }

  if (diffMinutes >= 60) {
    return getLocaleText("date.time_ago.hours_ago", { diffHours });
  }

  if (diffSeconds >= 120) {
    return getLocaleText("date.time_ago.minutes_ago", { diffMinutes });
  }

  if (diffSeconds >= 60) {
    return getLocaleText("date.time_ago.minute");
  }

  if (diffSeconds >= 10) {
    return getLocaleText("date.time_ago.seconds_ago", { diffSeconds });
  }

  return getLocaleText("date.time_ago.seconds");
}

export function duration(date1: Date | number, date2: Date | number): string {
  const diff = Math.floor(Number(date1) - Number(date2));
  const minutes = Math.floor(diff / 60);
  const seconds = diff % 60;

  const localeMins = getLocalePluralText("date.duration.min", minutes, {
    minutes,
  });

  const localeSecs = getLocalePluralText("date.duration.sec", seconds, {
    seconds,
  });

  return getLocalePluralText("date.duration.mins_secs", minutes, {
    mins: localeMins,
    secs: localeSecs,
  });
}

export function durationTimer(
  date1: Date | number,
  date2: Date | number,
  options: { includeDays?: boolean; excludeSeconds?: boolean } = {}
): string {
  let negativeTime = false;
  let diff = (Number(date1) - Number(date2)) / 1000;

  if (diff < 0) {
    negativeTime = true;
    diff = Math.abs(diff);
  }

  const days = Math.floor(diff / (3600 * 24));
  const hours = Math.floor((diff % (3600 * 24)) / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = Math.floor(diff % 60);

  const negativePrefix = negativeTime ? "-" : "";
  let daysPrefix = "";
  const hoursPrefix = hours.toString().padStart(2, "0");
  let minutesPrefix = `:${minutes.toString().padStart(2, "0")}`;
  let secondsPrefix = `:${seconds.toString().padStart(2, "0")}`;

  if (options?.includeDays) {
    daysPrefix = `${days.toString().padStart(2, "0")}:`;
  }

  if (options?.excludeSeconds) {
    secondsPrefix = "";

    const minutesExcludingSeconds = negativeTime ? minutes : minutes + 1;
    minutesPrefix = `:${minutesExcludingSeconds.toString().padStart(2, "0")}`;
  }

  return (
    negativePrefix + daysPrefix + hoursPrefix + minutesPrefix + secondsPrefix
  );
}

export function secondsToTimecode(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

export function secondsToTimecodeWithSeconds(
  time: number,
  withLetters = false
): string {
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_MINUTE = 60;

  const hours = Math.floor(time / SECONDS_IN_HOUR);
  const minutes = Math.floor((time % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
  const seconds = Math.floor((time % SECONDS_IN_HOUR) % SECONDS_IN_MINUTE);

  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  if (withLetters) {
    return getLocalePluralText("date.timecode", hours, {
      hours: paddedHours,
      minutes: paddedMinutes,
      seconds: paddedSeconds,
    });
  }

  const timecode = `${paddedMinutes}:${paddedSeconds}`;

  if (hours) {
    return `${paddedHours}:${timecode}`;
  }

  return timecode;
}

export function timecodeToSeconds(time: string) {
  const [hours, minutes] = time.split(":");

  return Number(hours) * 3600 + Number(minutes) * 60;
}

export function dayString(day: number) {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][day];
}

export function dateToTimestamp(date: DateValue) {
  return getUnixTime(parseDate(date));
}

export function getRange(days: number, includeToday = true) {
  const current = new Date();
  const after = dateToTimestamp(subDays(startOfDay(current), days - 1));

  const before = dateToTimestamp(
    includeToday ? endOfDay(current) : startOfDay(current)
  );

  return { after, before };
}

export function getRangeByDates(after: DateValue, before: DateValue) {
  return {
    after: dateToTimestamp(after),
    before: dateToTimestamp(before),
  };
}

type Unit = "minute" | "hour" | "day" | "month" | "year" | "second";

export function subtractTime(date: Date | number, amount: number, unit: Unit) {
  const fn = {
    minute: subMinutes,
    hour: subHours,
    day: subDays,
    month: subMonths,
    year: subYears,
    second: subSeconds,
  }[unit];

  return fn(date, amount);
}

export function addTime(date: Date | number, amount: number, unit: Unit) {
  return subtractTime(date, amount * -1, unit);
}

export function getCurrentTimestamp() {
  return Math.floor(getTime(new Date()) / 1000);
}

export function getCurrentISODate() {
  return new Date().toISOString();
}

export function getDateTimeZoneOffset(
  date: DateValue,
  timeZone: Intl.DateTimeFormatOptions["timeZone"]
) {
  const dateWithOffset = new Date(
    parseDate(date).toLocaleString("en-US", { timeZone })
  );

  return differenceInMinutes(dateWithOffset, parseDate(date));
}

export function addTimeZone(
  date: DateValue,
  timeZone: Intl.DateTimeFormatOptions["timeZone"]
) {
  const offset = getDateTimeZoneOffset(date, timeZone);

  return addMinutes(parseDate(date), offset);
}

export function removeTimeZone(
  date: DateValue,
  timeZone: Intl.DateTimeFormatOptions["timeZone"]
) {
  const offset = getDateTimeZoneOffset(date, timeZone);

  return addMinutes(parseDate(date), -offset);
}

export function dateInTimeZone(
  date: DateValue,
  formatString: string,
  timeZone: Intl.DateTimeFormatOptions["timeZone"]
) {
  return formatDate(addTimeZone(parseDate(date), timeZone), formatString);
}

export function timeInTimeZone(
  date: DateValue,
  formatString: string,
  timeZone: Intl.DateTimeFormatOptions["timeZone"]
) {
  return formatDate(addTimeZone(parseDate(date), timeZone), formatString);
}

export function dateToISODate(date: Date) {
  return date.toISOString();
}

export function getTimezoneOffset() {
  return format(new Date(), "XXX");
}

export function getTimezone() {
  const invalidTimezones = ["Etc/Unknown"];

  let timeZone: string = defaultTimezone;

  if (window.Intl) {
    ({ timeZone } = Intl.DateTimeFormat().resolvedOptions());

    if (!timeZone || invalidTimezones.includes(timeZone)) {
      timeZone = defaultTimezone;
    }
  }

  return timeZone;
}

export function secondsWithTimezone(seconds: number, timezone: string) {
  const browserTimezone = getTimezone();

  if (timezone !== browserTimezone) {
    const date = new Date();
    const start = date.setHours(0, 0, 0, 0);

    const timestamp = removeTimeZone(
      new Date(start + seconds * 1000),
      timezone
    );

    const timeInBrowserTimezone = (timestamp.getTime() - start) / 1000;

    return timeInBrowserTimezone;
  }

  return seconds;
}

export function timeToPercentage(time?: string) {
  if (!time) return 0;
  const components = time.split(":");
  let hour = Number(components[0]);

  if (components[1][2] === "p" && hour !== 12) {
    hour += 12;
  } else if (components[1][2] === "a" && hour === 12) {
    hour = 0;
  } else if (components[1][2] === "p" && hour === 12) {
    hour = 12;
  }

  return Math.ceil((hour * 100) / 23);
}

export function getTimeFromSeconds(
  seconds: number,
  timeFormat = "hh:mmaaaaa'm'"
) {
  const date = startOfDay(new Date());
  date.setSeconds(seconds);

  return format(date, timeFormat);
}

export function getHours(date: Date, timeFormat = "haaaaa'm'") {
  return format(new Date(date).getTime(), timeFormat);
}

export function fromNow(date: string): string {
  const parsedDate = parseISO(date);

  if (isToday(parsedDate)) {
    const minutes = differenceInMinutes(new Date(), parsedDate);
    if (minutes === 0) return getLocaleText("date.from_now.seconds");
    if (minutes < 60)
      return getLocalePluralText("date.from_now.minutes", minutes, { minutes });

    if (minutes < 24 * 60) {
      const hours = Math.floor(minutes / 60);

      return getLocalePluralText("date.from_now.hours", hours, { hours });
    }
  }

  if (isYesterday(parsedDate)) {
    return getLocaleText("date.from_now.yesterday", {
      hours: getHours(parsedDate),
    });
  }

  const days = differenceInDays(new Date(), parsedDate);

  return getLocalePluralText("date.from_now.days", days, { days });
}

export function distanceInWords(date: DateValue) {
  const parsedDate = parseDate(date);
  const days = differenceInDays(parsedDate, new Date());
  const months = differenceInMonths(parsedDate, new Date());
  const years = differenceInYears(parsedDate, new Date());
  const calendarYears = differenceInCalendarYears(parsedDate, new Date());

  if (isSameMonth(new Date(), parsedDate) || months === 0) {
    return `${Math.abs(days)} day${days !== 1 ? "s" : ""}${
      days < 0 ? " ago" : ""
    }`;
  }

  if (isSameYear(new Date(), parsedDate) || years === 0) {
    return `${Math.abs(months)} month${months !== 1 ? "s" : ""}${
      months < 0 ? " ago" : ""
    }`;
  }

  return `${Math.abs(calendarYears)} year${calendarYears !== 1 ? "s" : ""}${
    calendarYears < 0 ? " ago" : ""
  }`;
}

export function getTimeFromDuration(date: number, includeSeconds = true) {
  let hours: number | string = Math.floor(date / 3600);
  let minutes: number | string = Math.floor(date / 60) - hours * 60;

  let seconds: number | string =
    Math.floor(date) - (minutes * 60 + hours * 3600);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return includeSeconds
    ? `${hours}:${minutes}:${seconds}`
    : `${hours}:${minutes}`;
}

export function getDurationFromTime(time: string) {
  const parsedTime = time.split(":");

  return Number(parsedTime[0]) * 3600 + Number(parsedTime[1]) * 60;
}

export function getTimeFromRange(range: keyof typeof SinceRange) {
  return SinceRange[range];
}

export function getRangeFromTime(time: number) {
  return Object.keys(SinceRange).find(
    (key) => SinceRange[key as keyof typeof SinceRange] === time
  );
}

export function getDaysFromTime(time: number) {
  const days = Math.floor(time / 86400);

  return days < 10 ? `0${days}` : days;
}

export function getHoursFromTime(time: number) {
  const days = getDaysFromTime(time);
  const hours = Math.floor(time / 3600) - Number(days) * 24;

  return hours < 10 ? `0${hours}` : hours;
}

export function getMinutesFromTime(
  time: number,
  { disableZeroPadding = false } = {}
) {
  const days = getDaysFromTime(time);
  const hours = getHoursFromTime(time);

  const minutes =
    Math.floor(time / 60) - (Number(hours) * 60 + Number(days) * 24 * 60);

  let padding = "0";

  if (disableZeroPadding) padding = "";

  return minutes < 10 ? `${padding}${minutes}` : minutes;
}

/**
 * datetime: ISO string of given date
 * timeZone: string representation of timezone e.g. Australia/Sydney
 */
export function fromCurrentToGivenTimezone(
  datetime: DateValue,
  timeZone: string
) {
  return utcToZonedTime(datetime, timeZone);
}

export function swapTimezones(
  date: DateValue,
  fromTimeZone: Intl.DateTimeFormatOptions["timeZone"],
  toTimeZone: Intl.DateTimeFormatOptions["timeZone"]
) {
  return addTimeZone(removeTimeZone(date, fromTimeZone), toTimeZone);
}

export function toUTCTimezone(datetime: Date) {
  const dateInLocalTimezone = new Date(datetime);
  const localTimezoneUTCOffset = dateInLocalTimezone.getTimezoneOffset();

  return addMinutes(dateInLocalTimezone, localTimezoneUTCOffset);
}

export function shortTimezone(
  date: Date | number,
  timeZone: Intl.DateTimeFormatOptions["timeZone"]
) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    timeZoneName: "short",
  };

  return new Intl.DateTimeFormat("en-AU", options).format(date).split(" ")[1];
}

export function toSeconds(millisecondTimestamp: Date | number) {
  return (millisecondTimestamp as number) / 1000;
}

export function toMilliseconds(secondTimestamp: number) {
  return secondTimestamp * 1000;
}

export function secondsSinceMidnight(timezone = getTimezone()) {
  const now = fromCurrentToGivenTimezone(new Date(), timezone);
  const midnight = startOfDay(now);

  return differenceInSeconds(now, midnight);
}

export function endOfDateInTimezone(
  date: DateValue,
  unit: "day" | "week" | "month",
  timezone = getTimezone()
) {
  const fn = {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[unit];

  if (!fn) throw new TypeError(`Unit ${unit} not supported`);

  return zonedTimeToUtc(
    fn(fromCurrentToGivenTimezone(date, timezone)),
    timezone
  );
}

export function startOfDateInTimezone(
  date: DateValue,
  unit: "day" | "week" | "month",
  timezone = getTimezone()
) {
  const fn = {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[unit];

  return zonedTimeToUtc(
    fn(fromCurrentToGivenTimezone(date, timezone)),
    timezone
  );
}

export function unixDateTime(date: DateValue = new Date()) {
  const parsedDate = parseDate(date);

  return Math.floor(parsedDate.valueOf() / 1000);
}

export function timeCodefromDate(
  date = new Date(),
  timezone = "Australia/Sydney"
) {
  const dateInTimezone = addTimeZone(date, timezone);

  return formatDate(dateInTimezone, "HH:mm:ss");
}

export function nextQuarterHour(date = new Date()) {
  const newDateTime = new Date(date);

  newDateTime.setMilliseconds(
    Math.ceil(newDateTime.getMilliseconds() / 1000) * 1000
  );

  newDateTime.setSeconds(Math.ceil(newDateTime.getSeconds() / 60) * 60);
  newDateTime.setMinutes(Math.ceil(newDateTime.getMinutes() / 15) * 15);

  return newDateTime;
}

export function withinLast24Hours(date: DateValue) {
  const now = new Date();
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  const dateFormated = new Date(date);

  return dateFormated >= twentyFourHoursAgo && dateFormated <= now;
}
