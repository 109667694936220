import { camelCase, isObject, snakeCase } from "lodash-es";

export default function transformKeys(payload, format = "camelCase") {
  let keyFormatter;

  switch (format) {
    case "snakeCase": {
      keyFormatter = snakeCase;
      break;
    }
    case "camelCase":
    default:
      keyFormatter = camelCase;
  }

  function recursiveTransformKeys(data) {
    if (Array.isArray(data)) {
      return data.map(recursiveTransformKeys);
    }

    if (isObject(data)) {
      const result = {};

      Object.keys(data).forEach((key) => {
        result[keyFormatter(key)] = recursiveTransformKeys(data[key]);
      });

      return result;
    }

    return data;
  }

  return recursiveTransformKeys(payload);
}
