import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import User from "./User";

@Model()
class SpellingCorrectionRule extends ApplicationRecord {
  static jsonapiType = "spelling_correction_rules";

  @BelongsTo() user: User;

  @Attr() match: string;

  @Attr() replacement: string;

  @Attr() notes: string;

  @Attr() matchOnWordBoundaries: boolean;

  @Attr() archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default SpellingCorrectionRule;
