<template>
  <div
    class="flex playlist-item"
    @click.stop="$emit('play-item', item)"
  >
    <div
      v-if="!item.start_time"
      class="soft"
    >
      {{ $t("player_popout_list_item.queue_is_empty") }}
    </div>
    <template v-else>
      <div
        v-if="isCurrentClip"
        class="icon flex"
      >
        <PlayerPopoutSoundIcon :is-playing="isPlaying" />
      </div>
      <div
        v-else-if="item.type === 'radio_clip'"
        class="icon flex justify-center"
      >
        <img
          :alt="$t('player_popout_list_item.radio_clip')"
          src="~shared/assets/icons/radio.svg"
        />
      </div>
      <div
        v-else
        class="icon flex justify-center"
      >
        <img
          :alt="$t('player_popout_list_item.tv_clip')"
          src="~shared/assets/icons/tv.svg"
        />
      </div>
      <div class="column col no-wrap">
        <div class="row no-wrap">
          <span class="col-auto q-mr-xs ellipse-overflow source">
            {{ index }}. {{ dig(item, "source", "name") }}
          </span>
          <span class="col smaller ellipse-overflow">
            {{ dig(item, "program_airing", "name") }}
          </span>
        </div>
        <div class="row no-wrap soft">
          <div
            v-if="item.excerpts && item.excerpts.length"
            class="col ellipse-overflow"
          >
            {{
              $t("player_popout_list_item.mentions", {
                keywords: format(item.excerpts[0].keywords),
              })
            }}
          </div>
          <div v-else-if="item.keywords">
            {{
              $t("player_popout_list_item.mentions", {
                keywords: format(Object.keys(item.keywords)),
              })
            }}
          </div>
        </div>
      </div>
      <StreemTooltip v-if="tooltip">
        {{ tooltip }}
      </StreemTooltip>
    </template>
  </div>
</template>

<script>
import { toSentence } from "shared/helpers/array";
import { dig } from "shared/helpers/object";

import PlayerPopoutSoundIcon from "./PlayerPopoutSoundIcon.vue";

export default {
  name: "PlayerPopoutListItem",
  components: {
    PlayerPopoutSoundIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    tooltip: {
      type: String,
      default: null,
    },
    isCurrentClip: {
      type: Boolean,
    },
    isPlaying: {
      type: Boolean,
    },
  },
  emits: ["play-item"],
  methods: {
    dig,
    format(keywords) {
      return toSentence((keywords || []).map((keyword) => `"${keyword}"`));
    },
  },
};
</script>

<style lang="scss" scoped>
.playlist-item {
  max-width: 240px;
}

.item {
  overflow: hidden;

  & > div {
    padding: 0.25em;
    max-height: 3em;
  }

  &:hover .play-hover {
    opacity: 1;
  }
}

.play-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 0.15em;
  will-change: opacity;
  transition: opacity 0.3s;
  opacity: 0;
}

.ellipse-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.source {
  font-weight: 700;
}

.icon {
  width: 20px;
  margin-right: 8px;
  align-items: center;
}
</style>
