import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import JournalistEnquiry from "./JournalistEnquiry";
import User from "./User";

@Model()
class JournalistEnquiriesUser extends ApplicationRecord {
  static jsonapiType = "journalist_enquiries_users";

  @BelongsTo() journalistEnquiry: JournalistEnquiry;

  @BelongsTo() user: User;

  @Attr() journalistEnquiryId: number;

  @Attr() userId: number;
}

export default JournalistEnquiriesUser;
