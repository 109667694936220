import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OutreachCommunication from "./OutreachCommunication";
import User from "./User";

@Model()
class OutreachCommunicationUser extends ApplicationRecord {
  static jsonapiType = "outreach_communication_users";

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @BelongsTo() outreachCommunication: OutreachCommunication;

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() outreachCommunicationId: number;
}

export default OutreachCommunicationUser;
