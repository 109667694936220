import { Attr, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

@Model()
class CopyrightAgency extends ApplicationRecord {
  static jsonapiType = "copyright_agencies";

  @Attr() name: string;
}

export default CopyrightAgency;
