import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "source-restrictions",
    path: "/source_restrictions",
    props: (route) => typecastRouteProps(route),
    component: () => import("pages/SourceRestrictionList.vue"),
    meta: {
      title: "Source Restrictions",
    },
  },
  {
    name: "source-restrictions-add",
    path: "/source_restrictions/new",
    component: () => import("pages/SourceRestrictionEdit.vue"),
    meta: {
      title: "New Source Restriction",
    },
  },
  {
    name: "source-restrictions-edit",
    path: "/source_restrictions/:id/edit",
    component: () => import("pages/SourceRestrictionEdit.vue"),
    props: true,
    meta: {
      title: "Edit Source Restriction",
    },
  },
];
