import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";

@Model()
class ActiveStorageBlob extends ApplicationRecord {
  static jsonapiType = "active_storage_blobs";

  @BelongsTo() activeStorageAttachment: ActiveStorageAttachment;

  @Attr() key: string;

  @Attr() filename: string;

  @Attr() contentType: string;

  @Attr() metadata: Record<string, any>;

  @Attr() byteSize: number;

  @Attr() checksum: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) video: boolean;

  @Attr({ persist: false }) image: boolean;

  @Attr({ persist: false }) relativeUrl: string;
}

export default ActiveStorageBlob;
