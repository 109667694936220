import { Attr, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import EmailEvent from "./EmailEvent";

@Model()
class EmailTracking extends ApplicationRecord {
  static jsonapiType = "email_trackings";

  @HasMany() emailEvents: EmailEvent[];

  @Attr() parentId: number;
}

export default EmailTracking;
