import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OrganisationTeam from "./OrganisationTeam";
import User from "./User";

@Model()
class StreamGroup extends ApplicationRecord {
  static jsonapiType = "stream_groups";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() organisationTeam: OrganisationTeam;

  @BelongsTo() user: User;

  @Attr() label: string;

  @Attr() color: string;

  @Attr() position: number;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() userId: number;

  @Attr({ persist: false }) slug: string;

  @Attr() streamType: number;
}

export default StreamGroup;
